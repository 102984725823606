import React from "react";
import {observer} from "mobx-react-lite";
import {generatePath} from "react-router";
import {Grid} from "@mui/material";
import {useStores} from "../../../stores";
import {environment} from "../../../env";
import {DashboardRow} from "../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../dashboard/DashBoardCard";
import {SupplierNormalizationV2Icon} from "../../../components/icons/SupplierNormalizationV2Icon";
import {routes} from "../../../routing/routes";
import {ClassificationIcon} from "../../../components/icons/ClassificationIcon";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../../components/icons/ClassificationHierarchyIcon";

export const Merge2CleanupDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const supplierNormalizationKpi = supplierNormalizationStore.postKeyValues?.parent_suppliers_count;

    return <>
        {environment.isTest && <h1>Merge2CleanupDashboard</h1>}
        <Grid container
              className="dashboard merge-2-dashboard cleanup-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={supplierNormalizationKpi !== undefined ? supplierNormalizationKpi : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    subtitle="suppliers"
                    link={generatePath(routes.merge_2_cleanup_supplier_normalization, {id: bagId})}
                    badge="Ai"
                    // isTrial={true}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_2_cleanup_categorization, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.merge_2_cleanup_taxonomy_viewer, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
