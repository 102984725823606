import './SingleDropdownMenu.scss'
import React from "react";
import {Badge, Chip, Divider, Grow, IconButton, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import {AccountCircleOutlined} from "@mui/icons-material";
import {useHistory} from "react-router";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../routing/routes";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {environment} from "../../env";
import {getAppEnvironment} from "../../routing/routing.utils";
import {useAuth0} from '@auth0/auth0-react';

const menuId = 'single-dropdown-menu';

export const SingleDropdownMenu = observer(() => {
    const {authStore, approvalStore} = useStores();
    const {logout} = useAuth0();
    const history = useHistory();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();

    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuElement);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuElement(null);
    };

    const handleLogout = () => {
        authStore.auth0Logout(logout);
        history.push(routes.login);
    };

    const renderMenu = (
        <Menu
            anchorEl={menuElement}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            TransitionComponent={Grow}
        >
            <ListItem title={authStore.email}>
                {authStore.displayUser}
                {authStore.isMithraStaff && ' (Mithra)'}
            </ListItem>

            {authStore.viewOnly && <MenuItem disabled>
                <Typography variant="inherit">This account is view-only</Typography>
                {/*<ListItemIcon>*/}
                {/*    <Visibility fontSize="small" />*/}
                {/*</ListItemIcon>*/}
            </MenuItem>}

            <MenuItem disabled>
                <Typography variant="inherit">Account type: {authStore.customerRole}</Typography>
            </MenuItem>

            {(environment.isTest || authStore.isMithraStaff) &&
                <MenuItem disabled>
                    <Typography variant="inherit">Environment: {'' + getAppEnvironment(m.path)}</Typography>
                </MenuItem>
            }

            <Divider/>

            {authStore.isCustomerSuper &&
                <MenuItem onClick={() => {
                    handleMenuClose()
                    history.push(routes.approval);
                }}>
                    Awaiting approvals
                    {approvalStore.pendingRequests !== undefined &&
                        <Chip size="small" label={approvalStore.pendingRequests}
                              className="color-error"/>
                    }
                </MenuItem>
            }

            <Divider/>

            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    return <>
        <IconButton
            edge="end"
            size="medium"
            aria-label="account"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit">
            {
                authStore.isCustomerSuper && approvalStore.pendingRequests !== undefined
                    ? <Badge
                        badgeContent={approvalStore.pendingRequests}
                        color="error">
                        <AccountCircleOutlined/>
                    </Badge>
                    : <AccountCircleOutlined/>
            }
        </IconButton>
        {renderMenu}
    </>
})
