import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody} from "@mui/material";
import {ParentSupplierReviewSubRow} from "./ParentSupplierReviewSubRow";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {getAbbreviation, ParentSupplierReviewRowState} from "../SupplierNormalization.classes";
import {MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import {SUPPLIER_COLUMNS} from "./columns";

type Props = {
    data: ParentSupplierReviewRowState
};
export const ParentSupplierReviewSubTable: React.FC<Props> = observer(({data}) => {
    // const {} = useStores();
    return <Table
        className="sub-table"
        size="small">
        <MithraTableHeadColumns columns={SUPPLIER_COLUMNS}/>
        <TableBody>
            {data.subRows?.map(subRow =>
                <React.Fragment key={subRow.id}>
                    <CurrencyAbbreviationContext.Provider value={getAbbreviation(data.subRows)}>
                        <ParentSupplierReviewSubRow subRow={subRow}/>
                    </CurrencyAbbreviationContext.Provider>
                </React.Fragment>
            )}
        </TableBody>
    </Table>
})
