import {makeAutoObservable} from "mobx";
import {environment} from "../env";
import {DashboardCard} from "../pages/dashboard/dashboard-types";
import {Bag} from "../services/classes/Bag";
import moment from "moment";
import {HierarchicalSankeyOptions} from "../components/visualization/sankey/HierarchicalSankeyChart";
import {CurrencyAbbreviation} from "../components/currency-component/CurrencyComponent";
import {MatKpiTreeValues} from "../services/ApiTypes";
import {SearchConfiguration, SortingConfiguration} from "../customization/SearchConfiguration";
import {ColSpec} from "../components/table/MithraTableHeadColumns";
import {C} from "../configurations/active-configuration";
import {SearchOptionsSpecification, SearchType} from "./managers/TypedSearchManager";

const P_CONTEXTS = ['1', '2', '3'] as const;
type CONTEXT_TYPES = typeof P_CONTEXTS[number];
type ContextFieldName = `p_context_${CONTEXT_TYPES}` | `s_context_${CONTEXT_TYPES}`
type ContextColName = `${ContextFieldName}_col_name` | `${ContextFieldName}_col_type`;

export type CompanyProfile = {
    [key in ContextColName]?: string;
} & {
    p_name_col_name: string
    p_description_col_name: string | false
    s_col_name?: string | false
    bu_col_name?: string | false
    /**
     * The preference of the context columns, if desired
     */
    p_context_pref?: number[]
    sortingConfiguration?: SortingConfiguration
    /**
     * The type of Googler search that should be used
     */
    searchConfiguration?: SearchConfiguration

    hide_main_menu?: boolean
    main_menu_components?: string[]
    tease_menu_components?: string[]

    show_synergies_main_menu?: boolean
    tease_main_menu?: boolean

    /**
     * When using a separate file for the taxonomy, only show the number of categories
     */
    taxonomy_builder_only_n_cats?: boolean

    only_categorization?: boolean

    /**
     * The nth-smallest number that should be visible, in the domain [0, 1]
     * i.e. for graph sizes [0, 10, 20] the 66% visibility means: number 10 should be at least visible (20px high)
     * Higher values lead to smaller diagrams
     */
    sankeyVisibilityFraction?: number

    sankeyMinHeight?: number
    sankeyMaxHeight?: number

    taxonomyBuilderHeight?: number

    /**
     * Note: This is BROKEN since move to materialized backend
     */
    allowReviewDownloadExcel?: boolean

    hide_direct_results?: boolean

    locale: string
    currency: string

    /**
     * We are not able to link a single taxonomy to multiple bags.
     * For this reason we hardcode the taxonomy in the frontend. CAT-353
     */
    hardcodeTaxonomyId?: number

    hardcodedKPIAbbreviation?: CurrencyAbbreviation

    /**
     * Allow the user to send for approval of a taxonomy which is not filled completely to it's deepest level
     */
    allowIncompleteTaxonomy?: boolean

    /**
     * Hide the send for approval buttons, (For demonstration builds only)
     */
    hideApprovalButtons?: true

    testAiIntegration?: true

    /**
     * A specific master bag to be used
     */
    hardcodeMasterBagId?: number
    hardcodedBagId?: number
    hardcodedCategorizationBagId?: number
    hardcodedCombinedBagId?: number
    hardcodedBaseTaxonomyId?: number
    hardcodedSubsidiaryBagId?: number
    hardcodedTaxonomySuggestionId?: number
    hardcodedOriginalTaxonomyId?: number

    /**
     * A specific master taxonomy to be used to merge datasets
     */
    masterTaxonomyId?: number

    /**
     * Use the modified ATK synergy dashboard
     * @deprecated TODO: Replace this with different deployment configuration
     */
    new_synergy_dashboard?: boolean

    hardcodedSynergyId?: number

    /**
     * view only option for the taxonomy builder
     *
     * For when we want the customer to not change the taxonomy anymore
     */
    taxonomyBuilderViewOnly?: boolean

    /**
     * By default, this is disabled
     */
    enableSankey?: boolean

    /**
     * Show the experimental opportunity tracker
     */
    enableOpportunityTracker?: boolean

    disableBuFilter?: boolean

    hideBuInCategorizationTable?: boolean

    fullMenu?: true;

    ppvDemo?: true;

    disableRunningOfModels?: boolean;
    masterCompanyName?: string;
    compareCompanyName?: string;
    showResultPills?: boolean;

    categorizationShowScore?: false;
    categorizationShowScoreForBag?: number[];

    /**
     * CAT-968: Add a hack to disable showing uncategorized
     */
    hackHideUncategorizedInReview?: true;

    /**
     * Switch for turning data ingestion on or off
     */
    useDataIngestion?: true;
    /**
     * Switch for turning ai suggestions in taxonomy builder on or off
     */
    useAiTaxonomySuggestion?: true;
    /**
     * Switch for turning looker studio preview card on or off
     */
    useLookerPreviewCard?: true;
    /**
     * Switch for turning taxonomy mapper card on or off
     */
    useTaxonomyMapperCard?: true;
    /**
     * Hide some example dummy opportunities in the opportunity tracker
     */
    hideHardcodedOpportunities?: true;
    /**
     * Cards that should only be shown as trial
     */
    trialCards?: DashboardCard[];
    /**
     * Show the sub bars in the categorization screen with uncategorized below it
     * (Only works well in equally distributed taxonomy)
     */
    showUncategorizedSubBars?: boolean

    /**
     * If the results are created beforehand, we can disable the supplier normalization model
     */
    skipAiSupplierNormalizationModel?: true
    /**
     * Switch for turning Supplier Normalization card on or off in the simple package offering
     */
    useSupplierNormalizationCard?: true;

    /**
     * TODO: Ad hoc switch for CAT-969
     * Hide KPI's of supplier normalization with very low values
     */
    hideSmallStandaloneSpend?: { minStandaloneSpend: number, minStandaloneSuppliers: number }

    /**
     * When the results are created beforehand, and we do not allow the user to run the model from the FE
     */
    skipAiTaxonomyMapperModel?: true
    /**
     * TODO: CAT-888: Remove this hack
     */
    hackNSuppliersFromCommonSuppliers?: true
    lookerUrls?: string[]
    displaySynergyOverview?: boolean
    hardcodedDatasetNameLeft?: string
    hardcodedDatasetNameRight?: string
    hardcodedNumberTotalSuppliersLeft?: number
    hardcodedNumberTotalSuppliersRight?: number
    hardcodedNumberTotalCommonSuppliers?: number
    hardcodedTotalSpendCommonSuppliers?: number
    hardcodedNumberCommonSuppliersLeft?: number
    hardcodedNumberCommonSuppliersRight?: number
    hardcodedTotalSuppliersSpendLeft?: number
    hardcodedTotalSuppliersSpendRight?: number
    hardcodedCommonSuppliersSpendLeft?: number
    hardcodedCommonSuppliersSpendRight?: number
    hardcodedNumberTotalCategoriesLeft?: number
    hardcodedNumberTotalCategoriesRight?: number
    hardcodedNumberTotalCommonCategories?: number
    hardcodedNumberCommonCategoriesLeft?: number
    hardcodedNumberCommonCategoriesRight?: number
    hardcodedTotalSpendCommonCategories?: number
    hardcodedTotalCategoriesSpendLeft?: number
    hardcodedTotalCategoriesSpendRight?: number
    hardcodedCommonCategoriesSpendLeft?: number
    hardcodedCommonCategoriesSpendRight?: number

    /**
     * CAT-1042: Added a hack to retrieve CommonSupplier KPI's from the DB, instead of calculating them (For SIG only)
     */
    hackRetrieveCommonSupplierLastKpis?: true

    /**
     * TODO: CAT-916: Decide on taxonomy categories flow
     * @deprecated
     */
    hackCategorizationUseLatestCategories?: true
    // This two values are used to determine on with taxonomy to hide the error for missing L while sending for approval
    hackTaxonomyToHideApprovalError?: number
    readOnlySuggestion?: boolean
    hideLinksUserDropDownMenu?: boolean
    /**
     * CAT-848: Use this field to specify relation data is to be displayed in
     * CategorizationReviewSubRow tables
     */
    categorizationReviewSubRowRelationData?: Array<CategorizationReviewSubRowRelationsData>
    /**
     * CAT-963: Use this to indicate if statistics should be fetched from normalization 
     */
    normalizationStatisticsSource?: 'm_kpi' | 'm_sp_review'
}

export const DEFAULT_SANKEY_VISIBILITY_FRACTION = .67;

function getCompanyProfile(): CompanyProfile {
    return C.profile;
}

type ContextColum = {
    colName: string,
    colType: string,
    dataField: string,
    contextI: CONTEXT_TYPES,
    colClassName: string
};

export type CategorizationReviewSubRowRelationsData = {
    relation: string,
    columns: Array<CategorizationReviewSubRowRelationData>
}

type CategorizationReviewSubRowRelationData = {
    db_column: string,
    colSpec: ColSpec
}

/**
 * TODO: Retrieve this from the BE or compile it to hide it from other customers
 * - We want this to be available from the start to render the right page.
 * - We want to hide it between customers
 */
export default class ProfileStore {
    public p: CompanyProfile = getCompanyProfile()

    constructor() {
        makeAutoObservable(this)

        // TODO CAT-744: Replace moment with Luxon

        // const locale = (window.navigator.userLanguage || window.navigator.language) || 'en-GB';
        // moment.locale(locale);
        // moment.locale('en-GB');

        // import 'moment/locale/nl';
        // import 'moment/locale/fa';

        moment.locale(this.p.locale);
        // console.log('moment.locales()', moment.locales())
    }

    hasCard(card: DashboardCard, bag?: Bag): boolean {
        if (this.p.trialCards !== undefined) {
            if (this.p.trialCards.includes(card)) {
                return false;
            }
        }
        // TODO: CAT-712
        if (this.p.hide_direct_results) {
            if (this.isDirectBag(bag)) {
                switch (card) {
                    case "improve-cats":
                        console.warn('Direct results are hidden for Customer')
                        return false;
                }
            }
        }
        if (this.p.only_categorization) {
            switch (card) {
                case "explore-cats":
                case "improve-cats":
                case "taxonomy-builder":
                case "data-ingestion":
                    return true;
                default:
                    return false;
            }
        }
        return true;
    }

    /**
     * Return the context column names
     * @returns {colName, colType, dataField, contextI}[]
     */
    get partContextColNames(): ContextColum[] {
        const result: ContextColum[] = []
        const contexts = this.p.p_context_pref || P_CONTEXTS
        for (const iC of contexts) {
            const C = String(iC) as CONTEXT_TYPES;
            const colName = this.p[`p_context_${C}_col_name`];
            if (colName === undefined) {
                return result;
            } else {
                const dataField = `p_context_${C}`
                const colType = this.p[`p_context_${C}_col_type`] || 'any';
                const colClassName = `col-${dataField}` + (colType === 'any' ? '' : ` col-type-${colType}`)
                result.push({colName, colType, dataField, contextI: C, colClassName})
            }
        }
        return result;
    }

    getColumnHeader(field: string): string {
        switch (field) {
            case 'p__name':
                return this.p.p_name_col_name
            case 'p__description':
                return this.p.p_name_col_name
            case 'p__mat_group_raw':
                // TODO: p__mat_group_raw should not be used anymore in the FE
                return this.p.p_context_1_col_name || 'Description'
        }
        console.error('Unknown field', field)
        return '?'
    }

    filterLastName(userLastName: string) {
        if (userLastName.toLowerCase() === environment.customerName.toLowerCase()) {
            // Filter last names with the same name as the company
            return ''
        }
        return userLastName;
    }

    /**
     * A Bag is considered direct if it contains the word 'Direct' in the name
     */
    private isDirectBag(bag: Bag | undefined) {
        if (!bag) return false;
        return bag.name.toLowerCase().match(/\bdirect\b/)
    }

    get currencyFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.p.locale, {style: 'currency', currency: this.p.currency});
    }

    get currencySymbol(): string {
        return (0).toLocaleString(this.p.locale, {
            style: 'currency',
            currency: this.p.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).replace(/\d/g, '').trim()
    }

    getCategoryViewConfig(selectedKpi: MatKpiTreeValues, nLinks: number, topLinkValue: number): Partial<HierarchicalSankeyOptions> {
        if (nLinks === 0) {
            return {};
        }
        const options: Partial<HierarchicalSankeyOptions> = {
            minValueForLabel: 0,
        }
        if (environment.customerName === 'vion') {
            // TODO: CAT-712
            // const zoom = 1.8
            // options.width = 1920 / zoom
            // options.height = 880 / zoom
            // options.margin.top = 50
            // options.margin.bottom = 10
            // options.margin.left = 100 + options.width / 6
            // options.margin.right = 100 + options.width / 6
            if (selectedKpi === 'parts' && topLinkValue >= 95255 / 2) {
                options.minValueForLabel = 4300;
            }
            if (selectedKpi === 'spend' && topLinkValue >= 715617252 / 2) {
                options.minValueForLabel = 4300;
            }
        }
        if (environment.customerName === 'franke') {
            // TODO: CAT-712
            options.margin = {
                left: 180,
                right: 80,
                top: 5,
                bottom: 5,
            }
            if (selectedKpi === 'parts') {
                options.minValueForLabel = 800;
            }
            if (selectedKpi === 'spend') {
                options.minValueForLabel = 6000000;
            }
        }

        return options;
    }

    get reviewTableColumnsMain(): ColSpec[] {
        // TODO: CAT-712
        const cols: (ColSpec | undefined)[] = [
            {cls: 'col-dropdown', txt: ''},
            {cls: 'col-s_name', txt: 'Vendor name'},
            {cls: 'col-s_spend', txt: 'Total spend'},
            {cls: 'col-l1s', txt: 'L1(s) of vendor'},
            this.p.hideBuInCategorizationTable ? undefined : {cls: 'col-bus', txt: 'Entity'},
            {cls: 'col-s_cats', txt: ''},
            {cls: 'col-s_YN', txt: 'Approve', align: 'right' as const},
        ]
        return cols.filter(c => c) as ColSpec[];
    }

    get supplierDataName() {
        return this.p.s_col_name || 'Supplier'
    }

    get buDataName() {
        return this.p.bu_col_name || 'Business Unit'
    }

    get partDescriptionColumnName() {
        return this.p.p_description_col_name || 'Description';
    }

    get partNameAndDescriptionColumnName() {
        const pName = this.p.p_name_col_name;
        const pDesc = this.partDescriptionColumnName;
        return `${pName} + ${pDesc}`;
    }

    get searchOptions(): SearchType[] {
        const pName = this.p.p_name_col_name;
        const pDesc = this.partDescriptionColumnName;
        const pContext1 = this.p.p_context_1_col_name || '';
        const pContext2 = this.p.p_context_2_col_name || '';

        // If context 1 is the date, skip it
        const pickContext1 = this.p.p_context_1_col_type !== 'date';
        let c = pickContext1 ? '2' as const : '1' as const;
        let pC, pND, pDC, pNDC, pNDC_, pAll;
        if (pName === 'Mat ID' && pDesc === 'Mat desc' && this.p.p_context_1_col_name === 'Mat group desc') {
            pC = 'Mat group desc'
            pND = 'Mat ID + desc';
            pDC = 'Mat desc + group desc';
            pNDC = 'Mat ID + desc + group desc';
            pNDC_ = 'Search by Mat or group';
            pAll = 'Supplier + Mat + group desc';
        } else {
            pC = pickContext1 ? pContext1 : pContext2;
            pND = `${pName} + ${pDesc}`;
            pDC = `${pName} + ${pC}`;
            pNDC = `${pName} + ${pDesc} + ${pC}`;
            pNDC_ = `Search by ${pName}, ${pDesc} or ${pC}`;
            pAll = `Search by ${this.supplierDataName}, ${pName}, ${pDesc} or ${pC}`;
        }
        return [
            {typeId: 'supplier', label: this.supplierDataName, placeholder: `Search by ${this.supplierDataName} name`},  // mat_supplier_review_row__s_name
            {typeId: 'p_name', label: pName, placeholder: `Search by ${pName}`},
            {typeId: 'p_description', label: pDesc, placeholder: `Search by ${pDesc}`},
            {typeId: `p_context_${c}`, label: pC, placeholder: `Search by ${pC}`},
            {typeId: 'p_name_description', label: pND, placeholder: `Search by ${pND.replace('+', 'or')}`},
            {typeId: `p_description_context_${c}`, label: pDC, placeholder: `Search by ${pDC.replace('+', 'or')}`},
            {typeId: `p_name_description_context_${c}`, label: pNDC, placeholder: pNDC_},
            {typeId: `all_context_${c}`, label: pAll, placeholder: 'Search all'},
        ]
    }

    get supplierSearchOption(): SearchType {
        return {
            typeId: 'supplier',
            label: this.supplierDataName,
            placeholder: `Search by ${this.supplierDataName} name`
        };
    }

    get searchOptions1(): SearchOptionsSpecification {
        return {
            defaultIndex: 0,
            options: [
                this.searchOptions[0],
            ],
        }
    }

    get searchOptions2(): SearchOptionsSpecification {
        return {
            defaultIndex: 4,
            options: [
                this.searchOptions[1],
                this.searchOptions[2],
                this.searchOptions[3],
                this.searchOptions[4],
                this.searchOptions[5],
                this.searchOptions[6],
                this.searchOptions[7],
            ],
        }
    }

    get searchOptions3(): SearchOptionsSpecification {
        return {
            defaultIndex: 0,
            options: [],
        }
    }

    isColumnSortable(dataField: ColSpec['columnFieldId'] | undefined): boolean {
        switch (this.p.sortingConfiguration) {
            case 'by_default':
                switch (dataField) {
                    case 'p_spend':
                    case 'p_suggestion_score':
                    default:
                        return false;
                }
            case 'by_default_and_c1':
                switch (dataField) {
                    case 'p_spend':
                    case 'p_suggestion_score':
                    case 'p_context_1':
                        return true;
                    default:
                        return false;
                }
            default:
                return false;
        }
    }

    showCategorizationScoreForBag(bagId: number | undefined) {
        if (this.p.categorizationShowScore) return true;
        if (bagId === undefined) return false;
        return this.p.categorizationShowScoreForBag?.includes(bagId) || false
    }
}
