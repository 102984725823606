import './DataIngestion.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Button, Grid, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from '../../routing/routes';
import {generatePath, useHistory} from "react-router";
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {ChevronRight} from '@mui/icons-material';


export const IS_TE_DEVELOPMENT_MODE = false


type Props = {
    viewer?: boolean
}
export const DatasetOverviewPage: React.FC<Props> = observer(({viewer}) => {
    const {bagStore, dataIngestionStore, p} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;

    const history = useHistory();
    useEffect(() => {
        dataIngestionStore.getDatasetList()
    }, [dataIngestionStore, p])

    return (
        <KoiFacade
            title='Data Ingestion'
            back={generateHomePath(routeMatch, {id: String(bagId)})}
            className="data-ingestion data-ingestion-page"
        >

            <Grid container style={{marginTop: '3em'}} justifyContent="center"
                  className="data-ingestion dataset-overview-table-titles">
                <Grid item xs={2}>
                    <Typography variant="h6" sx={{mb: 2}}>Dataset name</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" sx={{mb: 2}}>Dataset status</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h6" sx={{mb: 2}}>Upload date</Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>

            <Grid container style={{maxHeight: '30em', overflowX: 'scroll'}} justifyContent="center"
                  className="data-ingestion dataset-overview-table-content">
                {dataIngestionStore.datasetList?.map((dataset) => (
                    <Grid container style={{marginTop: '3em'}} justifyContent="center" key={'dataset' + dataset.id}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{mb: 2}}>{dataset.name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{mb: 2}}>{dataset.current_status.name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{mb: 2}}>{dataset.date}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                color="primary"
                                variant="outlined"
                                startIcon={<ChevronRight/>}
                                sx={{mb: 2}}
                                onClick={() => history.push(generatePath(routes.data_upload_mapping, {dataFileId: String(dataset.id)}))}
                            >
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                    </Grid>
                ))}
            </Grid>


            <Grid container style={{marginTop: '3em'}}>
                <Grid item xs={10}>
                </Grid>
                <Grid item xs={2} justifyContent="flex-end">
                    <Button
                        onClick={() => history.push(generatePath(routes.data_upload))}
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                    >
                        Upload new dataset
                    </Button>
                </Grid>
            </Grid>
        </KoiFacade>
    )
});
