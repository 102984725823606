import {RequestManager} from "./managers/RequestManager";
import {AxiosResponse} from "axios";
import {from} from "rxjs";
import {MatCommonKpiSerializer} from "../services/ApiTypes";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {ParentSupplierDelegate} from "./normalization/ParentSupplierDelegate";
import {environment} from "../env";
import {ParentSupplierReviewDelegate} from "./normalization/ParentSupplierReviewDelegate";
import {
    ParentSupplierPostReviewStats,
    ParentSupplierPreReviewStats
} from "../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {C} from "../configurations/active-configuration";

type State = 'start' | 'running' | 'clean_parents' | 'apply' | 'applying' | 'done';

export class SupplierNormalizationStore {
    state: State = environment.isTest ? 'clean_parents' : 'clean_parents';

    databagId: number | undefined;
    selectedCategory: string[] = [];
    maxTaxonomySize = 2; // Do not allow to go deeper than L2 initially
    isMaster = false;
    supplierNormalizationKpi: number | undefined = undefined;

    readonly _rootStatRequestManager = new RequestManager<{ databag: number }, AxiosResponse<MatCommonKpiSerializer>>(
        ({databag}) => from(this.api.getCommonKpi(databag))
    );
    readonly _preStatsRequestManager = new RequestManager<{
        databag: number
    }, AxiosResponse<ParentSupplierPreReviewStats>>(
        ({databag}) => from(this.api.getParentSupplierReviewPreStats(databag))
    );
    readonly _postStatsRequestManager = new RequestManager<{ databag: number }, ParentSupplierPostReviewStats>(
        ({databag}) => from(
            C.profile.hackRetrieveCommonSupplierLastKpis
                ? this.api.getParentSupplierReviewLastPostStats(databag)
                : this.api.getParentSupplierReviewPostStats(databag)
        )
    );

    readonly ps = new ParentSupplierDelegate(this.api, this);
    readonly review = new ParentSupplierReviewDelegate(this.api, this);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)

        reaction(() => ([this.databagId, this.ps._parentSupplierManager.result] as const), ([databag, result]) => {
            console.log('SupplierNormalizationStore.reaction', databag, result?.data.length)
            if (databag && result) {
                // Only when the _parentSupplierManager is done re-trigger the startRowRequest
                this.review.filterDelegate.startRowsRequest();
                this.supplierNormalizationKpi = this.postKeyValues?.parent_suppliers_count;
            }
        })
    }

    init(databagId: number, taxonomySize: number) {
        console.log('SupplierNormalizationStore.init', databagId, taxonomySize)
        this.databagId = databagId;
        this.maxTaxonomySize = taxonomySize;
        this.selectedCategory = [];

        this._rootStatRequestManager.request({databag: databagId})
        this._preStatsRequestManager.request({databag: databagId})
        this._postStatsRequestManager.request({databag: databagId})

        // Cleanup old results
        this.review.filterDelegate.reset();
        this.review._parentSupplierRowsRequest.reset(); // Ensures that the subRow request is stopped
        this.ps._parentSupplierManager.cleanup();

        // Request new result, and trigger the reaction in the constructor
        this.ps._parentSupplierManager.request({databag: databagId})
    }

    refreshReview() {
        this.review._parentSupplierRowsRequest.reset(); // Ensures that the subRow request is stopped
        if (!this.databagId) return;
        this.ps._parentSupplierManager.request({databag: this.databagId})
        this._postStatsRequestManager.request({databag: this.databagId})
    }

    goToState(state: State) {
        this.state = state;
    }

    get stepNumber(): number {
        switch (this.state) {
            default:
            case 'start':
            case 'running':
                return 0;
            case 'clean_parents':
                return 1;
            case 'apply':
            case 'applying':
                return 2;
            case 'done':
                return 3;
        }
    }

    get preKeyValues(): ParentSupplierPreReviewStats | undefined {
        return this._preStatsRequestManager.result?.data;
    }

    get postKeyValues(): ParentSupplierPostReviewStats | undefined {
        return this._postStatsRequestManager.result;
    }

    resetState() {
        // Reset it to results
        this.state = 'clean_parents';
        this.selectedCategory = [];
    }

    gotoRunModelState() {
        this.state = 'running';
    }

    gotoApplyingState() {
        this.state = 'applying';
    }

    // get activeFilter(): AbsTreeNodeFilter | undefined {
    //     if (this.databag_id === undefined) return undefined;
    //     const level = this.selectedCategory.length
    //     const selectedCats = catsToDict(this.selectedCategory, level);
    //     return {
    //         type: 'children',
    //         databag: this.databag_id,
    //         level: level,
    //         ...selectedCats,
    //     }
    // }
}
