import React from "react";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";


type Props = {
    cats: string[] | false
}
export const PartCategorizationComponent: React.FC<Props> = ({cats}) => {
    // const {categorizationStore} = useStores();
    if (cats === false) {
        return <>{UNCATEGORIZED_LABEL}</>;
    }
    return <>
        {cats.map((c, i) => {
            let categoryLabel = c === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : c;
            // Alternative: Use a chip
            // <Chip
            //     // variant={categorizationStore.data.filterManager.selectedCategory === c ? "default" : "outlined"}
            //     variant="outlined"
            //     // TODO: This is a bit oversimplified, remove it for now?
            //     color={categorizationStore.data.filterManager.selectedL1Category === c ? "primary" : "default"}
            //     size="small"
            //     label={categoryLabel}/>
            return <React.Fragment key={i}>
                {categoryLabel}
                {(i < cats.length - 1) ? ' > ' : ''}
            </React.Fragment>
        })}
    </>
}
