import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {Alert, AlertTitle, Button, Grid} from "@mui/material";
import React from "react";
import {useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../routing/routes";

export const DashboardGridAlert = () => {
    const {bagStore} = useStores();
    const bag = bagStore.bag as Bag;
    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    if (!newDatasetId || bagStore.allBags === undefined) return <></>
    return <div className="dashboard-grid-alert">
        <Grid container justifyContent="center">
            <Grid item xs={4}>
                <Alert variant="standard" severity="warning" action={
                    <Button
                        color="inherit" size="small"
                        onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                        Open the new dataset
                    </Button>
                }>
                    <AlertTitle>This is an old version</AlertTitle>
                    This dataset is reviewed and all the changes are applied in a newer version of the dataset
                </Alert>
            </Grid>
        </Grid>
    </div>
}
