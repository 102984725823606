import './TaxonomyMapper.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {Button, Grid} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import {BaseTaxonomySupplierTable} from "./mapper/BaseTaxonomySupplierTable";
import {TaxonomyMapperComponent} from "./mapper/TaxonomyMapperComponent";
import {ChevronRight, Refresh} from "@mui/icons-material";
import {TaxonomyMapperToolbar} from "./mapper/TaxonomyMapperToolbar";
import {generateHomePath} from "../../routing/routing.utils";
import {environment} from "../../env";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import {C} from "../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from '../../stores/hack';

export const TaxonomyMapperPage: React.FC = observer(() => {
    let {p, rootAppStore, taxonomyMapperStore, synergyStore} = useStores();
    if (C.package === 'merge_x' && C.profile.masterCompanyName === 'SHV') {
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    // const theme = useTheme();
    // const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    const dataCategoriesLeft: Data = {
        top: {
            id: "1",
            total: synergyStore.commonCategoriesDelegate.numberTotalCategoriesLeft,
            value: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft,
            valueTitle: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft.toString(),
            valueTitleHover: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonCategoriesDelegate.totalCategoriesSpendLeft,
            value: synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft, 3,),
        },
    };

    const dataCategoriesRight: Data = {
        top: {
            id: "1",
            total: synergyStore.commonCategoriesDelegate.numberTotalCategoriesRight,
            value: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight,
            valueTitle: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight.toString(),
            valueTitleHover: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonCategoriesDelegate.totalCategoriesSpendRight,
            value: synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight, 3,),
        },
    };

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }


    return <KoiFacade
        title="Taxonomy Mapper"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, undefined, rootAppStore.app)}
        next={(environment.package === 'merge_x')
            ? undefined // For the merge_x package the Mapping Overview does not exist
            : {
                type: 'component',
                node: <Button
                    color="primary"
                    variant="outlined"
                    endIcon={<ChevronRight/>}
                    onClick={() => {
                        if (environment.package === 'merge_1') {
                            // In the merge_1 package there are no id's in the url
                            history.push(generatePath(routes.merge_1_common_categories_review, routeMatch.params))
                            return
                        }

                        // After the taxonomy mapper is completed, go to the review page
                        history.push(generatePath(routes.taxonomy_mapper_review, routeMatch.params))
                        // // TODO: CAT-712
                        // if (env === 'simple') {
                        //     // TaxonomyMapperReviewPage
                        //     history.push(generatePath(routes.pic_common_categories_review, routeMatch.params))
                        // } else if (env === 'nestle') {
                        //     history.push(generatePath(routes.nestle_common_categories_review, routeMatch.params))
                        // } else if (env === 'multi') {
                        //     // TaxonomyMapperReviewPage
                        //     history.push(generatePath(routes.synergy_merge_taxonomy_mapper_review, routeMatch.params))
                        // } else {
                        //     // TaxonomyMapperReviewPage
                        //     history.push(generatePath(routes.synergy_v2_taxonomy_review, routeMatch.params))
                        // }
                    }}>
                    Review
                </Button>
            }
        }
        className="taxonomy-mapper-page centered-header">
        {environment.package === 'merge_2' && <Grid container justifyContent="center" style={{marginTop: '2em'}}>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">{synergyStore.datasetNameLeft}</Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">{synergyStore.datasetNameRight}</Typography>
            </Grid>
            <Grid item md={2}></Grid>

            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={dataCategoriesLeft}
                    options={optionsLeft}
                />
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={dataCategoriesRight}
                    options={optionsRight}
                />
            </Grid>
            <Grid item md={2}></Grid>
        </Grid>}

        <Grid container justifyContent="end" className="taxonomy-titles">
            <Grid item sm={4}>{/* Or 7 to let the title span over the table as well*/}
                <Typography variant="h3" className="taxonomy-title base-title">
                    {taxonomyMapperStore.baseTaxonomyName}
                </Typography>
            </Grid>
            <Grid item sm={1} className="text-center">
                <Button
                    aria-label="refresh"
                    onClick={() => window.location.reload()}
                    disabled={taxonomyMapperStore.mapper.busy}>
                    <Refresh/>
                </Button>
            </Grid>
            <Grid item sm={4}>
                <Typography variant="h3" className="taxonomy-title target-title">
                    {taxonomyMapperStore.targetTaxonomyName}
                </Typography>
            </Grid>
        </Grid>

        <section className="taxonomy-mapper-main">
            <Grid container className="taxonomy-mapper-main-header">
                <Grid item sm={3}/>
                <Grid item sm={9}>
                    <TaxonomyMapperToolbar/>
                </Grid>
            </Grid>
            <Grid container className="taxonomy-mapper-main-body">
                <Grid item sm={3}>
                    <BaseTaxonomySupplierTable/>
                </Grid>
                <Grid item sm={9} id="taxonomy-mapper-viz-wrapper">
                    <TaxonomyMapperComponent/>
                </Grid>
            </Grid>
        </section>
    </KoiFacade>
});
