import React, {useMemo} from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import {Button} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {
    PartReCategorizationControllerStore
} from "../../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../../components/categorization/PartRecategorizationHandler";

type Type = {}
export const ChangeCategoryButton: React.FC<Type> = observer(() => {
    const {categorizationStore, categorizationReviewStore} = useStores();

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    return <>
        {controller && <PartReCategorizationHandler
            cmpId="review-filter-bar"
            controller={controller}
            label="Change the category"
        />}
        <Button
            variant={categorizationStore.data.anySelected ? "contained" : "text"}
            // variant="contained"
            disabled={!categorizationStore.data.anySelected}
            endIcon={<KeyboardArrowDown/>}
            onClick={event => {
                const anchor = event.currentTarget;
                controller.setOnSelect((newCats) => {
                    categorizationReviewStore.clickRecatSelectedParts(newCats);
                })
                controller.open(anchor)
            }}
        >
            Overwrite selection
        </Button>
    </>
})
