import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {
    MatchCategoriesTreeVisualization,
    Options
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";
import {C} from "../../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from "../../../stores/hack";

export const TaxonomyMapperComponent: React.FC = observer(() => {
    let {rootAppStore, taxonomyMapperStore} = useStores();
    if (C.package === 'merge_x') {
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }

    const data = taxonomyMapperStore.mapper.vizTreeData;
    const options = useMemo<Partial<Options>>(() => ({
        width: 1350,
        height: 0,
        onClickData: d => taxonomyMapperStore.selection.onClickData(d),
        leftFilterSpec: taxonomyMapperStore.filter.getLeftAsFilterSpecification(),
        rightFilterSpec: taxonomyMapperStore.filter.getRightAsFilterSpecification(),
    }), [taxonomyMapperStore.selection, taxonomyMapperStore.filter])

    return <MatchCategoriesTreeVisualization
        data={data}
        options={options}
        matchUpdateData={taxonomyMapperStore.mapper.connectionData}
    />
})
