import './CollapsibleSuggestionIndentTree.scss';
import {D3GSelection} from '../../../utils/global';
import * as d3 from 'd3';
import {ScaleBand} from 'd3';
import React from "react";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {SuggestionTreeStoreType} from "../../../stores/TaxonomySuggestorStore";
import Margin from "../../../utils/margin";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import {getHardcodedLabel} from "../collapsible-indent-tree/labelmapping";
import {hierarchy, HierarchyNode} from "d3-hierarchy";
import {ApiSuggestionTreeResponse, ApiUpdateSuggestionTreeResponse} from "../../../services/ApiHelpers";
import {TSTB_FILTERS} from "../../../pages/taxonomy-suggestor/TaxonomySuggestorPage";
import ProfileStore from "../../../stores/ProfileStore";

export type UpdateData = Partial<{
    filter: number
    hiddenRoot: boolean
    hiddenUncat: boolean
    hiddenColumns?: true
}>

export type Options = {
    height: number
    width: number
    margin: Margin
    leftToRight: boolean
    // $filter?: FilterSpecification
    overrideOnClick?: (d: ExtendedTree) => void,
    onDataSelectionChange?: (old: SuggestionTreeDataType | undefined, newValue: SuggestionTreeDataType | undefined) => void
    columns: Column[]
    onDataChanged?: (d) => void
    overrideOnClickSelect?: (d: ExtendedTree | undefined) => void
    profile: ProfileStore
}


type Column = {
    columnTitle: string;
    getValue: (d: d3.HierarchyNode<SuggestionTreeDataType>, c: TaxonomySuggestionTreeBuilder) => string,
    xOffset: number
};

type ReviewStatusType = {
    hasChangesInChildren?: boolean;
    deleted: boolean;
    added: boolean;
    renamed: boolean;
    accepted: boolean;
    rejected: boolean;
    oldLabel: string;
    newLabel: string;
};

/**
 * TreeDataType is the data structure to contain the data of the tree
 */
export type SuggestionTreeDataType = {
    id: number;
    label?: string;
    values: m_taxonomy.Data;
    children: SuggestionTreeDataType[]
    reviewStatus?: ReviewStatusType
}


type Tree = d3.HierarchyNode<SuggestionTreeDataType>;

/**
 * This shouldn't be exported...
 */
/**
 * ExtendedTree extend the TreDataType to have more information about hte rendering of the tree
 */
export interface ExtendedTree extends d3.HierarchyNode<SuggestionTreeDataType> {
    id: string
    collapsed: boolean
    filtered: boolean
    canOpen: boolean
    children: this[] // The current children to show
    _children: this[] // The original complete set of children that can be shown
    highlighted: boolean
    selected: boolean
    childSelected: boolean
}


// TODO: This does not work for mixed characters, please check
const MAX_LABEL_SIZE = 70

const ARROW_RIGHT = 'M7 4L1 8V0z';
const ARROW_LEFT = 'M0 4L6 8V0z';

export class TaxonomySuggestionTreeBuilder {
    private readonly data: ExtendedTree;

    nodeSize = 20

    duration = 0;

    nodeGroup: D3GSelection<any>;
    linkGroup: D3GSelection<any>;
    iconGroup: D3GSelection<any>;
    headersContainer: D3GSelection;
    svg: D3GSelection = d3.select('.taxonomy-suggestion-tree-viz')

    disabledClick = false;
    prevSelected: ExtendedTree | undefined;

    yAxis: ScaleBand<string> | undefined;
    hiddenColumns = false;
    private rootYOffset = 0;

    private onRedraw: undefined | (() => void);
    private onClick: (d: ExtendedTree) => void;
    private onClickSelection: (d: ExtendedTree) => void;
    constructor(
        private readonly root: D3GSelection<any>,
        private readonly options: Options,
        data: ExtendedTree | HierarchyNode<SuggestionTreeDataType>, //SuggestionTreeStoreType<m_taxonomy.Data> |
        public hiddenRoot, // TODO: Duplicate defined
        private readonly sortBy?: (a: m_taxonomy.Data, b: m_taxonomy.Data) => number,
    ) {
        this.root
            .classed('collapsible-suggestion-indent-tree-tree', true)
            .attr("transform", "translate(" + options.margin.left + "," + options.margin.top + ")");


        this.nodeGroup = this.root.append('g').classed('nodes', true);
        this.linkGroup = this.root.append('g').classed('links', true);
        this.iconGroup = this.root.append('g').classed('icons', true)
        this.headersContainer = this.root.append('g').classed('headers', true)

        if (this.sortBy) {
            const sortBy = this.sortBy;
            data.sort((a, b) => sortBy(a.data.values, b.data.values))
        }

        this.data = this.initNodes(data)

        // // Setup collapsible behavior based on level
        // if (options.$filter) {
        //     if (options.$filter.byLevel) {
        //         this.filterSubscription.add(options.$filter.byLevel.subscribe(level => {
        //             this.openToLevel(level)
        //             this.redraw()
        //         }))
        //     }
        //     if (options.$filter.byLabel) {
        //         this.filterSubscription.add(options.$filter.byLabel.subscribe(label => {
        //             this.filterByLabel(label)
        //             this.redraw()
        //         }))
        //     }
        // }

        if (options.overrideOnClick) {
            this.onClick = options.overrideOnClick;
        } else {
            this.onClick = d => {
                if (this.disabledClick) return;

                if (!d.canOpen) return;

                TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, !d.collapsed)
                // d.collapsed = !d.collapsed;
                // d.children = d.children ? null : d._children;

                this.redraw();
            }
        }


        this.onClickSelection = d => {
            if (this.disabledClick) return;

            //If the node is already selected, unselect it
            if (d.id === this.prevSelected?.id) {
                d.selected = false;
                this.prevSelected = undefined;
                this.headersContainer.classed('hidden', false)

                //resize the viewbox
                this.svg.attr('viewBox', `0 0 1500 5000`)

                if (options.overrideOnClickSelect) {
                    options.overrideOnClickSelect(undefined);
                }

            } else {
                //Unselect the previous node
                const prevSelectedNode = this.data.descendants().find(d => d.data.id === this.prevSelected?.data.id);
                prevSelectedNode?.selected && (prevSelectedNode.selected = false);

                //Select the new node
                d.selected = true;
                this.prevSelected = d;

                //Hide d3 table
                this.headersContainer.classed('hidden', true)

                if (options.overrideOnClickSelect) {
                    options.overrideOnClickSelect(d);
                }

                //resize the viewbox
                this.svg.attr('viewBox', `0 0 625 5000`)

            }

            console.log('Selected node ' + d.data.label)

            this.redraw();
        }


        // // identifyDescendants
        // this.data.descendants().forEach((d, i) => {
        //     if (d.id === undefined) {
        //         (d as any).id = String(i++);
        //     }
        //     this.initDescendantDefaultCollapseState(d);
        // })

        // // DEBUG: show draw area
        // root.append('rect')
        //     .attr('x', 0)
        //     .attr('y', 0)
        //     .attr('width', this.options.width)
        //     .attr('height', this.options.height);
    }


    // exportTree() {
    //     console.log('COMPONENT: rootNode', this.data)
    //     return TaxonomySuggestionTreeBuilder.exportTree(this.data)
    // }

    private static exportTree(node: ExtendedTree): SuggestionTreeDataType {
        return {
            id: node.data.id,
            label: node.data.label || '',
            children: (node.children || []).map(c => this.exportTree(c)),
            values: node.data.values,
            reviewStatus: node.data.reviewStatus,
        }
    }

    private static exportTreeForStore(node: ExtendedTree): SuggestionTreeStoreType<m_taxonomy.Data> {
        return {
            label: node.data.label || '',
            children: (node.children || []).map(c => this.exportTreeForStore(c)),
            values: node.data.values,
            review: {
                apiData: {
                    deleted: node.data.reviewStatus?.deleted || false,
                    added: node.data.reviewStatus?.added || false,
                    renamed: node.data.reviewStatus?.renamed || false,
                    accepted: node.data.reviewStatus?.accepted || false,
                    rejected: node.data.reviewStatus?.rejected || false,
                    oldLabel: node.data.reviewStatus?.oldLabel || '',
                    newLabel: node.data.reviewStatus?.newLabel || '',
                },
                stateData: {
                    hasChangesInChildren: node.data.reviewStatus?.hasChangesInChildren || false
                },
            },
        }
    }

    private static exportTreeForPut(node: ExtendedTree): ApiUpdateSuggestionTreeResponse<m_taxonomy.Data> & {
        id: Number
    } {
        //Make sure that when updating the suggestion it will not append the review_status to the root but it will do it to the children

        return {
            id: node.data.id,
            label: node.data.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.values,
        }
    }

    private static exportTreeForPutChildren(node: ExtendedTree): ApiSuggestionTreeResponse<m_taxonomy.Data> & {
        id: Number
    } {
        return {
            id: node.data.id,
            label: node.data.label || '',
            children: (node.children || []).map(c => this.exportTreeForPutChildren(c)),
            values: node.data.values,
            review_status: {
                deleted: node.data.reviewStatus?.deleted || false,
                added: node.data.reviewStatus?.added || false,
                renamed: node.data.reviewStatus?.renamed || false,
                accepted: node.data.reviewStatus?.accepted || false,
                rejected: node.data.reviewStatus?.rejected || false,
                oldLabel: node.data.reviewStatus?.oldLabel || '',
                newLabel: node.data.reviewStatus?.newLabel || '',
            }
        }
    }


    update(u: UpdateData) {
        if (u) {
            this.hiddenColumns = u.hiddenColumns || false;
            const hideUncat = Boolean(u.hiddenUncat);
            if (u.filter && u.filter >= TSTB_FILTERS.length) {
                console.warn(`Cannot apply filter with index ${u.filter}`)
                applyFilter(this.data as any, hideUncat, undefined)
            } else {
                const hideFilter = u.filter ? TSTB_FILTERS[u.filter] : undefined;
                applyFilter(this.data as any, hideUncat, hideFilter);
            }
        }
        this.redraw()
    }


    setRootYOffset(yOffset: number) {
        this.rootYOffset = yOffset;
        this.root
            .attr("transform", "translate("
                + this.options.margin.left + ","
                + (this.options.margin.top + this.rootYOffset) + ")")
    }

    redraw() {
        /**
         * Nodes are the rows in the tree
         */
        const nodeData = this.getNodes();
        const openNodeData = nodeData.filter(d => d.canOpen);

        /**
         * Links are the connections between the nodes
         */
        const linkData = this.data.links()
            .filter(d => !(d.source as ExtendedTree).filtered && !(d.target as ExtendedTree).filtered)
            .filter(d => !this.hiddenRoot || d.source.depth > 0)
        const S = this.options.leftToRight ? 1 : -1;

        const yAxis: (d: any) => number = this.yAxis !== undefined
            ? (d: any) => this.yAxis?.(String(d.data.id)) || 0
            : (d: any) => (d.index + (this.hiddenRoot ? +0.5 : 1.5)) * this.nodeSize;

        /**
         * left to right:
         *   x0             x1
         * |-*  bla bla     |
         *
         * right to left:
         * x0            x1
         * |     bla bla  *-|
         */
        let x0Axis: (d: ExtendedTree) => number;
        let x1Axis: (d: ExtendedTree) => number;
        const xAxisOffset = (this.hiddenRoot ? -this.nodeSize : 0) * S;
        if (this.options.leftToRight) {
            x0Axis = (d: ExtendedTree) => d.depth * this.nodeSize + xAxisOffset;
            x1Axis = () => this.options.width;
        } else {
            x0Axis = () => 0;
            x1Axis = (d: ExtendedTree) => this.options.width - (d.depth * this.nodeSize) + xAxisOffset;
        }
        /**
         * The axis where the dot should end up
         */
        const xAxis = this.options.leftToRight ? x0Axis : x1Axis

        const transition = this.root.transition()
            .duration(this.duration)

        // Update the nodes…
        const node = this.nodeGroup.selectAll<SVGGElement, ExtendedTree>('g.root-group').data(nodeData, d => d.id);

        const nodeEnter = this.redrawNodes(node, yAxis, xAxis, x0Axis, S, x1Axis);


        this.updateColumns(nodeEnter)

        // Transition nodes to their new position.
        // nodeUpdate
        const nodeUpdateRaw = node.merge((nodeEnter as any))
            .classed('highlighted', d => d.highlighted)
            .classed('selected', d => d.selected)
            .classed('child-selected', d => d.childSelected)

        const nodeUpdate = nodeUpdateRaw
            .transition((transition as any))
            .attr('transform', d => `translate(0,${yAxis(d)})`)
            .attr('cursor', d => 'pointer')
            .attr('pointer-events', 'all')
            .attr('fill-opacity', 1)
            .attr('stroke-opacity', 1)

        nodeUpdate.select('circle')
            .attr('r', d => d.canOpen ? 0 : 2.5)
            .attr('fill', d => d.canOpen ? null : '#999')

        const nodeLabelsUpdate = nodeUpdateRaw.select('text')

        nodeLabelsUpdate.select('tspan')
            .text(d => {
                let l = ''
                let postL = ''

                if (d.data.reviewStatus?.hasChangesInChildren) {
                    // l = '> ';
                    postL = ' *';
                }

                if (d.data.reviewStatus?.renamed) {
                    return l + postL;
                }

                const label = getHardcodedLabel(d.data.label)
                l += String(label)

                if (d.data.reviewStatus?.deleted && d.data.reviewStatus?.added) {
                    return l + postL;
                } else if (d.data.reviewStatus?.deleted) {
                    return l + postL;
                } else if (d.data.reviewStatus?.added) {
                    return l + postL;
                }

                if (l.length > MAX_LABEL_SIZE) {
                    return l.substring(0, MAX_LABEL_SIZE) + ' (...)' + postL;
                }
                return l + postL;
            })

        nodeLabelsUpdate.select('tspan.rename-left')
            .text(d => {
                if (d.data.reviewStatus?.renamed) {
                    return d.data.reviewStatus?.oldLabel
                }
                return ''
            })
        nodeLabelsUpdate.select('tspan.rename-right')
            .text(d => {
                if (d.data.reviewStatus?.renamed) {
                    return d.data.reviewStatus?.newLabel
                }
                return ''
            })

        // Update the labels
        nodeUpdateRaw.select('.rename-arrow')
            .classed('hidden', d => !d.data.reviewStatus?.renamed || d.data.reviewStatus?.accepted || d.data.reviewStatus?.rejected)

        nodeUpdateRaw.select('tspan.rename-left') // tspan-left
            .text(d => {
                if (d.data.reviewStatus?.renamed) {
                    if (d.data.reviewStatus?.accepted) {
                        return d.data.reviewStatus?.newLabel;
                    }
                    if (d.data.reviewStatus?.rejected) {
                        return d.data.reviewStatus?.oldLabel;
                    }
                    return d.data.reviewStatus?.oldLabel;
                }
                return ''
            })
        nodeUpdateRaw.select('tspan.rename-right') // tspan-right
            .text(d => {
                if (d.data.reviewStatus?.renamed) {
                    if (d.data.reviewStatus?.accepted) {
                        return ` (${d.data.reviewStatus?.oldLabel})`;
                    }
                    if (d.data.reviewStatus?.rejected) {
                        return ` (${d.data.reviewStatus?.newLabel})`;
                    }
                    return ` (${d.data.reviewStatus?.newLabel})`;
                }
                return ''
            })
            .classed('strikethrough', d => {
                if (d.data.reviewStatus?.renamed) {
                    if (d.data.reviewStatus?.accepted || d.data.reviewStatus?.rejected) {
                        return true;
                    }
                }
                return false;
            })

        //This add a class just to the btn groups
        const options = this.options;
        const nodeSize = this.nodeSize;
        nodeUpdateRaw.selectAll<SVGGElement, ExtendedTree>('.button-group-accept')
            .classed('active', d => Boolean(d.data.reviewStatus?.accepted))
            .attr('transform', function (d) {
                // if(!this.parentNode) return '';
                const parentSelection = d3.select(this.parentElement)
                const labelSelection = parentSelection.select<SVGTextElement>('text.label')
                const textWidth = labelSelection.node()?.getComputedTextLength()
                if (!textWidth) return '';

                // return `translate(${textWidth},${nodeSize - 31})`;
                return `translate(${x0Axis(d) + textWidth + 25},${nodeSize - 31})`;
            })

        nodeUpdateRaw.selectAll<SVGGElement, ExtendedTree>('.button-group-reject')
            .classed('active', d => Boolean(d.data.reviewStatus?.rejected))
            .attr('transform', function (d) {
                // if(!this.parentNode) return '';
                const parentSelection = d3.select(this.parentElement)
                const labelSelection = parentSelection.select<SVGTextElement>('text.label')
                const textWidth = labelSelection.node()?.getComputedTextLength()
                if (!textWidth) return '';

                // return `translate(${textWidth},${nodeSize - 31})`;
                return `translate(${x0Axis(d) + textWidth + 45},${nodeSize - 31})`;
            })

        //This add a class on the while node
        nodeUpdateRaw
            .classed('renamed', d => Boolean(d.data.reviewStatus?.renamed))
            .classed('added', d => Boolean(d.data.reviewStatus?.added))
            .classed('deleted', d => Boolean(d.data.reviewStatus?.deleted))
            .classed('has-changes-in-children', d => Boolean(d.data.reviewStatus?.hasChangesInChildren))
            .classed('not-chosen', d => !Boolean(d.data.reviewStatus?.accepted) && !Boolean(d.data.reviewStatus?.rejected))
            .classed('accept', d => Boolean(d.data.reviewStatus?.accepted))
            .classed('reject', d => Boolean(d.data.reviewStatus?.rejected))
            .classed('hidden-columns', this.hiddenColumns)


        // Transition exiting nodes to the parent's new position.
        // nodeExit
        node.exit()
            .transition((transition as any))
            .remove()
            .attr('transform', d => `translate(0,${yAxis(d)})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0);

        // Update the links…
        const link = this.linkGroup.selectAll('path')
            .data(linkData, d => (d as any).target.id);

        // Enter any new links at the parent's previous position.
        const xOffset = -2, yOffset = 3;

        const drawPath = this.options.leftToRight
            ? (link) => `M${xAxis(link.source)},${yAxis(link.source) + yOffset}
            V${yAxis(link.target)}
            h${this.nodeSize + xOffset}`
            : (link) => `M${xAxis(link.source)},${yAxis(link.source) + yOffset}
            V${yAxis(link.target)}
            h${(this.nodeSize + xOffset) * S}`

        const linkEnter = link.enter().append('path')//.transition(transition)
            .attr('d', link => drawPath(link));

        // Transition links to their new position.
        link.merge((linkEnter as any))//.transition(transition)
            .attr('d', link => drawPath(link));

        // Transition exiting nodes to the parent's new position.
        link.exit().remove()
            .attr('d', link => drawPath(link));

        //triangle icons for expandable/collapsible objects
        const icon = this.iconGroup.selectAll<SVGPathElement, ExtendedTree>('path').data(openNodeData, d => d.id);

        const iconPathData = d => d.canOpen ? (d.collapsed ? (this.options.leftToRight ? ARROW_RIGHT : ARROW_LEFT) : 'M4 7L0 1h8z') : ''
        const iconEnter = icon.enter().append('path')
            // .attr('transform', d => `translate(${xAxis(d) - 4},${yAxis(d) - 3})`)
            // .attr('fill', d => (d as any).collapsed ? '#999' : null)
            .attr('d', iconPathData);

        // Transition links to their new position.
        icon.merge(iconEnter)
            .attr('transform', d => `translate(${xAxis(d) - 4},${yAxis(d) - 4})`)
            .attr('fill', d => (d as any).collapsed ? '#999' : null)
            .attr('cursor', () => !this.disabledClick ? 'pointer' : '')
            .attr('pointer-events', 'all')
            .on('click', (event, d) => this.onClick(d))
            .attr('d', iconPathData);

        // Transition exiting nodes to the parent's new position.
        icon.exit().remove();

        if (this.onRedraw) {
            this.onRedraw();
        }
    }

    private redrawNodes(node: d3.Selection<SVGGElement, ExtendedTree, SVGGElement, any>, yAxis: (d: any) => number, xAxis: (d: ExtendedTree) => number, x0Axis: (d: ExtendedTree) => number, S: number, x1Axis: (d: ExtendedTree) => number) {
        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('class', 'root-group')
            .attr('transform', (d: any) => `translate(0,${yAxis(d)})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0)
            .attr('cursor', (d => !this.disabledClick && d.canOpen ? 'pointer' : null))
            .attr('pointer-events', 'all')
            // .on('click', (event, d) => this.onClick(d))
            .on('click', (event, d) => this.onClickSelection(d))
            .attr('id', (d) => `node-${d.id}`)

        // .on('mouseover', function (d) {
        //     // when the bar is mouse-overed, we highlight the row.
        //     d3.select(this).classed('hover', true).style('opacity', 1);
        // })
        // .on('mouseout', function (d) {
        //     d3.select(this).classed('hover', false).style('opacity', 0.2)
        // });

        nodeEnter.append('circle')
            .attr('cx', d => xAxis(d))

        //rect for mouse motion indication
        nodeEnter.append('rect')
            .attr('dy', '0.32em')
            .attr('x', d => x0Axis(d) + 6 * S)
            .attr('y', '-0.62em')
            .attr('width', d => x1Axis(d) - x0Axis(d))
            .attr('height', this.nodeSize)
            .attr('fill', 'none')

        const nodeLabelText = nodeEnter
            .append('text')
            .attr('dy', '0.32em')
            .attr('x', d => xAxis(d) + 8 * S)
            .attr('text-anchor', this.options.leftToRight ? 'start' : 'end')
            .classed('label', true)


        nodeLabelText
            .append('tspan')
        // .classed('label', true)

        nodeLabelText.append('tspan')
            .classed('rename-left', true)

        nodeLabelText.append('tspan')
            .classed('rename-arrow', true)
            .text(' → ')

        nodeLabelText.append('tspan')
            .classed('rename-right', true)


        nodeEnter.append('title')
            .text(d => {
                const label = 'test' //getHardcodedLabel(d.data.label)
                const l = String(label)
                if (l.length > MAX_LABEL_SIZE) {
                    return d.ancestors().reverse().slice(1).map(d => 'test').join('\n') //getHardcodedLabel(d.data.label)
                }
                return ''
            });

        const acceptGroup = nodeEnter.append('g')
            .classed('button-group-accept', true)
            .classed('hidden', d => Boolean(!d.data.reviewStatus?.deleted && !d.data.reviewStatus?.added && !d.data.reviewStatus?.renamed) || (this.options.profile.p.readOnlySuggestion ?? false))
            .on('click', (a, b) => {
                a.preventDefault();
                a.stopPropagation();
                this.handleAccept(b);
            })
            // .attr('transform', () => `translate(${this.options.leftToRight ? -15 : 255},${this.nodeSize - 31})`); //315
            .attr('transform', () => `translate(${this.options.leftToRight ? 420 : -15},${this.nodeSize - 31})`);


        acceptGroup.append('rect')
            .classed('button-accept', true)
            .attr('width', this.nodeSize)
            .attr('height', this.nodeSize)
            .attr('y', '0.16em')

        // .attr('fill', 'white')
        // .attr('fill-opacity', '0.1');

        acceptGroup.append('path')
            .attr('transform', 'translate(2, 2)')
            // Original:
            // .attr('d', 'M16.59 7.58 10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z') //check original
            .attr('d', 'M 11.613 5.306 L 7 9.919 l -2.513 -2.506 L 3.5 8.4 l 3.5 3.5 l 5.6 -5.6 z M 8.4 1.4 C 4.536 1.4 1.4 4.536 1.4 8.4 s 3.136 7 7 7 s 7 -3.136 7 -7 S 12.264 1.4 8.4 1.4 z m 0 12.6 c -3.094 0 -5.6 -2.506 -5.6 -5.6 s 2.506 -5.6 5.6 -5.6 s 5.6 2.506 5.6 5.6 s -2.506 5.6 -5.6 5.6 z') //check dennis




            .classed('button-group-accept-path', true)

        const rejectGroup = nodeEnter.append('g')
            .classed('button-group-reject', true)
            .classed('hidden', d => Boolean(!d.data.reviewStatus?.deleted && !d.data.reviewStatus?.added && !d.data.reviewStatus?.renamed) || (this.options.profile.p.readOnlySuggestion ?? false))
            .on('click', (a, b) => {
                console.log('clicked on button group reject');
                a.preventDefault();
                a.stopPropagation();
                this.handleReject(b);

            })
            // .attr('transform', () => `translate(${this.options.leftToRight ? -30 : 230},${this.nodeSize - 31})`); // Adjust x-coordinate value //290
            .attr('transform', () => `translate(${this.options.leftToRight ? 400 : -15},${this.nodeSize - 31})`);

        rejectGroup.append('rect')
            .classed('button-reject', true)
            .attr('width', this.nodeSize)
            .attr('height', this.nodeSize)
            .attr('y', '0.16em')
        // .attr('fill', 'white')
        // .attr('fill-opacity', '0.1');

        rejectGroup.append('path')
            .attr('d', 'M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z')  //cross
            .attr('d', 'M 11.672 6.4 L 9.6 8.472 L 7.528 6.4 L 6.4 7.528 L 8.472 9.6 L 6.4 11.672 L 7.528 12.8 L 9.6 10.728 L 11.672 12.8 L 12.8 11.672 L 10.728 9.6 L 12.8 7.528 L 11.672 6.4 z M 9.6 1.6 C 5.176 1.6 1.6 5.176 1.6 9.6 s 3.576 8 8 8 s 8 -3.576 8 -8 S 14.024 1.6 9.6 1.6 z m 0 14.4 c -3.528 0 -6.4 -2.872 -6.4 -6.4 s 2.872 -6.4 6.4 -6.4 s 6.4 2.872 6.4 6.4 s -2.872 6.4 -6.4 6.4 z')  //cross
            .attr('d', 'M 10.213 5.6 L 8.4 7.413 L 6.587 5.6 L 5.6 6.587 L 7.413 8.4 L 5.6 10.213 L 6.587 11.2 L 8.4 9.387 L 10.213 11.2 L 11.2 10.213 L 9.387 8.4 L 11.2 6.587 L 10.213 5.6 z M 8.4 1.4 C 4.529 1.4 1.4 4.529 1.4 8.4 s 3.129 7 7 7 s 7 -3.129 7 -7 S 12.271 1.4 8.4 1.4 z m 0 12.6 c -3.087 0 -5.6 -2.513 -5.6 -5.6 s 2.513 -5.6 5.6 -5.6 s 5.6 2.513 5.6 5.6 s -2.513 5.6 -5.6 5.6 z')  //cross
            .attr('transform', 'translate(2, 2)')
            .classed('button-group-rejected-path', true)

        nodeEnter.append('text')
            .attr('dy', '0.32em')
            .text(d => {
                return ''
            })
            // .attr('transform', () => `translate(${this.options.leftToRight ? -30 : 170},${this.nodeSize - 20})`); // Adjust x-coordinate value //290
            .attr('transform', () => `translate(${this.options.leftToRight ? 460 : -30},${this.nodeSize - 20})`); // Adjust x-coordinate value //290


        return nodeEnter;
    }

    private updateColumns(nodeEnter) {
        const S = this.options.leftToRight ? 1 : -1;

        // Build the headers (once)
        this.headersContainer
            .selectAll<SVGTextElement, any>('text')
            .data(this.options.columns, d => d.columnTitle)
            .join(enter => enter
                .append('text')
                .attr('dy', '0.32em')
                .attr('y', 0.5 * this.nodeSize)  // Maybe this needs to change when using yAxis
                .attr('x', c => c.xOffset * S * 1.5)
                // .attr('x', (c, i) => c.xOffset * i * 0.2)
                .attr('text-anchor', this.options.leftToRight ? 'end' : 'start')
                // .attr('text-anchor', this.options.leftToRight ? 'start' : 'end')
                .attr('font-weight', 'bold')
                .text(c => c.columnTitle)
                .classed('column-header', true)
            )
        // .attr('transform', () => `translate(${this.options.leftToRight ? 460 : -30},${this.nodeSize - 20})`);


        // Build the values in the rows
        // Assert the node's values are never updated
        this.options.columns.forEach(({getValue, xOffset}) => {
            // Each node gets an extra text field
            nodeEnter.append('text')
                .classed('column-value', true)
                .attr('dy', '0.32em')
                .attr('x', xOffset * S * 1.5)
                .attr('text-anchor', this.options.leftToRight ? 'end' : 'start')
                .text(d => getValue(d, this as any))
        })
    }

    public getNodesOrdered() {
        const ordered: ExtendedTree[] = [];
        this.data.eachBefore(node => {
            if (!this.hiddenRoot || (node.depth > 0)) {
                ordered.push(node);
            }
        })
        // console.log(`getNodesOrdered() returns: ${ordered.length}/${this.data.descendants().length}`)
        return ordered;
    }

    public getNodes() {
        let nodes = this.data.descendants()
            .filter(d => !d.filtered)

        // Recalculate the correct y positioning
        // Note: Only used when yAxis is not used, but the nodes are distributed equally
        let i = 0;
        this.data.eachBefore(n => {
            if (n.filtered) {
                return;
            }
            n['index'] = i++;
        })

        if (this.hiddenRoot) {
            nodes = nodes.filter(d => d.depth > 0)
        }
        return nodes;
    }


    static processTreeData(data: SuggestionTreeStoreType<m_taxonomy.Data>) {
        let id = 0;

        //Convert SuggestionTreeStoreType to SuggestionTreeDataType
        const convertNode = (node: SuggestionTreeStoreType<m_taxonomy.Data>): SuggestionTreeDataType => {
            const _id = id++;
            let extendedNode: SuggestionTreeDataType

            // if it's root don't add reviewStatus
            if (_id === 1) {
                extendedNode = {
                    children: [],
                    values: node.values,
                    id: _id,
                    label: node.label,
                };
            } else {
                extendedNode = {
                    children: [],
                    values: node.values,
                    id: _id,
                    label: node.label,
                    reviewStatus: {
                        hasChangesInChildren: node.review?.stateData?.hasChangesInChildren,
                        added: node.review?.apiData.added || false,
                        deleted: node.review?.apiData.deleted || false,
                        renamed: node.review?.apiData.renamed || false,
                        accepted: node.review?.apiData.accepted || false,
                        rejected: node.review?.apiData.rejected || false,
                        oldLabel: node.review?.apiData.oldLabel || '',
                        newLabel: node.review?.apiData.newLabel || '',
                    }
                };
            }


            if (node.children && node.children.length > 0) {
                extendedNode.children = node.children.map(convertNode);
            }

            return extendedNode;
        }
        //call hierarchy on the data
        return hierarchy<SuggestionTreeDataType>(convertNode(data), d => d['children']);
    }

    private initNodes(data: Tree) {
        data.descendants().forEach((d: Tree, i) => {
            const ed = d as unknown as ExtendedTree;

            // Each node should have a unique ID
            if (ed.id === undefined) {
                ed.id = String(i);
            }

            ed.filtered = false;

            if (ed.children) {
                ed.collapsed = false;
                ed.canOpen = true;
                ed._children = ed.children;
            } else {
                ed.collapsed = false;
                ed.canOpen = false;
            }
        })

        data.eachBefore(n => {
            // TODO: Move this to the store
            if (!n.data.reviewStatus) {
                n.data.reviewStatus = {
                    deleted: false,
                    added: false,
                    renamed: false,
                    accepted: false,
                    rejected: false,
                    oldLabel: '',
                    newLabel: '',
                }
            }
            // TODO: Update this when the change was accepted/rejected
            if (n.data.reviewStatus) {
                n.data.reviewStatus.hasChangesInChildren = false;
                if (n.data.reviewStatus.renamed || n.data.reviewStatus.deleted || n.data.reviewStatus.added) {
                    n.parent?.ancestors().forEach(p => {
                        if (p.data.reviewStatus) {
                            p.data.reviewStatus.hasChangesInChildren = true;
                        }
                    })
                }
            }
        })


        return data as ExtendedTree
    }

    openOnlyNode(filter: (d: ExtendedTree) => boolean) {
        // First open all
        this.openAll();
        // Then collect all the relevant nodes
        const openNodes = new Set<number>();
        this.data.descendants().forEach(d => {
            if (filter(d)) {
                d.ancestors().forEach(p => openNodes.add(p.data.id))
            }
        })
        // and open the relevant nodes
        this.closeAll()
        this.openSelectionOfNodes(openNodes);
    }

    openToLevel(filterLevel: number) {
        const targetDepth = filterLevel + 1;
        this.data.descendants()
            .forEach((d: any) => {
                if (d.depth === targetDepth && d.collapsed === false) {
                    // Collapse this node
                    TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, true)
                } else if (d.depth < targetDepth && d.collapsed === true) {
                    // Open this node
                    TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, false)
                }
            })
    }

    private openSelectionOfNodes(nodeIds: Set<number>, node?: ExtendedTree) {
        if (!node) {
            node = this.data;
        }

        const mustBeOpen = nodeIds.has(node.data.id);
        if (mustBeOpen && node.collapsed) {
            // Open this node
            TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, false)
        } else if (!mustBeOpen && !node.collapsed) {
            // Collapse this node
            TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true)
        }

        if (node._children)
            node._children.forEach(c => this.openSelectionOfNodes(nodeIds, c));
    }

    public beforeEach(func: (node: ExtendedTree) => void, node?: ExtendedTree) {
        if (!node) {
            node = this.data;
        }
        func(node);
        if (node._children)
            node._children.forEach(c => this.beforeEach(func, c));
    }

    public static getLeafs<D>(node: ExtendedTree): ExtendedTree[] {
        const nodes = [];
        TaxonomySuggestionTreeBuilder.getLeafsImpl(node, nodes);
        return nodes;
    }

    private static getLeafsImpl<D>(node: ExtendedTree, nodes: ExtendedTree[]) {
        if (!node._children || node._children.length === 0) {
            nodes.push(node);
            return;
        }
        node._children.forEach(c => TaxonomySuggestionTreeBuilder.getLeafsImpl(c, nodes));
    }

    filterByLabel(label: string) {
        const openNodes = new Set<number>();
        this.openAll();

        this.data.descendants().forEach(d => {
            TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, false);
        })
        label = label.toLowerCase();
        const nodes = this.data.descendants()
        const total = nodes.length;
        nodes.forEach(d => {
            const dLabel = d.data.label?.toLowerCase() || '';
            if (dLabel.includes(label)) {
                // Open this node and all it's parents
                d.ancestors().forEach(p => openNodes.add(p.data.id));
            }
        })
        this.data.descendants().forEach(d => {
            const mustBeOpen = openNodes.has(d.data.id);
            if (mustBeOpen && d.collapsed) {
                // Open this node
                TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, false)
            } else if (!mustBeOpen && !d.collapsed) {
                // Collapse this node
                TaxonomySuggestionTreeBuilder.setNodeCollapsed(d, true)
            }
        })
    }

    static setNodeCollapsed(node, collapsed: boolean) {
        node.collapsed = collapsed;
        if (collapsed) {
            node.children = null;
        } else {
            node.children = node._children
        }
    }

    openAll(node?: ExtendedTree) {
        if (!node) {
            node = this.data;
        }
        TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, false);
        if (node._children)
            node._children.forEach(c => this.openAll(c));
    }

    private closeAll(node?: ExtendedTree) {
        if (!node) {
            node = this.data;
        }
        TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true);
        if (node._children)
            node._children.forEach(c => this.closeAll(c));
    }

    setYAxis(yAxis: ScaleBand<string>) {
        this.yAxis = yAxis;
    }


    registerOnRedraw(func: () => void) {
        this.onRedraw = func;
    }

    handleAccept(b: ExtendedTree) {
        if (!b.data.reviewStatus) return;

        //ACCEPT DELETE -> PROPAGATE TO CHILDREN
        if (b.data.reviewStatus.deleted) {
            b.descendants().forEach(function (d) {
                if (!d.data.reviewStatus) return;
                if (d.data.reviewStatus.deleted) {
                    d.data.reviewStatus.rejected = false;
                    d.data.reviewStatus.accepted = true;
                } else if (d.data.reviewStatus?.added) {
                    console.error('ERROR: there should not be a child ADDED with a DELETE parent')
                } else if (!d.data.reviewStatus?.added || !d.data.reviewStatus?.deleted) {
                    console.error('ERROR: if the parent is DELETED, all children should be DELETED')
                }
            });
        }

        //ACCEPT ADD -> PROPAGATE TO PARENT
        if (b.data.reviewStatus.added) {
            b.ancestors().forEach(function (d) {
                if (!d.data.reviewStatus) return;
                if (d.data.reviewStatus.added) {
                    d.data.reviewStatus.rejected = false;
                    d.data.reviewStatus.accepted = true;
                } else if (d.data.reviewStatus.deleted) {
                    d.data.reviewStatus.rejected = true;
                    d.data.reviewStatus.accepted = false;
                }
            });
        }

        //ACCEPT RENAME -> APPLY TO SINGLE NODE
        if (b.data.reviewStatus.renamed) {
            b.data.reviewStatus.rejected = false;
            b.data.reviewStatus.accepted = true;
        }

        if (this.options.onDataChanged) {
            console.log('COMPONENT: onDataChanged argument')
            console.log(TaxonomySuggestionTreeBuilder.exportTreeForPut(this.data))
            this.options.onDataChanged(TaxonomySuggestionTreeBuilder.exportTreeForPut(this.data))
        }

        this.redraw()
    }

    handleReject(b: ExtendedTree) {
        if (!b.data.reviewStatus) return;

        //REJECT ADD -> PROPAGATE TO CHILDREN
        if (b.data.reviewStatus.added) {
            b.descendants().forEach(function (d) {
                if (!d.data.reviewStatus) return;
                if (d.data.reviewStatus.added) {
                    d.data.reviewStatus.rejected = true;
                    d.data.reviewStatus.accepted = false;
                } else if (d.data.reviewStatus.deleted) {
                    d.data.reviewStatus.rejected = true;
                    d.data.reviewStatus.accepted = false;
                }
            });
        }

        //REJECT DELETE -> PROPAGATE TO PARENT
        if (b.data.reviewStatus.deleted) {
            b.ancestors().forEach(function (d) {
                if (!d.data.reviewStatus) return;
                if (d.data.reviewStatus.deleted) {
                    d.data.reviewStatus.rejected = true;
                    d.data.reviewStatus.accepted = false;
                }
            });
        }

        //REJECT RENAME -> APPLY TO SINGLE NODE
        if (b.data.reviewStatus.renamed) {
            b.data.reviewStatus.rejected = true;
            b.data.reviewStatus.accepted = false;
        }

        if (this.options.onDataChanged) {
            console.log('COMPONENT: onDataChanged argument')
            console.log(TaxonomySuggestionTreeBuilder.exportTreeForPut(this.data))
            this.options.onDataChanged(TaxonomySuggestionTreeBuilder.exportTreeForPut(this.data))
        }

        this.redraw()

    }
}

/**
 * Applies the filter, going down the tree
 */
//FIXME: Consider refactoring this function to also show the filtered node as collapsed instead of hiding them (Consider using TaxonomySuggestionTreeBuilder.setNodeCollapsed(node, true) or CloseAll(node))
function applyFilter(node: ExtendedTree, hideUncat: boolean, hideFilter: undefined | ((d: ExtendedTree) => boolean), depth = 0): boolean {
    let hideThis = false;
    let canOpen = false;
    if (hideUncat) {
        hideThis = hideThis || (node.data.label === UNCATEGORIZED_LABEL);
    }

    //Hide everything else
    if (hideFilter && !hideThis && depth > 0) {
        hideThis = hideThis || !hideFilter(node)
    }

    // Hide everything else
    node.filtered = false;
    if (node._children) {
        let hasUnhiddenChildren = false;
        node._children.forEach(c => {
            if (!applyFilter(c, hideUncat, hideFilter, depth + 1)) {
                hasUnhiddenChildren = true;
                hideThis = false;
            } else {
                if (hideThis) {
                    setHidden(node, true, depth + 1)
                    node.filtered = true;
                }
            }
        })
        if (hasUnhiddenChildren) {
            canOpen = true;
            node.filtered = false;
        }
    }

    node.canOpen = canOpen;
    return hideThis;
}

function setHidden(d: ExtendedTree, hidden: boolean, depth: number) {
    d.filtered = hidden;
    if (d.children) {
        d.children.forEach(c => setHidden(c, hidden, depth + 1));
    }
}
