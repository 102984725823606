import './NestleDashBoard.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {DashboardGridAlert} from "../DashboardGridAlert";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {DashboardRow} from "../../../components/dashboard/DashboardRow";
import {SupplierNormalizationV2Icon} from "../../../components/icons/SupplierNormalizationV2Icon";
import {SynergySuppliersIcon} from "../../../components/icons/SynergySuppliersIcon";
import {SynergyCategoriesIcon} from "../../../components/icons/SynergyCategoriesIcon";
import {ClassificationIcon} from "../../../components/icons/ClassificationIcon";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const Merge1Dashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        taxonomyManagerStore,
    } = useStores();
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;

    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        <DashboardGridAlert/>
        <Grid container
              className="dashboard nestle-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                values={[
                    {
                        title: 'Suppliers ' + p.p.masterCompanyName,
                        subtitle: masterCommonKpi
                            ? String(masterCommonKpi.n_suppliers)
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                    {
                        title: 'Suppliers ' + p.p.compareCompanyName,
                        subtitle: baseCommonKpi
                            ? String(baseCommonKpi.n_suppliers)
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                ]}
                className="suppliers-row">
                <DashBoardCard
                    className="two-line-title"
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title={`1a - Supplier Normalization${p.p.masterCompanyName ? ` (${p.p.masterCompanyName})` : ''}`}
                    value={drawOpportunityValue(pendingSupplierNormalizationMaster)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.merge_1_supplier_normalization_master)}
                    badge="Ai"
                />
                <DashBoardCard
                    className="two-line-title"
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title={`1b - Supplier Normalization${p.p.compareCompanyName ? ` (${p.p.compareCompanyName})` : ''}`}
                    value={drawOpportunityValue(pendingSupplierNormalizationBase)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.merge_1_supplier_normalization)}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                values={[
                    {
                        title: 'Spend ' + p.p.masterCompanyName,
                        subtitle: masterCommonKpi
                            ? <CurrencyComponent v={masterCommonKpi.total_spend} nDigits={3}/>
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                    {
                        title: 'Spend ' + p.p.compareCompanyName,
                        subtitle: baseCommonKpi
                            ? <CurrencyComponent v={baseCommonKpi.total_spend} nDigits={3}/>
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                ]}
                className="spend-row">
                <DashBoardCard
                    cardKey="synergy-suppliers"
                    avatar={<SynergySuppliersIcon/>}
                    title="2 - Common Suppliers"
                    link={generatePath(routes.merge_1_common_suppliers)}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="synergy-categories"
                    avatar={<SynergyCategoriesIcon/>}
                    title="3 - Common Categories" // Wrongly named, actually the "Taxonomy Mapper" page is used
                    link={generatePath(routes.merge_1_common_categories)}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                values={[
                    {
                        title: 'Categories ' + p.p.masterCompanyName,
                        subtitle: masterCommonKpi
                            ? String(masterCommonKpi.n_categories)
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                    {
                        title: 'Categories ' + p.p.compareCompanyName,
                        subtitle: baseCommonKpi
                            ? String(baseCommonKpi.n_categories)
                            : DASHBOARD_CONSTANTS.SUBTITLE_LOADING
                    },
                ]}
                className="categories-row">
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.merge_1_client_taxonomy_builder)}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="4 - Harmonize Categories"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_1_client_categorization_review)}
                    badge="Ai"
                />
            </DashboardRow>
        </Grid>
    </>
});
