import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {TableCell, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {PartReCategorizationComponent} from "../../../../components/categorization/PartReCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {SingleReviewButtons} from "../../../../components/approval-acc-rej/SingleReviewButtons";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {getSpecificCategorization} from "../../../../services/classes/MatReviewHelpers";
import {MatPartReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {CategorizationReviewStore} from "../../../../stores/CategorizationReviewStore";
import {environment} from "../../../../env";
import moment from "moment";


type Props = {
    part: MatPartReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};
export const CategorizationReviewSubRow: React.FC<Props> = observer(({part, controller}) => {
    const {p, authStore, bagStore, categorizationReviewStore} = useStores();

    const partState = CategorizationReviewStore.calcSingleState(part);
    partState.editAllowed = !authStore.viewOnly && partState.editAllowed;

    // /**
    //  * Hack for ATK
    //  * - The API requests the /with_context/ endpoints
    //  */
    // const atkPart = part as any as MatPartReviewRowWithContext;
    // const contextCats = getContextReviewCategory(atkPart, bagStore.taxonomy_size);
    // const leftCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.leftCat);
    
    const rightCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.rightCat);

    let categoryComponent;

    let onAccept: undefined | (() => void) = undefined;
    if (!partState.editAllowed) {
        categoryComponent = <PartCategorizationComponent cats={rightCats}/>
    } else {
        onAccept = () => categorizationReviewStore.clickAcceptReject(part, ReviewChoice.ACCEPT);
        if (categorizationReviewStore.categories.taxonomy_categories) {
            categoryComponent = <PartReCategorizationComponent
                cats={rightCats}
                handleOpenSearch={event => {
                    const anchor = event.currentTarget;
                    controller.setOnSelect((newCats) => {
                        categorizationReviewStore.clickRecatPart(part, newCats)
                    })
                    controller.open(anchor)
                }}
            />
        } else {
            categoryComponent = <PartCategorizationComponent cats={rightCats}/>
        }
    }

    return <TableRow>
        <TableCell className="col-p_name" component="th" scope="row">
            {environment.isTest && <>p_id={part.p_id}<br/></>}
            {part.p_name}
        </TableCell>
        <TableCell className="col-p_spend">
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </TableCell>
        {p.p.p_description_col_name !== false &&
            <TableCell className="col-p_description">
                {part.p_description}
            </TableCell>
        }
        {p.partContextColNames.map(({dataField, colType}) =>
            <TableCell key={dataField} className={"col-" + dataField}>
                {colType === 'date'
                    ? moment(part[dataField]).format('L')
                    : String(part[dataField])}
            </TableCell>
        )}
        {/*<TableCell className="col-p_context_l">*/}
        {/*    <PartCategorizationComponent cats={contextCats}/>*/}
        {/*</TableCell>*/}
        {/*<TableCell className="col-p_input_l">*/}
        {/*    <PartCategorizationComponent cats={leftCats}/>*/}
        {/*</TableCell>*/}
        <TableCell className="col-p_suggested_l">
            {categoryComponent}
        </TableCell>
        {/*<TableCell className="col-p_suggested_conf">*/}
        {/*    <AiScore score={part.p_suggestion_score}/>*/}
        {/*</TableCell>*/}
        <TableCell className="col-p_YN" align="right">
            {rightCats !== false && rightCats.every(cat => cat === '') ? null : (
                <SingleReviewButtons onAccept={onAccept} state={partState}/>
            )}
        </TableCell>
    </TableRow>
})
