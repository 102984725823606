import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import './SupplierNormalization.scss';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {Chip, Grid, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {SupplierNormalizationWelcomeStep} from "./pages/SupplierNormalizationWelcomeStep";
import {environment} from "../../env";
import {SupplierNormalizationApplyStep} from "./pages/SupplierNormalizationApplyStep";
import {SupplierNormalizationModelRunningStep} from "./pages/SupplierNormalizationModelRunningStep";
import {SupplierNormalizationApplyingResultsStep} from "./pages/SupplierNormalizationApplyingResultsStep";
import {ParentSuppliersStep} from "./pages/parent-suppliers/ParentSuppliersStep";
import {generateHomePath} from "../../routing/routing.utils";
import {JobRouteMatch} from '../../routing/routes';
import {useHistory} from "react-router";

// const RESULT_COLUMNS: DataColumn[] = [
//     {
//         dataIndex: 'supplier_name',
//         headerText: 'Supplier name',
//         ltr: true,
//     },
//     {
//         dataIndex: 'parent_supplier',
//         headerText: 'Identified parent supplier',
//         ltr: true,
//     },
// ];

// function pseudoHardcodeFraction(liveValue, referenceTotal, referenceTarget) {
//     // TODO: Remove hardcoded
//     const fraction = referenceTarget / referenceTotal;
//     const pseudo = liveValue * fraction;
//     return Math.round(pseudo);
// }

// const PROCESSING_DURATION_HACK_MINUTES = 7;

export const SupplierNormalizationPage: React.FC = observer(() => {
    const {p, rootAppStore, bagStore, supplierNormalizationStore, synergyStore} = useStores();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();


    useEffect(() => {
        // Check route
        if (supplierNormalizationStore.isMaster) {
            if (!p.p.hardcodeMasterBagId) throw new Error('Hardcode master bag id is not set (ERROR FROM PAGE!!)');
            supplierNormalizationStore.init(p.p.hardcodeMasterBagId, bag.taxonomy_size)
        } else {
            supplierNormalizationStore.init(bagId, bag.taxonomy_size)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    // useEffect(() => {
    //     // TODO: Replace stats and keyValues with bagStore.kpi.common
    //     // const sub = forkJoin([
    //     //     from(bagStore.getJobStats()),
    //     //     // from(bagStore.getJobKoiCumSuppliersL1Summary()),
    //     //     // from(bagStore.getJobKoiCumSuppliers(1)),
    //     // ])
    //     //     .pipe(map(([
    //     //                    stats,
    //     //                    // l1Resp,
    //     //                    // topResp
    //     //                ]) => ({
    //     //         // total_suppliers: totalSuppliers,
    //     //         total_suppliers: stats.data.n_suppliers,
    //     //         parent_suppliers: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 8),
    //     //         child_suppliers: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 26),
    //     //         stand_alone: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 101),
    //     //     })))
    //     //     .subscribe(keyValues => {
    //     //         setKeyValues(keyValues);
    //     //     });
    //     // return () => sub.unsubscribe();
    // }, [bagStore])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    // const step1 = <div className="page-item step-1">
    //     {/*/!* Header *!/*/}
    //     {/*<Grid container justifyContent="space-evenly">*/}
    //     {/*    <Grid item xs={5}>*/}
    //     {/*        <Typography variant="h6" align="center">Determining the parent and child suppliers ...</Typography>*/}
    //     {/*    </Grid>*/}
    //     {/*    <Grid item xs={4}>*/}
    //     {/*    </Grid>*/}
    //     {/*</Grid>*/}
    //
    //     <Grid container justifyContent="space-evenly">
    //         <Grid item xs={4}
    //               container
    //               justifyContent="space-around" style={{alignContent: 'space-around'}} alignItems="center">
    //             <Grid item
    //                   container direction="column"
    //                   justifyContent="space-around" style={{alignContent: 'space-around'}} alignItems="center"
    //                   className="text-center">
    //                 <Grid item className="font-bigger">
    //                     <Typography variant="caption">
    //                         Estimated completion time:
    //                         {/*<br/>suppliers*/}
    //                     </Typography>
    //                     <Typography variant="h5" className="font-weight-bold key-value">
    //                         {/*{showEstimation(PROCESSING_DURATION_HACK_MINUTES)}*/}
    //                         in a few seconds
    //                     </Typography>
    //                 </Grid>
    //                 <Grid item>
    //                     {/*Hidden button*/}
    //                     <div style={{width: '50px', height: '50px'}} onClick={() => setActiveStep(2)}/>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </Grid>
    // </div>;
    //
    // const step2 = <div className="page-item step-2">
    //     <div style={{minHeight: '11em'}}>
    //         <Grid container justifyContent="space-evenly">
    //             <Grid item xs={7}
    //                   container justifyContent="space-around" style={{alignContent: 'space-around'}}
    //                   alignItems="center">
    //                 <Grid className="key-value-container text-center font-bigger"
    //                       item xs={11}
    //                       container spacing={4}>
    //                     {/* Row 1 */}
    //                     <Grid item
    //                           container
    //                           style={{justifyContent: "space-between"}}>
    //                         <Grid item>
    //                             <Typography variant="caption">
    //                                 Total
    //                                 <br/>suppliers
    //                             </Typography>
    //                             <Typography variant="h5" className="font-weight-bold key-value">
    //                                 {keyValues
    //                                     ? `${keyValues.total_suppliers}`
    //                                     : <>&nbsp;</>}
    //                             </Typography>
    //                         </Grid>
    //                         <Grid item>
    //                             <Typography variant="caption">
    //                                 Parent
    //                                 <br/>suppliers
    //                             </Typography>
    //                             <Typography variant="h5" className="font-weight-bold key-value">
    //                                 {keyValues
    //                                     ? `${keyValues.parent_suppliers}`
    //                                     : <>&nbsp;</>}
    //                             </Typography>
    //                         </Grid>
    //                         <Grid item>
    //                             <Typography variant="caption">
    //                                 Child
    //                                 <br/>suppliers
    //                             </Typography>
    //                             <Typography variant="h5" className="font-weight-bold key-value">
    //                                 {keyValues
    //                                     ? `${keyValues.child_suppliers}`
    //                                     : <>&nbsp;</>}
    //                             </Typography>
    //                         </Grid>
    //                         <Grid item>
    //                             <Typography variant="caption">
    //                                 Standalone
    //                                 <br/>suppliers
    //                             </Typography>
    //                             <Typography variant="h5" className="font-weight-bold key-value">
    //                                 {keyValues
    //                                     ? `${keyValues.stand_alone}`
    //                                     : <>&nbsp;</>}
    //                             </Typography>
    //                         </Grid>
    //
    //                         <Grid item>
    //                             <a className="button" href={environment.demoFiles.supplierHierarchy.url}>
    //                                 <Button variant="contained" color="primary" style={{margin: '2em'}}>
    //                                     Download results
    //                                 </Button>
    //                             </a>
    //                         </Grid>
    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </div>
    //
    //     <Grid container justifyContent="space-evenly">
    //         {/*<Grid item xs={8}>*/}
    //         {/*    <Typography variant="h6">Result</Typography>*/}
    //         {/*</Grid>*/}
    //         <Grid item xs={7} style={{minHeight: '10em'}}>
    //             <DataTable
    //                 className="supplier-hierarchy-table alt-rows"
    //                 tableColumns={RESULT_COLUMNS}
    //                 data={RESULT_DATA.map(([supplier_name, parent_supplier]) =>
    //                     ({supplier_name, parent_supplier,})
    //                 )}
    //                 emptyCaption="Loading"
    //                 pageSize={12}
    //             />
    //         </Grid>
    //     </Grid>
    //
    //
    // </div>;

    const activeStep = supplierNormalizationStore.stepNumber;

    let goBack = () => history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app));
    let backLabel = 'Home';
    if (activeState === 'start' || activeState === 'running') {
        // Go Home
    } else if (activeStep === 1) {
        goBack = () => supplierNormalizationStore.goToState('start');
        backLabel = 'Back';
    } else if (activeState === 'apply') {
        goBack = () => supplierNormalizationStore.goToState('clean_parents');
        backLabel = 'Back';
    }

    return <KoiFacade
        title={(!p.p.masterCompanyName && !p.p.compareCompanyName) ? 'Supplier Normalization'
            : (supplierNormalizationStore.isMaster
                ? <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.masterCompanyName + ' '}
                    {p.p.showResultPills && <Chip label="SAP results" size="medium"/>}
                </Typography>
                : <Typography variant="h2" component="h1">
                    Supplier Normalization for {p.p.compareCompanyName + ' '}
                    {p.p.showResultPills && <Chip color="primary" label="Mithra results" size="medium"/>}
                </Typography>)}
        intro={<></>}
        onBack={goBack}
        backLabel={backLabel}
        className="sup-norm-page">

        <Grid container justifyContent="space-evenly" className="supplier-normalization-stepper">

            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={activeStep}>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('start')}>
                        <StepLabel>
                            {
                                activeState === 'running' ? 'Running ...' :
                                    p.p.skipAiSupplierNormalizationModel ? 'Start model' : 'Start model'
                            }
                        </StepLabel>
                    </Step>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('clean_parents')}>
                        <StepLabel>
                            Determine the parent suppliers
                        </StepLabel>
                    </Step>
                    <Step onClick={() => environment.isTest && supplierNormalizationStore.goToState('apply')}>
                        <StepLabel>
                            {
                                activeState === 'applying' ? 'Applying ...'
                                    : p.p.skipAiSupplierNormalizationModel ? 'Results' : 'Apply results'
                            }
                        </StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>

        {activeState === 'start' && <SupplierNormalizationWelcomeStep/>}
        {activeState === 'running' && <SupplierNormalizationModelRunningStep/>}
        {/*{activeStep === 2 && <SupplierCleaningStep/>}*/}
        {activeStep === 1 && <ParentSuppliersStep/>}
        {activeState === 'apply' && <SupplierNormalizationApplyStep/>}
        {(activeState === 'applying' || activeState === 'done') && <SupplierNormalizationApplyingResultsStep/>}

    </KoiFacade>
});
