import './TaxonomySuggestor.scss';
import React from "react";
import ProfileStore from "../../stores/ProfileStore";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Table, TableBody, TableCell, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {MatSupplierCategoryConcentrationStatistics} from "../../services/classes/MaterializedClasses";
import {MithraTablePaginationActions} from "../../components/table-pagination/MithraTablePaginationActions";


export function getDatasetTargetColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        // {cls: 'col-s_id', txt: p.partDescriptionColumnName},
        {cls: 'col-s_id', txt: 'ID'},
        {cls: 'col-s_name', txt: p.supplierDataName},
        {cls: 'col-s_total_spend', txt: 'Amount', width: 300},
    ]
    return arr.filter(c => c) as ColSpec[];
}

export const TaxonomySuggestionTable: React.FC = observer(() => {
    const {p, taxonomySuggestorStore} = useStores()

    const isLoading = taxonomySuggestorStore.isTableDataLoading;
    const tableClass = 'mithra-table x-design x-design-narrow' + (isLoading ? ' loading' : '');

    const COLUMNS = getDatasetTargetColumns(p)

    const ptd = taxonomySuggestorStore.paginatedTableData;
    return <div className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody>
                {ptd.data ? <>
                        {ptd.data.map((d, i) => <React.Fragment key={i}>
                            <TaxonomySuggestionRow data={d}/>
                        </React.Fragment>)}
                        {ptd.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length}
                        count={ptd.count}
                        rowsPerPage={ptd.pageSize}
                        page={ptd.page - 1}
                        onPageChange={(e, page) => ptd.changePage(page + 1)}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </div>
})

type Props = {
    data: MatSupplierCategoryConcentrationStatistics
};
export const TaxonomySuggestionRow: React.FC<Props> = observer(({data}) => {
    const {p} = useStores();
    const COLUMNS = getDatasetTargetColumns(p);

    let i = 0;
    return <TableRow>
        {/*{p.p.p_description_col_name !== false &&*/}
        {/*    <MithraTableCell c={COLUMNS[i++]}>*/}
        {/*        {data.p__description ? data.p__description : 'N/A'}*/}
        {/*    </MithraTableCell>*/}
        {/*}*/}
        <MithraTableCell c={COLUMNS[i++]}>
            {data.s_id}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {data.s_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={data.s_total_spend}></CurrencyComponent>
        </MithraTableCell>
    </TableRow>
})
