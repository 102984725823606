import './CategorizationPage.scss'
import React, {useState} from "react";
import {CategorizationStepper} from "./CategorizationStepper";
import {CategorizationWelcome} from "./welcome/CategorizationWelcome";
import {Alert, Button, CircularProgress, Dialog, Grid, LinearProgress} from "@mui/material";
import {ChevronLeft, ChevronRight, CloudDownload, Done} from "@mui/icons-material";
import {useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../routing/routes';
import {generateHomePath} from "../../routing/routing.utils";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CategorizationReview} from "./review/CategorizationReview";
import {ConfirmSendForApproval} from "./review/modals/ConfirmSendForApproval";
import {CAT_FILTER_MODAL_TITLE_ID, CategorizationFilterModal} from "./review/modals/CategorizationFilterModal";

const HARDCODED_JOB_ID = 4

type Props = {}
export const CategorizationPage: React.FC<Props> = observer(() => {
    const {
        rootAppStore,
        bagStore,
        categorizationStore,
        categorizationReviewStore,
        authStore,
        api,
        materializedApi,
        p
    } = useStores();
    const bagId = bagStore.bagId;
    const routeMatch = useRouteMatch<JobRouteMatch>();

    const history = useHistory();
    const back = generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)

    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false)
    const [hasDownloadedExcel, setHasDownloadedExcel] = useState(false)

    const [debugBusy, setDebugBusy] = useState(false)
    const [job3Status, setJob3Status] = useState('?')

    return <div>
        <Dialog
            open={categorizationReviewStore.isSendForApprovalDialog}
            onClose={() => categorizationReviewStore.setSendForApprovalDialog(false)}
            aria-labelledby={"review-modal-title"}
            maxWidth="lg"
        >
            {categorizationReviewStore.isSendForApprovalDialog && <ConfirmSendForApproval/>}
        </Dialog>
        <Dialog
            open={categorizationReviewStore.isAdvFilterDialogOpen}
            onClose={() => categorizationReviewStore.setAdvancedFilterDialogOpen(false)}
            aria-labelledby={CAT_FILTER_MODAL_TITLE_ID}
            maxWidth="lg"
        >
            {categorizationReviewStore.isAdvFilterDialogOpen && <CategorizationFilterModal/>}
        </Dialog>

        {p.p.testAiIntegration && <Button
            style={{margin: '2em'}}
            variant="contained"
            disabled={debugBusy}
            onClick={() => {
                setDebugBusy(true)
                materializedApi.resetDebugBag()
                    .finally(() => setDebugBusy(false))
            }}>
            Reset bag 1433
        </Button>}
        {p.p.testAiIntegration && <Button
            style={{margin: '2em'}}
            variant="contained"
            disabled={debugBusy}
            onClick={() => {
                setDebugBusy(true)
                materializedApi.restartAiJob(HARDCODED_JOB_ID)
                    .finally(() => setDebugBusy(false))
            }}>
            Restart Job {HARDCODED_JOB_ID}
        </Button>}
        {p.p.testAiIntegration && <Button
            style={{margin: '2em'}}
            variant="contained"
            disabled={debugBusy}
            onClick={() => {
                setDebugBusy(true)
                materializedApi.getAiJobStatus(HARDCODED_JOB_ID)
                    .then(r => setJob3Status(JSON.stringify(r.data)))
                    .finally(() => setDebugBusy(false))
            }}>
            Job {HARDCODED_JOB_ID} status: {job3Status}
        </Button>}
        {p.p.testAiIntegration && <Button
            style={{margin: '2em'}}
            variant="contained"
            disabled={debugBusy}
            onClick={() => {
                setDebugBusy(true)
                materializedApi.completeAiJob(HARDCODED_JOB_ID)
                    .finally(() => setDebugBusy(false))
            }}>
            Complete job {HARDCODED_JOB_ID}
        </Button>}

        {/*A header with back button*/}
        <div className="custom-page">

            {categorizationStore.page === 'review' && categorizationStore.data._reviewLevelStatistics.error &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <Alert severity="warning">
                            The categorization review is not available
                            ({categorizationStore.data._reviewLevelStatistics.error})
                        </Alert>
                    </Grid>
                </Grid>
            }

            <Grid container alignItems="center" className="header">
                <Grid item xs={2} className="text-center align-with-stepper">
                    {categorizationStore.page === 'model_selection' &&
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => history.push('' + back)}>
                            Back
                        </Button>
                    }
                    {categorizationStore.page === 'review' &&
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => {
                                if (categorizationStore.data.filterManager.selectedLevel === 0) {
                                    categorizationStore.backToStart()
                                } else {
                                    categorizationStore.data.filterManager.selectOneCategoryUp();
                                }
                            }}>
                            Back
                        </Button>
                    }
                    {categorizationStore.page === 'result' &&
                        <Button
                            color="primary"
                            variant="outlined"
                            startIcon={<ChevronLeft/>}
                            onClick={() => categorizationStore.showReview()}>
                            Back
                        </Button>
                    }
                </Grid>

                <Grid item xs={8}>
                    <CategorizationStepper activeStep={categorizationStore.step}/>
                </Grid>

                <Grid item xs={2} className="align-with-stepper">
                    {categorizationStore.page === 'review' && <>
                        <>
                            <Button
                                id="save-categorization-btn"
                                color="primary"
                                variant="outlined"
                                disabled={categorizationReviewStore.anyStoreRequestBusy}
                                endIcon={<ChevronRight/>}
                                onClick={() => {
                                    categorizationStore.data.filterManager.unsetSelectedCat();
                                    categorizationStore.showResult();
                                }}>
                                {authStore.viewOnly ? 'Next' : 'Save'}
                            </Button>
                            <div className="loading-element">
                                {!authStore.viewOnly && <>
                                    <LinearProgress
                                        variant="determinate"
                                        value={1 / (categorizationReviewStore.numberOfBusyRequests + 1) * 100}
                                    />
                                </>}
                            </div>
                        </>
                    </>}

                    {(categorizationStore.page === 'review' || categorizationStore.page === 'result') && <>
                        {!p.p.hideApprovalButtons && <Button
                            color="primary"
                            variant="outlined"
                            disabled={categorizationReviewStore.anyStoreRequestBusy}
                            style={{marginTop: '2em'}}
                            // endIcon={<ChevronRight/>}
                            onClick={() => categorizationReviewStore.setSendForApprovalDialog(true)}>
                            Send for approval
                        </Button>}
                        {p.p.allowReviewDownloadExcel &&
                            <Button
                                color="primary"
                                variant="outlined"
                                className="btn-with-circular-progress"
                                startIcon={isDownloadingExcel ? <CircularProgress size={14}/> : (hasDownloadedExcel ?
                                    <Done/> :
                                    <CloudDownload/>)}
                                endIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? undefined : undefined)}
                                disabled={isDownloadingExcel || true}
                                style={{marginTop: '2em', minWidth: '16em'}}
                                onClick={() => {
                                    setIsDownloadingExcel(true)
                                    api.downloadCategorizationResultExcel(bagId)
                                        .then(resp => {
                                            let filename = 'Result.xlsx';
                                            const contentHeader: string = resp.headers['content-disposition'] || '';
                                            if (contentHeader) {
                                                let split = contentHeader.split('filename=');
                                                if (split.length > 1) {
                                                    filename = split[1];
                                                }
                                            }
                                            const url = URL.createObjectURL(new Blob([resp.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', filename);
                                            document.body.appendChild(link);
                                            link.click();
                                            link.parentNode?.removeChild(link);
                                            setHasDownloadedExcel(true)
                                        })
                                        .finally(() => {
                                            setIsDownloadingExcel(false)
                                        })
                                }}>
                                {isDownloadingExcel ? 'Preparing data' : 'Download Excel file (BROKEN)'}
                            </Button>
                        }
                    </>}
                    {categorizationStore.page === 'review' && <>
                        {categorizationReviewStore.categories.taxonomy_categories === null &&
                            <Grid container style={{marginTop: '2em'}}>
                                <Grid item>
                                    <Alert severity="warning">Missing taxonomy</Alert>
                                </Grid>
                            </Grid>
                        }
                        {categorizationReviewStore.categories.taxonomy_categories === undefined && !categorizationReviewStore.categories.error &&
                            <Grid container style={{marginTop: '2em'}}>
                                <Grid item>
                                    <Alert severity="info">
                                        Loading review, please wait
                                        <span className="ml-2 mr-1">
                                            <CircularProgress size={12}/>
                                        </span>
                                    </Alert>
                                </Grid>
                            </Grid>
                        }
                        {categorizationReviewStore.categories.error &&
                            <Grid container style={{marginTop: '2em'}}>
                                <Grid item>
                                    <Alert severity="error">{categorizationReviewStore.categories.error}</Alert>
                                </Grid>
                            </Grid>}
                    </>}

                </Grid>
            </Grid>
            <div className="categorization-page-content">
                {/*<p>*/}
                {/*    step={categorizationStore.step}<br/>*/}
                {/*    page={categorizationStore.page}<br/>*/}
                {/*</p>*/}
                {categorizationStore.page === 'model_selection' && <CategorizationWelcome/>}
                {(categorizationStore.page === 'review' || categorizationStore.page === 'result') &&
                    <CategorizationReview/>
                }
            </div>
        </div>

    </div>
});
