import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {generatePath} from "react-router";
import {useStores} from "../../../stores";
import {DASHBOARD_CONSTANTS} from "../../../components/dashboard/Dashboard.utils";
import {TaxonomyMapperStore} from "../../taxonomy-mapper/store/TaxonomyMapperStore";
import {environment} from "../../../env";
import {DashboardRow} from "../../../components/dashboard/DashboardRow";
import {DashBoardCard} from "../../dashboard/DashBoardCard";
import {QueryStats} from "@mui/icons-material";
import {routes} from "../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../components/icons/ClassificationHierarchyIcon";
import {C} from "../../../configurations/active-configuration";
import {TaxonomyMapperIcon} from "../../../components/icons/TaxonomyMapperIcon";

export const Merge2MasterDashboard: React.FC = observer(() => {
    const stores = useStores();
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
    } = stores;
    const bagId = bagStore.bagId;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const unmatchOld = ((stores as any).old_new_Store as TaxonomyMapperStore).mapper.unMatchedCategories?.length;

    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>Merge2MasterDashboard</h1>}
        <Grid container
              className="dashboard merge-2-dashboard master-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                className="bare-row">

                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Opportunity Dashboard (Old)"
                    // value={drawOpportunityValue(ppvTotal)}
                    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="Preview"
                    link={generatePath(routes.merge_2_master_looker_opp_old, {id: bagId})}
                    // badge="preview"
                />
                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Opportunity Dashboard (Mithra)"
                    // value={drawOpportunityValue(ppvTotal)}
                    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="Preview"
                    link={generatePath(routes.merge_2_master_looker_opp_new, {id: bagId})}
                    // badge="preview"
                />
                <DashBoardCard
                    cardKey="adv-looker-dashboard"
                    avatar={<QueryStats fontSize="large"/>}
                    title="Advanced Dashboard (Combined)"
                    // value={drawOpportunityValue(ppvTotal)}
                    // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="Preview"
                    link={generatePath(routes.merge_2_master_looker_adv_all, {id: bagId})}
                    // badge="preview"
                />

            </DashboardRow>
            <DashboardRow
                className="bare-row">

                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    // title="Taxonomy Mapper (old holdings)"
                    title="Taxonomy Viewer"
                    subtitle="Old Taxonomy"
                    link={generatePath(routes.merge_2_master_taxonomy_viewer, {
                        id: bagId,
                        taxonomyId: C.profile.hardcodedBaseTaxonomyId
                    })}
                />
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    // title="Taxonomy Mapper (new proposal)"
                    title="Taxonomy Builder"
                    subtitle="New Taxonomy"
                    link={generatePath(routes.merge_2_master_taxonomy_builder, {
                        id: bagId,
                        taxonomyId: C.profile.masterTaxonomyId
                    })}
                />
            </DashboardRow>
            <DashboardRow
                className="bare-row">

                {/*<DashBoardCard*/}
                {/*    cardKey="taxonomy-mapper"*/}
                {/*    avatar={<TaxonomyMapperIcon/>}*/}
                {/*    title="Taxonomy Compare (old holdings)"*/}
                {/*    value={unmatchedCategories === undefined*/}
                {/*        ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}*/}
                {/*    subtitle={taxonomyManagerStore.taxonomy?.name}*/}
                {/*    // link={generatePath(routes.merge_x_master_taxonomy_compare_un, {id: bagId})}*/}
                {/*    link={""}*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Compare (new proposal)"
                    value={unmatchOld === undefined ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchOld}
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.merge_2_master_taxonomy_compare_baseline, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
