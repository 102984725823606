import {observer} from "mobx-react-lite";
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@mui/material";
import React from "react";
import {useStores} from "../../../../stores";
import {ParentSupplierMatch} from "../SupplierNormalization.classes";

let _id = 0;

type Option = ParentSupplierMatch | { type: 'new_option', inputValue: string, label: string };

const filter = createFilterOptions<Option>({
    limit: 20,
});

/**
 * TODO: CAT-712 Hack for demo
 * As the data is nor parsed correctly
 * @param label
 */
function showLabel(label: string) {
    return label.toLowerCase();
}

type Props = {
    id?: string,
    width?: number,
    value: ParentSupplierMatch | null,
    setValue: (value: ParentSupplierMatch | null) => void,
    label: string
}
export const ParentSupplierChooser: React.FC<Props> = observer(({id, width, value, setValue, label}) => {
    if (!id) id = `supplier-parent-chooser-${_id++}`;
    const {supplierNormalizationStore} = useStores();
    const ps = supplierNormalizationStore.ps;

    const [open, setOpen] = React.useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newMatch = ps.createNewParentSupplier();
        setValue(newMatch);
        handleClose();
    };
    const handleClose = () => {
        setOpen(false);
    };
    return <>
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    // timeout to avoid instant validation of the dialog's form.
                    setTimeout(() => {
                        setOpen(true);
                        ps.initNewSupplier(showLabel(newValue));
                    });
                } else if (newValue && newValue.type === 'new_option') {
                    setOpen(true);
                    ps.initNewSupplier(showLabel(newValue.inputValue));
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                if (!options) return [];
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                    filtered.push({
                        type: 'new_option',
                        inputValue: showLabel(params.inputValue),
                        label: `Add "${showLabel(params.inputValue)}"`,
                    });
                }

                return filtered;
            }}
            id={id}
            options={supplierNormalizationStore.ps.allSuggestedParentsSupplier as Option[]}
            getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                    return showLabel(option);
                }
                if (option.type === 'new_option') {
                    return showLabel(option.inputValue);
                }
                return showLabel(option.data.sp_name);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // renderOption={(props, option) => <li {...props}>{option.sp_name}</li>}
            renderOption={(props, option) =>
                <Box {...props} component="li">
                    {option.type === 'new_option'
                        ? showLabel(option.label)
                        : showLabel(option.data.sp_name)}
                </Box>
            }
            size="small"
            // sx={{width: 300}}
            freeSolo
            renderInput={(params) =>
                <TextField {...params} label={label}/>
            }
        />
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Add a new</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    {/*    Did you miss any film in our list? Please, add it!*/}
                    {/*</DialogContentText>*/}
                    <TextField
                        autoFocus
                        margin="dense"
                        id={id + '-name'}
                        value={showLabel(ps.newParentSupplier.sp_name)}
                        onChange={(event) => ps.setNewParentSupplierName(showLabel(event.target.value))}
                        label="name"
                        type="text"
                        variant="standard"
                    />
                    {/*<TextField*/}
                    {/*  margin="dense"*/}
                    {/*  id="year"*/}
                    {/*  value={dialogValue.year}*/}
                    {/*  onChange={(event) =>*/}
                    {/*    setDialogValue({*/}
                    {/*      ...dialogValue,*/}
                    {/*      year: event.target.value,*/}
                    {/*    })*/}
                    {/*  }*/}
                    {/*  label="year"*/}
                    {/*  type="number"*/}
                    {/*  variant="standard"*/}
                    {/*/>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </form>
        </Dialog>
    </>;

    // const parentSupplierLabel = "Parent supplier";
    // const parentSupplierLabelId = `parent-supplier-${id}-label`;
    // const parentSupplierFieldId = `parent-supplier-${id}`;
    // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    // const open = Boolean(anchorEl);
    // return <FormControl
    //     // variant="outlined"
    //     fullWidth
    //     // error={Boolean(error)}
    // >
    //     <InputLabel id={parentSupplierLabelId}>
    //         {parentSupplierLabel}
    //     </InputLabel>
    //     <Select
    //         readOnly
    //         onClick={event => {
    //             if(event.currentTarget)
    //                 setAnchorEl(event.currentTarget)
    //         }}
    //         // variant="outlined"
    //         // className={classes.selectEmpty + ' select-bag-field'}
    //         labelId={parentSupplierLabelId}
    //         id={parentSupplierFieldId}
    //         label={parentSupplierLabel}
    //         fullWidth
    //         // error={Boolean(error)}
    //         // value="loading"
    //         value={supplierNormalizationStore.ps.selectedParentSupplier?.id}
    //     >
    //         {supplierNormalizationStore.ps.parentSuppliers.map(ps =>
    //             <MenuItem key={ps.id} value={ps.id}>{ps.sp_name}</MenuItem>
    //         )}
    //     </Select>
    //     {/*{error && <FormHelperText>{error}</FormHelperText>}*/}
    // </FormControl>

    // return <>
    //     <StyledPopover
    //         id={parentSupplierAutocompletePopoverId}
    //         open={false}
    //         // anchorEl={anchorEl}
    //         // onClose={handleClose}
    //         anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //         }}
    //         transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //         }}
    //     >
    //         <Autocomplete
    //             id={parentSupplierAutocompleteId}
    //             style={{width: 800}}
    //             options={supplierNormalizationStore.ps.parentSuppliers}
    //             className={classes.popoverContent}
    //             classes={{
    //                 option: classes.option,
    //             }}
    //             // filterOptions={filterOptions}
    //             autoHighlight
    //             getOptionLabel={(option) => option.sp_name}
    //             renderOption={(props, option, {selected}) =>
    //                 <Box component="li">
    //                     {option.sp_name}
    //                 </Box>
    //             }
    //             onChange={(event: any, newValue: any | null) => {
    //                 console.log('newValue', toJS(newValue.values))
    //                 // handleClose()
    //                 // const newCats = toJS(newValue.values) as string[];
    //                 // onChange(newCats)
    //             }}
    //             renderInput={(params) =>
    //                 <TextField
    //                     {...params}
    //                     label="Search for a category"
    //                     variant="outlined"
    //                     inputProps={{
    //                         ...params.inputProps,
    //                         // autoComplete: 'new-password', // disable autocomplete and autofill
    //                     }}
    //                 />
    //             }
    //         />
    //     </StyledPopover>
    // </>
})

// import {styled} from "@mui/material/styles";
// const PREFIX = 'ParentSupplierChooser';
// const classes = {
//     typography: `${PREFIX}-typography`,
//     popoverContent: `${PREFIX}-popoverContent`,
//     option: `${PREFIX}-option`
// };
// const StyledPopover = styled(Popover)(({theme}) => ({
//     [`& .${classes.typography}`]: {
//         padding: theme.spacing(2),
//     },
//
//     [`& .${classes.popoverContent}`]: {
//         margin: 10,
//         background: 'transparent',
//     },
//
//     [`& .${classes.option}`]: {
//         fontSize: 15,
//         '& > span': {
//             marginRight: 10,
//             fontSize: 18,
//         },
//     }
// }));
