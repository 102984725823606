import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {AiOption} from "../../../stores/ai/ApplyTaxonomyMappingAiDelegate";
import {environment} from "../../../env";

export const AiModelSelection: React.FC = observer(() => {
    const {bagStore, categorizationStore, aiManagerStore} = useStores();
    const bagId = bagStore.bagId;

    return <div className="ai-model-selection">
        <Grid container justifyContent="center">
            <Grid item>
                <Typography variant="h4" className="header">
                    Select the Ai model you want to use
                </Typography>

                <FormControl>
                    <RadioGroup
                        name="ai-selection-radio-buttons-group"
                        value={aiManagerStore.aiConfig}
                        onChange={e => aiManagerStore.setAiConfig(e.target.value as AiOption)}
                    >
                        <FormControlLabel
                            control={<Radio/>}
                            value={'base' as AiOption}
                            label="Base model with Taxonomy Mapper overwrites"
                        />
                        <FormControlLabel
                            control={<Radio/>} disabled={true}
                            value={'trained_single' as AiOption}
                            label="Retrain model based on current review"
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>

        <Grid container justifyContent="center" className="action-btn-container">
            <Grid item>
                <div className="button-wrapper">
                    <Button
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={!aiManagerStore.applyTaxonomyMapping.canStartAiJob()}
                        onClick={() => {
                            categorizationStore.navigateToPage('model_busy');
                            aiManagerStore.applyTaxonomyMapping.startAiJob();
                        }}>
                        Start<br/>
                        Categorization
                    </Button>
                </div>
                <div className="button-wrapper">
                    <Button
                        id="continue-categorization-btn"
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={categorizationStore.data.hasNoCategorizationResult || categorizationStore.data.isLoading}
                        onClick={() => categorizationStore.navigateToPage('review')}
                    >
                        Continue<br/>
                        Categorization
                    </Button>
                </div>
                <div className="button-wrapper">
                    {environment.isTest && <Button
                        id="continue-categorization-btn"
                        color="secondary"
                        variant="outlined"
                        disabled={aiManagerStore.applyTaxonomyMapping.debugDeleteResults.busy}
                        onClick={() => aiManagerStore.applyTaxonomyMapping.debugDeleteResults.request(bagId)}
                    >
                        Delete Results (DEBUG)
                    </Button>}
                </div>
            </Grid>
        </Grid>
    </div>
});
