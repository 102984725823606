import './CategorizationWelcome.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import Typography from "@mui/material/Typography";
import {Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {Bag} from "../../../services/classes/Bag";
import {ChevronRight} from "@mui/icons-material";

type KeyValues = {
    before: {
        unclassified_spend: number,
    },
}

export const CategorizationWelcome: React.FC = observer(() => {
    const {bagStore, categorizationStore, p} = useStores();
    const bagId = bagStore.bagId;

    const keyValues: KeyValues | undefined = !bagStore.kpi.common
        ? undefined
        : {
            before: {unclassified_spend: bagStore.kpi.common.unclassified_spend}
        }

    return <div className="categorization-welcome">
        <Grid className="key-value-container wide-margin text-center font-bigger" container justifyContent="center">
            <Grid item>
                <Typography variant="caption">
                    Previously uncategorized
                </Typography>
                <Typography variant="h5" className="font-weight-bold key-value value-warning">
                    {keyValues
                        ? <CurrencyComponent v={keyValues.before.unclassified_spend} nDigits={3}/>
                        : <>&nbsp;</>}
                    {/*{categorizationStore.data.unclassifiedStats*/}
                    {/*    ? <CurrencyComponent v={categorizationStore.data.unclassifiedStats.pre_spend} nDigits={3}/>*/}
                    {/*    : <>&nbsp;</>}*/}
                </Typography>
            </Grid>
        </Grid>

        <Grid container justifyContent="center" className="one-line">
            <Grid item>
                {categorizationStore.data.hasNoCategorizationResult &&
                    <Typography variant="caption">
                        No result exists...
                    </Typography>
                }
            </Grid>
        </Grid>

        {!p.p.disableBuFilter && bagStore.bu.businessUnitOptions &&
            <Grid container justifyContent="center" className="mb-3 mt-3">
                <Grid item>
                    <BusinessUnitSelector/>
                </Grid>
            </Grid>
        }

        <Grid container justifyContent="center" className="mb-3">
            <Grid item>
                <Button
                    id="continue-categorization-btn"
                    color="primary"
                    variant="outlined"
                    endIcon={<ChevronRight/>}
                    disabled={categorizationStore.data.hasNoCategorizationResult || categorizationStore.data.isLoading}
                    onClick={() => categorizationStore.showReview()}
                >
                    Continue<br/>
                    categorization
                </Button>
            </Grid>
        </Grid>
    </div>
});


export const BusinessUnitSelector: React.FC = observer(() => {
    const {p, bagStore} = useStores();
    return <FormControl component="fieldset">
        <FormLabel component="legend">{p.p.bu_col_name} filter</FormLabel>
        <RadioGroup aria-label="business unit" name="business_unit"
                    value={bagStore.bu.selectedBusinessUnitOption.id || -1}
                    onChange={(e) => bagStore.bu.setSelectedBusinessUnitId(Number(e.target.value))}>
            {bagStore.bu.businessUnitOptions?.map(b =>
                <FormControlLabel key={b.id} value={b.id} control={<Radio/>} label={b.bu_name}/>
            )}
            {/*<FormControlLabel value="female" control={<Radio/>} label="Female"/>*/}
            {/*<FormControlLabel value="male" control={<Radio/>} label="Male"/>*/}
            {/*<FormControlLabel value="other" control={<Radio/>} label="Other"/>*/}
            {/*<FormControlLabel value="disabled" disabled control={<Radio/>} label="(Disabled option)"/>*/}
        </RadioGroup>
    </FormControl>
})
