import {TableCellProps} from "@mui/material/TableCell/TableCell";
import React from "react";
import {TableCell} from "@mui/material";
import {ColSpec} from "./MithraTableHeadColumns";


type Props = TableCellProps & {
    c: ColSpec
};
export const MithraTableCell: React.FC<Props> = ({c, children, ...tableCellProps}) => {
    if(!c) {
        console.error('MithraTableCell: ColSpec not found')
        return <div>ColSpec Not found</div>
    }
    return <TableCell className={c.cls} align={c.align}
                      style={{width: c.width, minWidth: c.minWidth}} {...tableCellProps}>
        {children}
    </TableCell>;
}
