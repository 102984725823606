import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable, reaction} from "mobx";
import {PageResponseManager} from "../managers/PageResponseManager";
import {environment} from "../../env";
import {
    AnyMatPartReviewRowState,
    matGetFilterType,
    MatPartFilter,
    MatPartReviewRowState,
    MatSupplierFilter,
    MatSupplierFilterType,
    SingularMatPartReviewRowState,
    SomeMatSupplierReviewRow,
    SomeMatSupplierReviewRowState
} from "../../services/classes/MatReviewClasses";
import {EMPTY, from, Subscription} from "rxjs";
import {
    MatPartReviewRow,
    MatReviewLevelStatisticsTreeSerializer,
    MatReviewStatisticsSerializer
} from "../../services/classes/MaterializedClasses";
import {AxoisRequestManager} from "../managers/RequestManager";
import {calcCombinedState, selectMatReviewLevelStatistics} from "../../services/classes/MatReviewHelpers";
import {single_bar_chart} from "../../components/visualization/single-barchart/SingleBarChartBuilder";
import {
    AiChangeChart,
    convertSingleToChart,
    convertToChart,
    convertToChartOnlyNew,
    findUnclassified
} from "../../services/classes/CategorizationHelpers";
import {CurrencyAbbreviation, findAbbreviationOfGroup} from "../../components/currency-component/CurrencyComponent";
import AuthStore from "../AuthStore";
import ProfileStore from "../ProfileStore";
import {SummaryKeyValues} from "../CategorizationStore";
import {CategorizationReviewFilterManagerDelegate} from "./CategorizationReviewFilterManagerDelegate";
import {UNCATEGORIZED_VALUE} from "../../constants";

type MinimalSupplierReviewRowState = Omit<SomeMatSupplierReviewRowState, 'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6' | 'l7' | 'l8'>;
export type CategorizationSupplierPageManager = PageResponseManager<MatSupplierFilter,
    SomeMatSupplierReviewRowState,
    SomeMatSupplierReviewRow>;
export type CategorizationPartPageManager = PageResponseManager<MatPartFilter,
    SingularMatPartReviewRowState,
    MatPartReviewRow>;

/**
 * Class to manage the data of the categorization review
 */
export class CategorizationReviewDataDelegate {
    readonly filterManager = new CategorizationReviewFilterManagerDelegate(this, this.profile)
    requestedBagId: undefined | number;

    // TODO[integration]: Move max level to profile store or get it from the bag
    maxTaxonomySize = 3; // Initially it must be set, but later this must be updated when the bag is known

    shouldHaveReviewLevelStatistics = false;

    allSelected = false;
    anySelected = false;

    /**
     * Keep track of the suppliers table by a single request group
     */
    readonly supplierPages: CategorizationSupplierPageManager = new PageResponseManager(
        environment.categorizationReviewSupplierPageSize,
        (page, f) =>
            this.matApi.listSupplierReview(f, page, environment.categorizationReviewSupplierPageSize),
        // Set part related attributes when they are done downloading
        (data: SomeMatSupplierReviewRow, request) => {
            const filter: SomeMatSupplierReviewRowState['filter'] = matGetFilterType(request);
            const rowState: MinimalSupplierReviewRowState = {
                filter,
                selected: false,
                ...data,
            };
            return rowState as SomeMatSupplierReviewRowState;
        }
    )

    readonly partPages: CategorizationPartPageManager = new PageResponseManager(
        environment.categorizationReviewPageSize,
        (page, f) => this.matApi.listPartReviewPageWithSupplierData(f, page, environment.categorizationReviewPageSize),
        (data: MatPartReviewRow) => {
            // interpretPartData
            const state: SingularMatPartReviewRowState = {
                ...data,
                review_mine: data.review_user_id === this.auth.userId,
                feedback_mine: data.feedback_user_id === this.auth.userId,
                selected: false,
                isLastSelected: false,
            }
            return state;
        }
    )

    public _isLoadingParts = false
    private partSub?: Subscription

    readonly _reviewStatistics = new AxoisRequestManager<{ bagId: number }, MatReviewStatisticsSerializer[]>(
        ({bagId}) => from(this.matApi.listReviewStatistics(bagId)),
    );
    readonly _reviewLevelStatistics = new AxoisRequestManager<{ bagId: number, businessUnitId: undefined | null | number, taxonomySize: number }, [MatReviewLevelStatisticsTreeSerializer]>(
        ({
             bagId,
             businessUnitId,
             taxonomySize
         }) => from(this.matApi.listReviewLevelStatistics(bagId, businessUnitId, taxonomySize))
    );
    lastSelected: AnyMatPartReviewRowState | undefined = undefined;

    constructor(
        private matApi: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        // TODO[convoluted-review-interface]: bad practice to put these reactions here!
        reaction(() => [this.supplierPages.data] as const, ([suppliers]) => {
            // console.log('Supplier page data changed')
            // Every time the supplier page is updated, the parts should be fetched again
            this._isLoadingParts = true;
            // TODO: Add loading waiter
            // console.log('Triggering this.supplierPages.data', suppliers?.map(s => s.id))
            if (suppliers) {
                if (this.partSub) this.partSub.unsubscribe();
                const filterType = this.filterManager.selectedFilterType;
                if (filterType === null) return;
                const supplierIds = suppliers.map(s => s.id)
                console.time('listPartsInReview')
                console.log('listPartsInReview', {supplierIds: supplierIds.length})
                if (supplierIds.length === 0) {
                    this.partSub = undefined;
                    console.timeEnd('listPartsInReview')
                } else {
                    let filteredApproval = this.filterManager.filteredApproval;
                    this.partSub = from(this.matApi.listPartsInReview(
                        filterType,
                        filteredApproval,
                        supplierIds,
                        profile.p.categorizationReviewSubRowRelationData
                    )).subscribe({
                        next: resp => this.setPartData(resp.data, filterType),
                        complete: () => console.timeEnd('listPartsInReview')
                    })
                }
            }
        })
    }


    reInitialize(bagId: number, taxonomySize: number) {
        console.log('reInitialize', {bagId, taxonomySize});
        console.log('CategorizationStore.CategorizationReviewDataDelegate.reInitialize', {bagId, taxonomySize});
        // TODO[convoluted-review-interface]: This needs a more stable interface
        this.filterManager.resetFilter(undefined);
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup();
        this._reviewLevelStatistics.cleanup();
        this.supplierPages.reset()
        this.shouldHaveReviewLevelStatistics = true;
        this.lastSelected = undefined;

        this._reviewStatistics.request({bagId})
        //TODO: [CAT-963] Should _reviewLevelStatistics not be called here?
        const businessUnitId = this.filterManager.filteredBusinessUnitId;
        this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}))

        // Note for the AICategorizationReviewPage: the singleState is forcefully disabled, see TODO[convoluted-review-interface]
    }

    reInitializeV2(bagId: number, taxonomySize: number) {
        console.log('CategorizationStore.CategorizationReviewDataDelegate.reInitializeV2', {bagId, taxonomySize});
        // TODO[convoluted-review-interface]: This needs a more stable interface
        this.filterManager.resetFilter(undefined);
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup();
        this._reviewLevelStatistics.cleanup();
        this.supplierPages.reset()
        this.shouldHaveReviewLevelStatistics = true;
        this.lastSelected = undefined;

        // TODO[convoluted-review-interface]: This is very hacky...
        this.setRequestBagId(bagId);
        const businessUnitId = this.filterManager.filteredBusinessUnitId;
        this._reviewStatistics.request({bagId})
        console.log('reInitializeV2: ' + businessUnitId + ' ' + taxonomySize + ' ' + bagId)
        this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}))

        return EMPTY;
    }

    reInitializeForApprovalV3(approval: number, bagId: number, taxonomySize: number) {
        console.log('reInitializeForApprovalV3', {approval, bagId, taxonomySize});
        console.log('CategorizationStore reInitializeForApprovalV3', {approval, bagId, taxonomySize});

        // TODO[convoluted-review-interface]: This needs a more stable interface
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup()
        this._reviewLevelStatistics.cleanup();
        this.shouldHaveReviewLevelStatistics = false;
        this.lastSelected = undefined;
        this.supplierPages.reset()
        this.filterManager.resetFilter(approval);

        this.setRequestBagId(bagId);


        // TODO[convoluted-review-interface]: Force to not use the single mode, otherwise the approval page will not be able to load anything
        this.filterManager.setSingleMode(false);
        this.requestSupplierPages(); // The worst code you've ever written maybe ...

        // TODO[convoluted-review-interface]: Is it impossible to calculate the review level statistics from an approval view at the moment?
        // const businessUnitId = this.filterManager.filteredBusinessUnitId;
        // this._reviewStatistics.request({bagId})
        // this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}))
    }

    get reviewStatistics(): MatReviewStatisticsSerializer | undefined | null {
        if (!this._reviewStatistics.result) return undefined;
        if (this._reviewStatistics.result.length !== 1) return null;
        return this._reviewStatistics.result.at(0);
    }

    get isLoading(): boolean {
        return this.shouldHaveReviewLevelStatistics && (
            this._reviewStatistics.busy || this._reviewLevelStatistics.busy
        );
    }

    get hasNoCategorizationResult() {
        return this.reviewStatistics === null;
    }

    get isLoadingParts(): boolean {
        if (this.filterManager.singleMode) {
            return this.partPages.isLoading
        } else {
            return this.supplierPages.isLoading || this._isLoadingParts;
        }
    }

    requestPartPages() {
        const bagId = this.requestedBagId;
        this.lastSelected = undefined;
        if (!bagId) {
            console.error('No bag id set for part pages');
            this.partPages.reset()
        } else {
            const urlParams = this.filterManager.getPartUrlSearchParams();

            this.partPages._request({
                databag: bagId,
                urlParams,
            });
        }
    }

    requestSupplierPages() {
        this.supplierPages._request(this.filterManager.selectedFilter);
    }

    get reviewLevelStatistics(): MatReviewLevelStatisticsTreeSerializer | undefined {
        return this._reviewLevelStatistics.result?.at(0);
    }

    setRequestBagId(bagId: number | undefined) {
        this.requestedBagId = bagId
    }

    setPartData(partData: MatPartReviewRow[], filterType: MatSupplierFilterType) {
        console.time('setPartData()');
        this.lastSelected = undefined;
        // Collect the part data grouped by supplier
        const indices = new Map(this.supplierPages.data?.map((d, i) => ([d.id, i])));
        const partDatas = new Map<number, MatPartReviewRowState[]>();
        for (const part of partData) {
            // interpretPartData
            const partI: MatPartReviewRowState = {
                ...part,
                review_mine: part.review_user_id === this.auth.userId,
                feedback_mine: part.feedback_user_id === this.auth.userId,
                selected: false,
                isLastSelected: false,
                parent_supplier_row: undefined as any,  // Defined below
            }

            const supplierLookupField = MithraMaterializedApi.getMatPartReviewLookupField(filterType)
            const supplierRowId = partI[supplierLookupField]
            const ds = partDatas.get(supplierRowId)
            if (ds) {
                ds.push(partI)
            } else {
                partDatas.set(supplierRowId, [partI])
            }
        }

        // Apply to the view
        partDatas.forEach((parts, key) => {
            const i = indices.get(key);
            if (i === undefined) return;
            const data = this.supplierPages.data;
            if (!data) return;
            const supplierData = data[i];
            if (!supplierData) return

            parts.forEach(p => p.parent_supplier_row = supplierData) // Double link it for easy updating
            supplierData.parts = parts;
            supplierData.combined_state = calcCombinedState(parts, this.maxTaxonomySize)
        })
        console.log(`setPartData() of ${partData.length} parts`);
        console.timeEnd('setPartData()');

        this._isLoadingParts = false;
    }

    get _selectedStats() {
        if (!this.reviewLevelStatistics) {
            return undefined
        }
        return selectMatReviewLevelStatistics(this.reviewLevelStatistics, this.filterManager.selectedCategory)
    }

    get parentCharts(): {
        charts: { category: string, data: single_bar_chart.Data }[],
        max: number,
    } | undefined {
        if (!this._selectedStats) return undefined;
        const parentStats = this._selectedStats.slice(1);
        if (parentStats.length === 0) return undefined;
        const charts = parentStats.map((stat, i) => {
            const aiChangeChart = convertSingleToChart(stat, this.profile.currencySymbol, true);
            const data: single_bar_chart.Data = {
                mainLabel: `L${i + 1}: ${aiChangeChart.label}`,
                values: aiChangeChart.values,
            }
            return {category: aiChangeChart.category, data};
        })
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...charts.map(c => Math.max(...c.data.values.map(v => v.value)))) / COL_WIDTH;
        return {charts, max}
    }

    get currentSelectionStats(): undefined | MatReviewLevelStatisticsTreeSerializer[] {
        if (!this.filterManager.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        return this._selectedStats[this._selectedStats.length - 1].children;
    }

    get selectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.filterManager.canSelectLevelDeeper) return undefined;
        let d = this.currentSelectionStats;
        if (!d) return undefined;

        const data = convertToChart(d, this.profile.currencySymbol)

        if (this.profile.p.hackHideUncategorizedInReview) {
            const uncategorizedIndex = data.findIndex(d => d.category === UNCATEGORIZED_VALUE)
            if (uncategorizedIndex !== -1) {
                console.warn('Hack: Hiding uncategorized in review')
                data.splice(uncategorizedIndex, 1)
            }
        }

        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    get onlyNewSelectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.filterManager.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        let d: MatReviewLevelStatisticsTreeSerializer[] = this._selectedStats[this._selectedStats.length - 1].children;

        const data = convertToChartOnlyNew(d, this.profile.currencySymbol)
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    get summaryResultKeyValues(): SummaryKeyValues | undefined {
        console.log('running summaryResultKeyValues')
        console.log(this.unclassifiedStats)
        console.log(this.reviewStatistics)

        if (this.unclassifiedStats === undefined) {
            return undefined;
        }
        if (!this.reviewStatistics) return undefined;
        const unclassified_spend = this.unclassifiedStats?.values.post_spend || 0
        const reclassified_spend = this.reviewStatistics.cat_recat_spend || 0
        const classified_spend = this.reviewStatistics.total_spend - unclassified_spend
        const after: SummaryKeyValues["after"] = {classified_spend, reclassified_spend, unclassified_spend}
        console.log('summaryResultKeyValues', {after})

        // These values should not have hardcoded abbreviations
        const abbreviation: CurrencyAbbreviation = findAbbreviationOfGroup(Object.values(after));

        return {abbreviation, after}
    }

    get unclassifiedStats(): MatReviewLevelStatisticsTreeSerializer | undefined | null {
        if (!this.reviewLevelStatistics) return undefined;
        return findUnclassified(this.reviewLevelStatistics)
    }

    reloadView(initialLoad = false) {
        if (this.filterManager.singleMode) {
            this.loadData(initialLoad);
        } else {
            this.supplierPages.reloadView()
        }
    }

    loadData(initialLoad = false) {
        const bagId = this.requestedBagId;
        const businessUnitId = this.filterManager.filteredBusinessUnitId;
        const singleMode = this.filterManager.singleMode;
        const taxonomySize = this.maxTaxonomySize;
        // TODO: taxonomy size is not yet updated here...
        console.log('CategorizationReviewDataDelegate.loadData (hardcoded taxonomy size)', {
            bagId,
            businessUnitId,
            singleMode,
            taxonomySize
        });
        if (bagId) {
            this.allSelected = false;
            this.anySelected = false;
            if (singleMode) {
                this.requestPartPages()
                if (initialLoad) {
                    this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}));
                }
            } else {
                this._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}));
                this.requestSupplierPages();
            }
        }
    }

    getSelectedParts(): AnyMatPartReviewRowState[] {
        if (this.filterManager.singleMode) {
            return this.partPages.data?.filter(p => p.selected) || [];
        } else {
            return this.supplierPages.data?.flatMap(s => s.parts?.filter(p => p.selected) || []) || [];
        }
    }

    getCurrentParts(): AnyMatPartReviewRowState[] {
        if (this.filterManager.singleMode) {
            return this.partPages.data || [];
        } else {
            return this.supplierPages.data?.flatMap(s => s.parts || []) || [];
        }
    }
}
