import './CategorizationReview.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {FormControl, Grid, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {SingleBarChart} from "../../../components/visualization/single-barchart/SingleBarChart";
import {NO_MARGIN} from "../../../utils/margin";
import {UNCATEGORIZED_VALUE} from "../../../constants";
import {ReviewBreadcrumbs} from "./ReviewBreadcrumbs";
import {Clear, Search} from "@mui/icons-material";
import {CategorizationReviewMainTable} from "./review-table/CategorizationReviewMainTable";
import {CategorizationReviewKeyValues} from "./CategorizationReviewKeyValues";
import {CurrencyAbbreviationContext} from '../../../components/currency-component/CurrencyAbbreviationContext';

export const CategorizationReview: React.FC = observer(() => {
    const {categorizationStore} = useStores();

    const keyValues = categorizationStore.data.summaryResultKeyValues;

    // TODO[integration]: fix these lines
    // const showTable = Boolean(categorizationStore.data.supplierPages);
    // const showTable = categorizationStore.data.selectedLevel > 0;
    const showTable = categorizationStore.data.filterManager.selectedLevel > 0 && Boolean(categorizationStore.data.supplierPages);

    useEffect(() => {
        categorizationStore.data.reloadView(true);
    }, [categorizationStore]);

    const dataMax = Math.max(
        categorizationStore.data.selectionCharts?.max || 1,
        categorizationStore.data.parentCharts?.max || 1
    );
    return <div className="categorization-review">

        <Grid container justifyContent="center">
            <Grid className=""
                  item xs={6}
                  container>
                <ReviewBreadcrumbs/>
            </Grid>
        </Grid>

        {/*<div>*/}
        {/*    selectedLevel={JSON.stringify(categorizationStore.data.selectedLevel)}<br/>*/}
        {/*    selectedCategory={JSON.stringify(categorizationStore.data.selectedCategory)}<br/>*/}
        {/*    selectedStats={JSON.stringify(categorizationStore.data._selectedStats?.map(s => s.label))}<br/>*/}
        {/*</div>*/}

        <CategorizationReviewKeyValues/>

        {/*{categorizationStore.data.parentCharts &&*/}
        {/*    <Grid container>*/}
        {/*        <Grid item xs={3}>*/}
        {/*            <Button*/}
        {/*                className="nav-all-cats-btn"*/}
        {/*                color="primary"*/}
        {/*                variant="text"*/}
        {/*                startIcon={<ChevronLeft/>}*/}
        {/*                onClick={() => categorizationStore.data.unsetSelectedCat()}>*/}
        {/*                All categories*/}
        {/*            </Button>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={9} className="detail-bar-chart">*/}
        {/*            {categorizationStore.data.parentCharts.charts.map(({category, data}, index) =>*/}
        {/*                <div key={data.mainLabel + index}*/}
        {/*                     className={*/}
        {/*                         `bar-chart-container l${index + 1}` +*/}
        {/*                         (categorizationStore.data.canClickParentChart(index) ? ' clickable' : '')*/}
        {/*                     }*/}
        {/*                     onClick={() => categorizationStore.data.clickParentChart(index)}>*/}
        {/*                    <SingleBarChart*/}
        {/*                        data={data}*/}
        {/*                        className={category === UNCATEGORIZED_VALUE ? 'uncat' : ''}*/}
        {/*                        options={{width: 1300, height: 45, margin: NO_MARGIN, dataMax, labelHover: true}}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            )}*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*}*/}

        {categorizationStore.data.selectionCharts &&
            <Grid container justifyContent="center">
                <Grid item xs={3}/>
                <Grid item xs={9} className="overview-bar-charts">
                    {/* The bars of the bar chart */}
                    {categorizationStore.data.selectionCharts.data.map(changeChartData =>
                        <div key={changeChartData.category}
                             className={`clickable bar-chart-container l${categorizationStore.data.filterManager.selectedLevel + 1}`}
                             onClick={() => categorizationStore.data.filterManager.selectNextCategoryDown(changeChartData.category)}>
                            <SingleBarChart
                                key={changeChartData.category}
                                data={{
                                    mainLabel: changeChartData.label,
                                    values: changeChartData.values,
                                }}
                                className={changeChartData.category === UNCATEGORIZED_VALUE ? 'uncat' : ''}
                                options={{width: 1300, height: 45, margin: NO_MARGIN, dataMax, labelHover: true}}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        }
        {showTable &&
            <>
                <Grid container justifyContent="start">
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth={true} className="search-field">
                            <InputLabel htmlFor="supplier-search-field">
                                Vendor search
                                {/*{categorizationStore.data.filter.hits !== undefined &&*/}
                                {/*    ` (${categorizationStore.data.filter.hits} result${categorizationStore.data.hits.leftHits === 1 ? '' : 's'})`*/}
                                {/*}*/}
                            </InputLabel>
                            <Input
                                id="supplier-search-field"
                                type="text"
                                fullWidth={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear search"
                                            onClick={() => categorizationStore.data.filterManager.supplierSearch.clearSearch()}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => categorizationStore.data.filterManager.supplierSearch.doSearch()}
                                        >
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={categorizationStore.data.filterManager.supplierSearch.searchString}
                                onChange={e => categorizationStore.data.filterManager.supplierSearch.setSearch(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' ? categorizationStore.data.filterManager.supplierSearch.doSearch() : undefined}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {keyValues?.abbreviation
                            && <CurrencyAbbreviationContext.Provider value={keyValues.abbreviation}>
                                <CategorizationReviewMainTable/>
                            </CurrencyAbbreviationContext.Provider>
                        }
                    </Grid>
                </Grid>
            </>
        }
    </div>
});
