import {
    MatchNodeDataType,
    Tree
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {from, map} from "rxjs";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {TreeDataType} from "../../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import {BagStore} from "../../../stores/BagStore";
import TaxonomyManagerStore from "../../../stores/TaxonomyManagerStore";
import {TaxonomyMapDelegate} from "./TaxonomyMapDelegate";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {TaxonomyMapSelectionDelegate} from "./TaxonomyMapSelectionDelegate";
import {RequestManager} from "../../../stores/managers/RequestManager";
import * as d3 from "d3";
import {TaxonomyMapFilterDelegate} from "./TaxonomyMapFilterDelegate";
import {TaxonomyMapTreeControllerDelegate} from "./TaxonomyMapTreeControllerDelegate";
import {environment} from "../../../env";
import AuthStore from "../../../stores/AuthStore";

export class TaxonomyMapperStore {
    public readonly mapper = new TaxonomyMapDelegate(this.api, this)
    public readonly selection = new TaxonomyMapSelectionDelegate(this.api, this);
    public readonly filter = new TaxonomyMapFilterDelegate(this);
    public readonly treeController = new TaxonomyMapTreeControllerDelegate(this, this.selection);

    autoUpdateConnectedRight = true;

    private readonly getFirstMatTax = ({taxonomy}) => from(this.api.listMatTaxonomy(taxonomy)).pipe(
        map(resp => {
            if (resp.data.length === 0) {
                throw new Error('No materialized Taxonomy found')
            }
            if (resp.data.length !== 1) {
                throw new Error(`Too many materialized Taxonomies found: ${resp.data.map(m => m.name)}`)
            }
            return resp.data[0];
        })
    );
    readonly _baseTaxonomyRequestManager = new RequestManager<{ taxonomy: number }, m_taxonomy.MaterializedTaxonomy>(this.getFirstMatTax);
    readonly _targetTaxonomyRequestManager = new RequestManager<{ taxonomy: number }, m_taxonomy.MaterializedTaxonomy>(this.getFirstMatTax);

    constructor(
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private profileStore: ProfileStore,
        private bagStore: BagStore,
        private baseTaxonomyManager: TaxonomyManagerStore,
        private fixedBaseTaxonomyId?: number,
        private fixedTargetTaxonomyId?: number,
    ) {
        makeAutoObservable(this)
        // In the first iteration we will manage the base taxonomy with the existing TaxonomyManagerStore
        // And the target taxonomy is retrieved and managed in this class

        // Register
        this.mapper.register();
        reaction(() => [this.baseTaxonomyId, this.masterTaxonomyId, this.auth.isLoggedIn()] as const, ([baseTaxonomyId, targetTaxonomyId, loggedIn]) => {
            if(loggedIn) {
                this.initialize(baseTaxonomyId, targetTaxonomyId);
            }
        }, {fireImmediately: true})
    }

    get baseTaxonomyId(): number | undefined {
        return this.fixedBaseTaxonomyId || this.profileStore.p.hardcodedBaseTaxonomyId || this.baseTaxonomyManager.taxonomy?.id
    }

    get masterTaxonomyId(): number | undefined {
        return this.fixedTargetTaxonomyId || this.profileStore.p.masterTaxonomyId
    }

    reset() {
        this.initialize(this.baseTaxonomyId, this.masterTaxonomyId);
        this.filter.reset()
        this.treeController.reset();
    }

    initialize(baseTaxonomyId: number | undefined, targetTaxonomyId: number | undefined) {
        console.debug('TaxonomyMapperStore.initialize', baseTaxonomyId, targetTaxonomyId)
        if (baseTaxonomyId && targetTaxonomyId) {
            this._baseTaxonomyRequestManager.request({taxonomy: baseTaxonomyId})
            this._targetTaxonomyRequestManager.request({taxonomy: targetTaxonomyId})
        }
    }

    get baseMatTaxonomy(): m_taxonomy.MaterializedTaxonomy | undefined {
        return this._baseTaxonomyRequestManager.result;
    }

    get targetMatTaxonomy(): m_taxonomy.MaterializedTaxonomy | undefined {
        return this._targetTaxonomyRequestManager.result;
    }

    get matTaxIds(): { srcMatTax: number | undefined, dstMatTax: number | undefined } {
        return {
            srcMatTax: this.baseMatTaxonomy?.id,
            dstMatTax: this.targetMatTaxonomy?.id,
        }
    }

    get selectedBaseCategory(): string[] | undefined {
        // return nodeToCategories(this.leftSelectionIndex, this.vizPointData);
        return undefined;
    }

    get selectedTargetCategory(): string[] | undefined {
        // return nodeToCategories(this.rightSelectionIndex, this.vizPointData);
        return undefined;
    }

    get baseDataBagName(): string {
        return this.bagStore.bag?.name || '';
    }

    get baseTaxonomyName(): string {
        return this.baseMatTaxonomy?.name || '';
    }

    get baseDataBagId(): number | undefined {
        if (environment.environmentName === 'atk') {
            // TODO: Documentation needed
            if (!this.bagStore.bag?.baseline) {
                console.warn('For ATK an baseline is expected')
            }
        }
        return this.bagStore.bag?.baseline || this.bagStore.bag?.id;
    }

    get targetTaxonomyName(): string {
        return this.targetMatTaxonomy?.name || '';
    }

    get baseTaxonomyHierarchy(): Tree | undefined {
        if (!this.baseMatTaxonomy) return undefined;
        return TaxonomyMapperStore.buildTreeData(this.baseMatTaxonomy, true)
    }

    get targetTaxonomyHierarchy(): Tree | undefined {
        if (!this.targetMatTaxonomy) return undefined;
        return TaxonomyMapperStore.buildTreeData(this.targetMatTaxonomy, false)
    }

    private static buildTreeData(materializedTaxonomy: m_taxonomy.MaterializedTaxonomy, isLeft: boolean): Tree {
        const convertedTree = TaxonomyMapperStore.convertTreeDataNode(materializedTaxonomy.tree_state, isLeft);
        const hTree = d3.hierarchy(convertedTree);
        console.log('TaxonomyMapStore.buildTreeData', hTree.height)
        hTree.sort((a, b) => a.data.label?.localeCompare(b.data.label || '') || 0)
        return hTree;
    }

    setAutoUpdateConnectedLeft(on: boolean) {
        this.autoUpdateConnectedRight = on;
        if (!on) {
            this.treeController.resetTreeAlignment();
        }
    }

    private static convertTreeDataNode(tree: m_taxonomy.MaterializedTaxonomyTree, isLeft: boolean): TreeDataType<MatchNodeDataType> {
        const thisId = String(tree.values.id);
        const values: MatchNodeDataType = {
            isLeft,
            valueTitle: '',
            data: tree.values,
        };
        // noinspection UnnecessaryLocalVariableJS
        const node: TreeDataType<MatchNodeDataType> = {
            id: +thisId,
            label: tree.label,
            values,
            children: tree.children.map(c => TaxonomyMapperStore.convertTreeDataNode(c, isLeft)),
            selected: false,
            childSelected: false,
            highlighted: false,
        }
        // console.log('TaxonomyMapStore.convertTreeDataNode.node=', node);
        return node;
    }
}
