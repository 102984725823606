import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {Button, ButtonGroup, Dialog, Grid, LinearProgress, TextField, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {ApprovalRouteMatch, MithraHistoryState, routes} from "../../../routing/routes";
import {useHistory} from "react-router";
import {ApprovalStatusEnum, ApprovalTypeEnum} from "../../../services/classes/AiClasses";
import {ApprovalStore} from "../../../stores/ApprovalStore";
import moment from "moment";
import {Check, Clear} from "@mui/icons-material";
import {APPROVAL_DIALOG_TITLE_ID} from "../ApprovalDialog";
import {CategorizationApprovalAcceptDialog} from "./CategorizationApprovalAcceptDialog";
import {SingularCategorizationApprovalTable} from "./singular-cat-approval-table/SingularCategorizationApprovalTable";
import {ReviewChoice} from "../../../services/classes/MaterializedClasses";
import {CategorizationReviewFeedbackMainTable} from "./review-feedback-table/CategorizationReviewFeedbackMainTable";

/**
 * Partially implemented:
 * - The Accept and Reject buttons are not tested yet
 * - The dimensions are still off
 * - The Grouped supplier table is not added yet
 * - The filter-toolbar is not in yet
 */
const EXPERIMENTAL_USE_NEW_TABLE = false;

export const ApprovalCategorizationPage: React.FC = observer(() => {
    const routeMatch = useRouteMatch<ApprovalRouteMatch>();
    const history = useHistory<MithraHistoryState>();
    const {approvalStore} = useStores();

    // The magic that loads the data
    useEffect(() => {
        approvalStore.onLoadApprovalPage(ApprovalTypeEnum.CATEGORIZATION, routeMatch, history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const a = approvalStore.categorizationApproval;
    const busyQueueSize = approvalStore.cat.reviewFeedback.numberOfBusyRequests;
    const hasPendingOperations = busyQueueSize !== 0;
    const canEdit = a && ApprovalStatusEnum.userCanUpdate(a.current_status.status) && !hasPendingOperations;
    const dateStr = moment(a?.current_status.timestamp).format('L LT')

    const ofBagStr = a?.baseline_bag ? ` of dataset ${a?.baseline_bag.name}` : '';
    return <KoiFacade
        title="Categorization approvals"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<Typography className="one-line-p">
            {a && (
                `${ApprovalStore.showRequestType(a.request_type)}${ofBagStr} ―
                 ${a.created_by.first_name} ${a.created_by.last_name} ―
                 ${dateStr}`
            )}
        </Typography>}
        back={routes.approval}
        className="approval-page categorization-approval-page"
    >
        <Dialog
            open={approvalStore.isAcceptApprovalDialogOpen}
            onClose={() => approvalStore.clickAwayApprovalDialog()}
            aria-labelledby={APPROVAL_DIALOG_TITLE_ID}
            maxWidth="lg"
        >
            {approvalStore.isAcceptApprovalDialogOpen && <CategorizationApprovalAcceptDialog/>}
        </Dialog>
        <Grid container justifyContent="center" alignItems="flex-end">
            <Grid item md={6} xs={10}>
                <TextField
                    multiline
                    fullWidth
                    label="Notes for the reviewer"
                    variant="filled"
                    value={a?.notes || ''}
                    inputProps={{readOnly: true}}
                />
            </Grid>
            <Grid item md={2} xs={2}
                  container direction="column" alignItems="center">
                <Grid item>
                    <Button
                        variant="outlined"
                        className="m-2"
                        onClick={() => approvalStore.openSendApprovalDialog()}
                        disabled={!canEdit}
                    >
                        Finish review
                    </Button>
                </Grid>
                <Grid item>
                    <ButtonGroup variant="contained" size="small" className="m-2" disabled={!canEdit}>
                        <Button
                            className={"btn-grp-red"}
                            onClick={() => approvalStore.cat.clickSetAll(ReviewChoice.REJECT)}
                            startIcon={<Clear className="reject"/>}>
                            Reject all
                        </Button>
                        <Button
                            className={"btn-grp-green"}
                            onClick={() => approvalStore.cat.clickSetAll(ReviewChoice.ACCEPT)}
                            startIcon={<Check className="accept"/>}>
                            Approve all
                        </Button>
                    </ButtonGroup>
                    {/*<LinearProgress*/}
                    {/*    variant="determinate"*/}
                    {/*    value={1 / (busyQueueSize + 1) * 100}*/}
                    {/*/>*/}
                    <div className="progress-container">
                        {hasPendingOperations && <LinearProgress variant="indeterminate"/>}
                    </div>
                </Grid>
                <Grid item>
                    {ApprovalStore.getApprovalStatusName(a?.current_status.status)}
                </Grid>
            </Grid>
        </Grid>

        <Grid container justifyContent="center">
            <Grid item xs={12}>
                {EXPERIMENTAL_USE_NEW_TABLE
                    ? <SingularCategorizationApprovalTable/>
                    : <CategorizationReviewFeedbackMainTable/>
                }
            </Grid>
        </Grid>

    </KoiFacade>
})
