import React from "react";
import {observer} from "mobx-react-lite";
import {Checkbox, Grid, TextField} from "@mui/material";
import {EditorModalComponent} from "../../../taxonomy-editor/EditorModalComponent";
import {useStores} from "../../../../stores";

const CAT_FILTER_MODAL_ID = 'categorization-filter-modal';
export const CAT_FILTER_MODAL_TITLE_ID = CAT_FILTER_MODAL_ID + '-title';

export const CategorizationFilterModal: React.FC = observer(() => {
    const {categorizationReviewStore, categorizationStore} = useStores();

    const fieldsEnabled = categorizationStore.data.filterManager.isAnyAdvancedFilterFieldEnabled;
    return <EditorModalComponent
        titleId={CAT_FILTER_MODAL_TITLE_ID}
        className="categorization-review-model-component"
        title="Filter"
        saveLabel={fieldsEnabled ? 'Apply' : 'Ok'}
        canSave={true}
        // hideCancel
        onSave={() => {
            if(categorizationStore.data.filterManager.checkAdvancedFilterIsValid()) {
                categorizationStore.data.filterManager.applyAdvancedFilter()
                categorizationReviewStore.setAdvancedFilterDialogOpen(false)
            }
        }}
        cancelLabel="Disable"
        onCancel={() => {
            categorizationStore.data.filterManager.disableAdvancedFilter()
            categorizationReviewStore.setAdvancedFilterDialogOpen(false)
        }}
        // isSaving={categorizationReviewStore.isBusySendForApproval}
        onClose={() => categorizationReviewStore.setAdvancedFilterDialogOpen(false)}
        // isError={categorizationReviewStore.isSendForApprovalError}
    >
        {/*<Typography variant="body1"></Typography>*/}
        {/*Some text*/}
        <Grid container alignContent="center" alignItems="center" direction="row" xs={12}>
            <Grid item xs="auto">
                <Checkbox
                    className="mb-4"
                    checked={categorizationStore.data.filterManager.scoreFilterFieldEnabled}
                    onChange={() => categorizationStore.data.filterManager.toggleScoreFilterFieldEnabled()}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    fullWidth
                    id={CAT_FILTER_MODAL_ID + '-score-field-min'}
                    label="Minimal score"
                    error={categorizationStore.data.filterManager.scoreLowFilterFieldError !== ''}
                    helperText={categorizationStore.data.filterManager.scoreLowFilterFieldError || ' '}
                    variant="outlined"
                    value={categorizationStore.data.filterManager.scoreLowFilterField}
                    onChange={e => categorizationStore.data.filterManager.setScoreLowFilterField(e.target.value)}
                    onClick={e => categorizationStore.data.filterManager.setScoreFilterFieldEnabled()}
                    // disabled={categorizationReviewStore.isBusySendForApproval}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    fullWidth
                    id={CAT_FILTER_MODAL_ID + '-score-field-max'}
                    label="Maximal score"
                    error={categorizationStore.data.filterManager.scoreHighFilterFieldError !== ''}
                    helperText={categorizationStore.data.filterManager.scoreHighFilterFieldError || ' '}
                    variant="outlined"
                    value={categorizationStore.data.filterManager.scoreHighFilterField}
                    onChange={e => categorizationStore.data.filterManager.setScoreHighFilterField(e.target.value)}
                    onClick={e => categorizationStore.data.filterManager.setScoreFilterFieldEnabled()}
                    // disabled={categorizationReviewStore.isBusySendForApproval}
                />
            </Grid>
        </Grid>


    </EditorModalComponent>
})
