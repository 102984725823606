import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Checkbox, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../../components/categorization/PartCategorizationComponent";
import {PartReCategorizationComponent} from "../../../../../components/categorization/PartReCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {CurrencyAbbreviationContext} from "../../../../../components/currency-component/CurrencyAbbreviationContext";
import {environment} from "../../../../../env";
import {ReviewChoice} from "../../../../../services/classes/MaterializedClasses";
import {getSpecificCategorization} from "../../../../../services/classes/MatReviewHelpers";
import {SingularMatPartReviewRowState} from "../../../../../services/classes/MatReviewClasses";
import {getCatReviewSubColumns} from "../columns";
import {MithraTableCell} from "../../../../../components/table/MithraTableCell";
import {CategorizationReviewStore} from "../../../../../stores/CategorizationReviewStore";
import {SingleReviewButtons} from "../../../../../components/approval-acc-rej/SingleReviewButtons";
import {GoogleLink} from "../../../../../components/_tiny/GoogleLink";
import {AiScore} from "../../../../../components/_tiny/AiScore";
import moment from "moment";


type Props = {
    part: SingularMatPartReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};
export const SingularCategorizationReviewRow: React.FC<Props> = observer(({part, controller}) => {
    const {p, authStore, bagStore, categorizationStore, categorizationReviewStore} = useStores();
    const COLUMNS = getCatReviewSubColumns(p, categorizationStore.desiredBagId, true);

    const partState = CategorizationReviewStore.calcSingleState(part);
    partState.editAllowed = !authStore.viewOnly && partState.editAllowed;

    // /**
    //  * Hack for ATK
    //  * - The API requests the /with_context/ endpoints
    //  */
    // const atkPart = part as any as MatPartReviewRowWithContext;
    // const contextCats = getContextReviewCategory(atkPart, bagStore.taxonomy_size);

    // FIXME: This Taxonomy size should come from the categorizationStore?
    const leftCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.leftCat);
    const rightCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.rightCat);

    let categoryComponent;

    let onAccept: undefined | (() => void) = undefined;
    if (!partState.editAllowed) {
        categoryComponent = <PartCategorizationComponent cats={rightCats}/>
    } else {
        onAccept = () => categorizationReviewStore.clickAcceptReject(part, ReviewChoice.ACCEPT);
        if (categorizationReviewStore.categories.taxonomy_categories) {
            categoryComponent = <PartReCategorizationComponent
                cats={rightCats}
                handleOpenSearch={event => {
                    const anchor = event.currentTarget;
                    controller.setOnSelect((newCats) => {
                        categorizationReviewStore.clickRecatPart(part, newCats)
                    })
                    controller.open(anchor)
                }}
            />
        } else {
            categoryComponent = <PartCategorizationComponent cats={rightCats}/>
        }
    }

    // TODO: Cleanup types of SingularMatPartReviewRowState
    const partWithSupplier = part as SingularMatPartReviewRowState & { s_name: string };
    let i = 0;
    return <TableRow className={part.isLastSelected ? ' is-last-selected' : ''}>
        <MithraTableCell c={COLUMNS[i++]} component="th" scope="row">
            <Checkbox checked={part.selected}
                      onClick={event => categorizationReviewStore.toggleSelectionSubRow(part, event.shiftKey)}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <GoogleLink type="part_with_supplier" objs={[part]}>
                {partWithSupplier.s_name}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {environment.isTest && <>p_id={part.p_id}<br/></>}
            {part.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="part" objs={[part]}>
                    {part.p_description}
                </GoogleLink>
            </MithraTableCell>
        }

        {p.partContextColNames.map(({dataField, colType}) =>
            <MithraTableCell key={dataField} c={COLUMNS[i++]}>
                {colType === 'date'
                    ? moment(part[dataField]).format('L')
                    : String(part[dataField])}
            </MithraTableCell>
        )}

        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={leftCats}/>
        </MithraTableCell>
        {p.showCategorizationScoreForBag(categorizationStore.desiredBagId) &&
            <MithraTableCell c={COLUMNS[i++]}>
                <AiScore score={part.p_suggestion_score}/>
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            {categoryComponent}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {rightCats !== false && rightCats.every(cat => cat === '') ? null : (
                <SingleReviewButtons onAccept={onAccept} state={partState}/>
            )}
        </MithraTableCell>
    </TableRow>
})
