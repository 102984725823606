import './DashBoard.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Alert, AlertTitle, Button, Grid} from "@mui/material";
import {DashBoardCard} from "./DashBoardCard";
import {generatePath, useHistory} from "react-router";
import {JobRouteMatch, routes} from "../../routing/routes";
import {Bag} from "../../services/classes/Bag";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {MoveIcon} from "../../components/icons/MoveIcon";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {SupplierSegmentationIcon} from "../../components/icons/SupplierSegmentationIcon";
import {useRouteMatch} from "react-router-dom";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {QueryStats} from "@mui/icons-material";
import {TaxonomyMapperIcon} from "../../components/icons/TaxonomyMapperIcon";
import {SupplierNormalizationV2Icon} from "../../components/icons/SupplierNormalizationV2Icon";

export const DashBoard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        categorizationStore,
        spendConcentrationStore,
        ppvControllerStore,
        taxonomyMapperStore,
        // Temporary fix for CAT-824
        // TODO: CAT-888
        supplierNormalizationStore
    } = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const scTop = spendConcentrationStore.topValueP;
    // Temporary fix for CAT-824
    // TODO: CAT-888
    const numberOfSuppliers = p.p.hackNSuppliersFromCommonSuppliers ?
        supplierNormalizationStore.postKeyValues?.total_suppliers_count
        : scTop;
    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;
    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const ppvTotal = ppvControllerStore.spendTotalPPV;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    return <>
        {
            newDatasetId && bagStore.allBags !== undefined && <div className="dashboard-grid-alert">
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Alert variant="standard" severity="warning" action={
                            <Button
                                color="inherit" size="small"
                                onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                                Open the new dataset
                            </Button>
                        }>
                            <AlertTitle>This is an old version</AlertTitle>
                            This dataset is reviewed and all the changes are applied in a newer version of the dataset
                        </Alert>
                    </Grid>
                </Grid>
            </div>
        }
        <Grid container
              className="dashboard dashboard-v2-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={nCategories}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    // value={nCategories}
                    // subtitle="categories"
                    link={generatePath(routes.job_koi_kdf, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.job_ai_cat_welcome, {id: bagId})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    link={generatePath(routes.job_taxonomy_builder, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common ?
                    <CurrencyComponent v={bagStore.kpi.common.total_spend}
                                       nDigits={3}/> : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(ppvTotal)}
                    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.job_koi_ppv, {id: bagId})}
                />
                {
                    p.p.useLookerPreviewCard && <DashBoardCard
                        cardKey="adv-looker-dashboard"
                        avatar={<QueryStats fontSize="large"/>}
                        title="Advanced Dashboard"
                        // value={drawOpportunityValue(ppvTotal)}
                        // valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                        subtitle="Preview"
                        link={generatePath(routes.looker_preview_id, {id: bagId})}
                        // badge="preview"
                    />
                }
                {
                    p.p.useTaxonomyMapperCard && <DashBoardCard
                        cardKey="taxonomy-mapper"
                        avatar={<TaxonomyMapperIcon/>}
                        title="Taxonomy Mapper"
                        value={unmatchedCategories === undefined
                            ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}
                        subtitle="unmapped categories"
                        link={generatePath(routes.taxonomy_mapper, {id: bagId})}
                        badge="Ai"
                    />
                }
                {/*<DashBoardCard*/}
                {/*    avatar={<ContractIcon/>}*/}
                {/*    title="Analyze Payment Terms"*/}
                {/*    value={drawOpportunityValue(paymentTermsOpp)}*/}
                {/*    valueColor={paymentTermsOpp ? (paymentTermsOpp >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    link={generatePath(routes.job_koi_cfo, {id: bagId})}*/}
                {/*    badge="Ex"*/}
                {/*    isTrial={DISABLE_FRANKE_DASHBOARD_BUTTONS}*/}
                {/*/>*/}
                {p.p.useDataIngestion &&
                    <DashBoardCard
                        cardKey="data-ingestion"
                        avatar={<MoveIcon/>}
                        title="Data ingestion"
                        link={generatePath(routes.data_ingestion, {id: bagId})}
                    />
                }
            </DashboardRow>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    // Temporary fix for CAT-824
                    // TODO: CAT-888
                    // value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    value={numberOfSuppliers ? `${Math.round(numberOfSuppliers)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.job_koi_sc, {id: bagId})}
                />
                {/*<DashBoardCard*/}
                {/*    avatar={<PersonHierarchyIcon/>}*/}
                {/*    title="Improve Supplier Hierarchy"*/}
                {/*    link={generatePath(routes.job_koi_sh, {id: bagId})}*/}
                {/*    badge="Ai"*/}
                {/*    isTrial={DISABLE_FRANKE_DASHBOARD_BUTTONS}*/}
                {/*/>*/}
                {
                    p.p.useSupplierNormalizationCard && <DashBoardCard
                        cardKey="supplier-normalization"
                        avatar={<SupplierNormalizationV2Icon/>}
                        title="Supplier Normalization"
                        value={drawOpportunityValue(pendingSupplierNormalization)}
                        subtitle="suppliers not reviewed yet"
                        link={generatePath(routes.job_supplier_normalization, {id: bagId})}
                        badge="Ai"
                    />
                }
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.job_koi_ss, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
