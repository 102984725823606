/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/NotFound";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {HeaderWrapper} from "./HeaderWrapper";
import {reaction} from "mobx";

/**
 * Wrapper for the mergeX product offering
 */
export const MergeXEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {
        rootAppStore,
        bagStore,
        bagLoadingStore,
        cmpStore,
        taxonomyManagerStore,
        categorizationStore,
        categorizationReviewStore,
        supplierNormalizationStore,
        masterSupplierNormalizationStore,
        taxonomySuggestorStore,
        p
    } = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const isNoData = state.dataType === 'no_data';

    // TODO: See if this can be optimized, probably by the usage of react Context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the data
    useEffect(() => {
        const bagId = bagStore.bag?.id;
        taxonomySuggestorStore.setSelectedNodeLabel(undefined)
        taxonomySuggestorStore.setSelectedNodeValues(undefined)
        if (!p.p.hardcodeMasterBagId) throw new Error('No hardcoded hardcodeMasterBagId provided');
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId provided');
        if (p.p.hardcodeMasterBagId && bagId && bagId === p.p.hardcodeMasterBagId) supplierNormalizationStore.isMaster = true;


        // for SHV we classified all subsidiaries to the new masterTaxonomyId
        // categorizationReviewStore.

        // categorizationReviewStore.categories.fillCategoriesFromTaxonomy(p.p.masterTaxonomyId)
        // categorizationStore.setDesiredBag(p.p.hardcodedCategorizationBagId, 3);
        // cmpStore.initBags(p.p.hardcodeMasterBagId)
        // // taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

        masterSupplierNormalizationStore.init(p.p.hardcodedCombinedBagId, 4) // Only used for the card titles

        const disposer = reaction(
            () => [bagStore.bagId, rootAppStore.app.dashboardEnvironment, bagStore.bag?.taxonomy_size] as const,
            ([bagId, dashboardEnvironment, taxonomySize]) => {
                /**
                 * @deprecated TODO: architecture for pre-loading of data is required, this is too hacky
                 * @see https://mobx.js.org/reactions.html#use-reactions-sparingly
                 */
                if (p.p.hackNSuppliersFromCommonSuppliers && bagId && taxonomySize)
                    supplierNormalizationStore.init(bagId, taxonomySize)

                // if (!bagId || !p.p.hardcodedCombinedBagId) return;
                // if (dashboardEnvironment === 'merge') {
                //     supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, 4);
                //     // categorizationStore.setDesiredBag(p.p.hardcodedCategorizationBagId, 3); // Resulting categorization
                // } else {
                //     supplierNormalizationStore.init(bagId, bagStore.taxonomy_size)
                //     // categorizationStore.setDesiredBag(bagId, bagStore.taxonomy_size); // Resulting categorization
                // }
            })

        if (!isNoData) {
            bagLoadingStore.initBag(location, history, match)

            // Overwrite the taxonomy for the categorization store
            // Each Taxonomy Editor uses loads in its own taxonomy
            taxonomyManagerStore.setDesiredTaxonomyId(p.p.masterTaxonomyId);

            // bagLoadingStore.loadBagWithoutLoadingStores(p.p.hardcodedSubsidiaryBagId).then(bag => {
            //     console.log(`Wrapper: Bag ${p.p.hardcodedBagId} loaded`);
            //     cmpStore.setCompareBag(bag);
            // });

            if (p.p.hardcodedTaxonomySuggestionId) {
                // The suggestion is still hardcoded, define the design to understand where and how it should be retrieved (soruce: CAT-873)
                taxonomySuggestorStore.getTaxonomySuggestion(Number(p.p.hardcodedTaxonomySuggestionId))
            }
        }
        return () => disposer();
    }, [])

    // const desiredMasterBagStr = cmpStore.desiredMasterBagId ? `(${cmpStore.desiredMasterBagId}) ` : '';
    // const masterError = `Could not find Master data ${desiredMasterBagStr}on the server anymore`;

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        <LoadingBarComponent/>
        {/*{*/}
        {/*    bagLoadingStore.bagNotFound*/}
        {/*        ? <NotFound msg={"Could not find data on the server anymore"}/>*/}
        {/*        : cmpStore.masterBagNotFound*/}
        {/*            ? <NotFound msg={masterError}/>*/}
        {/*            : bagStore.bag && cmpStore.masterBag*/}
        {/*                ? children*/}
        {/*                : <div className="loading-spinner">*/}
        {/*                    <LoadingSpinnerPie/>*/}
        {/*                </div>*/}
        {/*}*/}
        {
            isNoData
                ? (children)
                : (
                    bagLoadingStore.bagNotFound
                        ? <NotFound msg={"Could not find data on the server anymore"}/>
                        : bagStore.bag
                            ? children
                            : <div className="loading-spinner">
                                <LoadingSpinnerPie/>
                            </div>
                )
        }
    </MainComponent>;
});
