import React from "react";
import {observer} from "mobx-react-lite";
import {TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {SUPPLIER_COLUMNS} from "./columns";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {MithraTableCell} from "../../../components/table/MithraTableCell";
import {
    CommonSupplierReviewMemberRowState,
    getSpend
} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {useStores} from "../../../stores";

type Props = {
    subRow: CommonSupplierReviewMemberRowState
};
export const SynergyCommonSupplierReviewSubRow: React.FC<Props> = observer(({subRow}) => {
    const {p} = useStores()
    let i = 0;
    const isBase = subRow.s_context_3 === 'base';
    const isMaster = subRow.s_context_3 === 'master';
    const className = 'sub-row' + (isBase ? ' base' : '') + (isMaster ? ' master' : '');
    return <TableRow className={className}>
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[i++]}>*/}
        {/*    <Stack direction="row" className="supplier-parent-chooser-wrapper">*/}
        {/*        /!*<SynergyCommonSupplierChooser value={value} setValue={changeValue} label="Matched on"/>*!/*/}
        {/*        /!*{subRow.sp_id}*!/*/}
        {/*    </Stack>*/}
        {/*</MithraTableCell>*/}
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.s_name}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={getSpend(subRow)} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.s_country}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {subRow.total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[i++]}>
            {isBase
                ? p.p.compareCompanyName : isMaster
                    ? p.p.masterCompanyName
                    : subRow.s_context_3}
        </MithraTableCell>
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[4]}>*/}
        {/*    {Math.round(subRow.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
