import './TaxonomyEditorPage.scss'
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {Alert, AlertProps, AlertTitle, Button, Dialog, Grid, MenuItem, Select, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";
import {MoveCategoryModal} from "./MoveCategoryModal";
import {CreateCategoryModal} from "./CreateCategoryModal";
import {MergeCategoryModal} from "./MergeCategoryModal";
import {TaxonomyEditor} from "../../components/visualization/taxonomy-editor/TaxonomyEditor";
import {TaxonomyEditorOptions} from "../../components/visualization/taxonomy-editor/TaxonomyEditorOptions";
import {UpdateCategoryModal} from "./UpdateCategoryModal";
import {TaxonomyNodeValueMode} from "../../stores/TaxonomyEditorStore";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, TaxonomyRouteMatch} from "../../routing/routes";
import {TaxonomyEditorButtonBar} from "./TaxonomyEditorButtonBar";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {ConfirmSendForApproval} from "./modals/ConfirmSendForApproval";
import {ApprovalStatusEnum} from "../../services/classes/AiClasses";
import {ApprovalStore} from "../../stores/ApprovalStore";
import {jsx} from "@emotion/react";
import {TaxonomyEditorBreadcrumbs} from '../../components/visualization/taxonomy-editor/TaxonomyEditorBreadcrumbs';
import {generateHomePath} from "../../routing/routing.utils";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import JSX = jsx.JSX;


export const IS_TE_DEVELOPMENT_MODE = false

const zoom = 1;
const options: Partial<TaxonomyEditorOptions> = {
    height: 900 / zoom, // For LW we need a bit bigger taxonomy, TODO CAT-712: move to configuration file
    width: 1800 / zoom,
    margin: {
        left: 0,
        right: 0,
        top: 7 + 30,
        bottom: 7 + 30,
    },
    nodeDistance: 270,
    showNumberLevels: 2,
    nodeWidth: 55,
    postPadding: IS_TE_DEVELOPMENT_MODE ? 3 : 1.5,
    transitionDuration: 350,
};

type Props = {
    viewer?: boolean
}
export const TaxonomyEditorPage: React.FC<Props> = observer(({viewer}) => {
    const {rootAppStore, bagStore, taxonomyEditorStore, taxonomyManagerStore, p, authStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bag = bagStore.bag as Bag;
    const bagId = bagStore.bagId;
    if (!bag) throw new Error();

    options.height = p.p.taxonomyBuilderHeight ?? options.height;

    // Trigger a desired taxonomy Id change here
    const match = useRouteMatch<JobRouteMatch | TaxonomyRouteMatch>();
    const urlTaxonomyId = 'taxonomyId' in match?.params ? Number(match?.params['taxonomyId']) : undefined;
    useEffect(() => {
        if (p.p.hardcodeTaxonomyId) {
            // If we have a hardcodeTaxonomyId set, always load the hardcoded one
            taxonomyManagerStore.setDesiredTaxonomyId(p.p.hardcodeTaxonomyId);
        } else if (urlTaxonomyId) {
            // Otherwise load the taxonomy from the URL
            taxonomyManagerStore.setDesiredTaxonomyId(urlTaxonomyId);
        } else {
            // Otherwise do not load the taxonomy store (and assume it's loaded somewhere else in the system)
        }
    }, [taxonomyManagerStore, urlTaxonomyId, p])

    // TODO: Disable edit mode for the taxonomy editor when navigation happens
    //  >>> OK

    let resultOfApprovalMsg: { msg: JSX.Element, severity: AlertProps['severity'] } | undefined = undefined;
    if (taxonomyManagerStore.taxonomy?.result_of_approval) {
        const approval = taxonomyManagerStore.taxonomy.result_of_approval;
        const approvalStatus = approval.current_status.status;
        const msg = <Typography>
            <AlertTitle>Approval: {ApprovalStore.getApprovalStatusName(approvalStatus)}</AlertTitle>
            {approval.feedback_notes || 'No notes provided'}
        </Typography>;
        let severity: AlertProps['severity'] = approvalStatus === ApprovalStatusEnum.REJECTED ? 'error' : 'info';
        resultOfApprovalMsg = {
            msg, severity
        };
    }

    const className = 'taxonomy-editor-page dashboard-main-page' + (viewer ? ' viewer' : '');
    return <KoiFacade
        title={viewer ? 'Taxonomy Viewer' : 'Taxonomy Builder'}
        subTitle={taxonomyManagerStore.taxonomy?.name}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="taxonomy-editor-page"
    >
        <div className={className}>
            <Dialog
                open={
                    taxonomyEditorStore.isCreateCategoryMode
                    || taxonomyEditorStore.isUpdateCategoryMode
                    || taxonomyEditorStore.isMergeCategoryMode
                    || taxonomyEditorStore.isMoveCategoryMode
                    || taxonomyManagerStore.isSendForApprovalDialog
                }
                onClose={() => {
                    taxonomyEditorStore.closeModeDialog();
                    taxonomyManagerStore.setSendForApprovalDialog(false);
                }}
                aria-labelledby={"taxonomy-editor-modal-title"}
                maxWidth="lg"
            >
                {taxonomyEditorStore.isUpdateCategoryMode && <UpdateCategoryModal/>}
                {taxonomyEditorStore.isCreateCategoryMode && <CreateCategoryModal/>}
                {taxonomyEditorStore.isMergeCategoryMode && <MergeCategoryModal/>}
                {taxonomyEditorStore.isMoveCategoryMode && <MoveCategoryModal/>}
                {taxonomyManagerStore.isSendForApprovalDialog && <ConfirmSendForApproval/>}
            </Dialog>


            <Grid container justifyContent="center">
                <Grid item md={9} xs={12}>
                    <Grid container justifyContent="end">
                        <Grid item xs={10}>
                            <TaxonomyEditorBreadcrumbs/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={2} xs={false}/>
            </Grid>


            {/* BLOCK FOR DISCUSSION WITH XENIA */}
            {/* Block for having toolbar at the right of the page */}
            <Grid container alignItems="center" className="header" justifyContent="space-between" xs={12}>
                {taxonomyEditorStore.allTaxonomyValueModes.length > 1 &&
                    <Grid item>
                        <Select
                            variant="standard"
                            value={taxonomyEditorStore.valueMode.key}
                            onChange={e => taxonomyEditorStore.setValueMode(e.target.value as TaxonomyNodeValueMode['key'])}>
                            {taxonomyEditorStore.allTaxonomyValueModes.map(m =>
                                <MenuItem key={m.key} value={m.key}>
                                    {m.label}
                                </MenuItem>
                            )}
                        </Select>
                    </Grid>
                }
                {!viewer &&
                    <>
                        <Grid item/>
                        <Grid item className="text-right top-toolbar" xs={10}>
                            <TaxonomyEditorButtonBar/>
                        </Grid>

                    </>
                }
            </Grid>

            {/* BLOCK FOR DISCUSSION WITH XENIA */}
            {/* Block for having toolbar at the center of the page */}
            {/* <Grid container alignItems="center" className="header" justifyContent="space-between" xs={12}>


        <Grid container alignItems="center" className="header" justifyContent="flex-end">
            {taxonomyEditorStore.allTaxonomyValueModes.length > 1 &&
                <Grid item>
                    <Select
                        variant="standard"
                        value={taxonomyEditorStore.valueMode.key}
                        onChange={e => taxonomyEditorStore.setValueMode(e.target.value as TaxonomyNodeValueMode['key'])}>
                        {taxonomyEditorStore.allTaxonomyValueModes.map(m =>
                            <MenuItem key={m.key} value={m.key}>
                                {m.label}
                            </MenuItem>
                        )}
                    </Select>
                </Grid>
            }
            {!viewer &&
                <>
                    <Grid item />
                    <Grid item className="text-left top-toolbar">
                        <TaxonomyEditorButtonBar/>
                    </Grid>

                </>
            }
        </Grid> */}

            {/* BLOCK FOR DISCUSSION WITH XENIA */}
            {/* Block for having toolbar aligned with taxonomy builder title */}
            {/* <Grid container alignItems="center" className="header">
            <Grid item xs={2}>
            </Grid>

            <Grid item>
            <Grid container>
                    <Select
                        variant="standard"
                        value={taxonomyEditorStore.valueMode.key}
                        onChange={e => taxonomyEditorStore.setValueMode(e.target.value as TaxonomyNodeValueMode['key'])}>
                        {taxonomyEditorStore.allTaxonomyValueModes.map(m =>
                            <MenuItem key={m.key} value={m.key}>
                                {m.label}
                            </MenuItem>
                        )}
                    </Select>
            </Grid>
        </Grid>

        <Grid>
        {!viewer &&
            <>
                <Grid item className="text-left top-toolbar" md={12} xs={9}>
                    <TaxonomyEditorButtonBar />
                </Grid>
            </>
        }
        </Grid>
        </Grid> */}

            <div className="categorization-page-content">
                {!viewer &&
                    <Grid container justifyContent="center">
                        <Grid item className="">
                            <Button
                                style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
                                disabled={!taxonomyManagerStore.canEditTaxonomy}
                                className="toolbar-btn large-icon-btn wider-button z-bg-button"
                                color="primary"
                                variant="outlined"
                                startIcon={<Add/>}
                                onClick={() => taxonomyEditorStore.setCreateCategoryMode(true)}>
                                Add L{'' + taxonomyEditorStore.focusLevel}
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid container justifyContent="center">
                    {taxonomyManagerStore.error &&
                        <Grid item><Alert severity="error">{taxonomyManagerStore.error}</Alert></Grid>
                    }
                    {taxonomyManagerStore.notification &&
                        <Grid item>
                            <Alert severity={taxonomyManagerStore.notification_type}>
                                {taxonomyManagerStore.notification}
                            </Alert>
                        </Grid>
                    }
                    {resultOfApprovalMsg &&
                        <Grid item>
                            <Alert severity={resultOfApprovalMsg.severity} action={
                                !authStore.isMithraStaff ?
                                    <>
                                        <Button color="primary"
                                                onClick={() => taxonomyManagerStore.forceSetStatus(ApprovalStatusEnum.APPROVED)}>
                                            SET approved
                                        </Button><br/>
                                        <Button color="warning"
                                                onClick={() => taxonomyManagerStore.forceSetStatus(ApprovalStatusEnum.HALTED)}>
                                            set HALTED
                                        </Button>
                                    </>
                                    : undefined
                            }>
                                {resultOfApprovalMsg.msg}
                            </Alert>
                        </Grid>
                    }
                    {taxonomyManagerStore.taxonomy &&
                        <Grid item xs={12}><TaxonomyEditor options={options}/></Grid>
                    }
                    {(!taxonomyManagerStore.taxonomy && !taxonomyManagerStore.error) &&
                        <Grid item className="loading-mid-page"><LoadingSpinnerPie/></Grid>
                    }
                </Grid>
            </div>
        </div>
    </KoiFacade>
});
