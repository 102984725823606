import {
    MatchDataType,
    Node,
    Tree,
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {TaxonomyMapperStore} from "./TaxonomyMapperStore";
import {makeAutoObservable, reaction} from "mobx";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {environment} from "../../../env";
import {
    matGetFilterType,
    MatSupplierFilter,
    SomeMatSupplierReviewRow,
    SomeMatSupplierReviewRowState
} from "../../../services/classes/MatReviewClasses";
import {ciTreeToCatsDict} from "../../../services/ApiHelpers";

export class TaxonomyMapSelectionDelegate {
    leftSelectionIndex?: number;
    rightSelectionIndex?: number;
    hasCreatedLink = false;

    // Left selection suppliers
    readonly leftSelectionSupplierPages = new PageResponseManager<MatSupplierFilter, SomeMatSupplierReviewRowState, SomeMatSupplierReviewRow>(
        environment.categorizationReviewSupplierPageSize,
        (page, f) =>
            this.api.listSupplierReview(f, page, environment.categorizationReviewSupplierPageSize, {
                field: 's_total_spend',
                desc: true
            }),
        // Set part related attributes when they are done downloading
        (data: SomeMatSupplierReviewRow, request) => {
            const filterType = matGetFilterType(request);
            return {...data as any, filter: filterType};
        }
    )

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private taxonomyMapperStore: TaxonomyMapperStore,
    ) {
        makeAutoObservable(this)
        reaction(() => this.leftSelectionData?.id, leftSelectionId => {
            // TODO: Test if this is safe to do in a 'reaction'
            if (!leftSelectionId) {
                this.leftSelectionSupplierPages.reset();
                return;
            }
            const leftNode = this.taxonomyMapperStore.baseTaxonomyHierarchy?.find(n => n.data.id === leftSelectionId);
            if (!leftNode) {
                // this.leftSelectionSupplierPages.reset();
                return;
            }
            const leftBag = this.taxonomyMapperStore.baseDataBagId;
            if (!leftBag) return;
            const filter: MatSupplierFilter = {
                databag: leftBag,
                ...ciTreeToCatsDict(leftNode),
                business_unit: undefined,
            }
            this.leftSelectionSupplierPages.init(filter);
        });
    }

    get leftSelectionNode(): Tree | undefined {
        if (this.leftSelectionIndex === undefined || !this.taxonomyMapperStore.mapper.vizPointData) return undefined;
        return this.taxonomyMapperStore.mapper.vizPointData[this.leftSelectionIndex];
    }

    get leftSelectionData(): Node | undefined {
        return this.leftSelectionNode?.data;
    }

    get rightSelectionNode(): Tree | undefined {
        if (this.rightSelectionIndex === undefined || !this.taxonomyMapperStore.mapper.vizPointData) return undefined;
        return this.taxonomyMapperStore.mapper.vizPointData[this.rightSelectionIndex];
    }

    get rightSelectionData(): Node | undefined {
        return this.rightSelectionNode?.data;
    }

    get canConnect() {
        if (this.leftSelectionIndex === undefined || this.rightSelectionIndex === undefined) {
            return false;
        }
        // noinspection RedundantIfStatementJS
        if (this.leftSelectionData?.children && this.leftSelectionData.children.length > 0) {
            // Not allowed to make connections if the left one is not a leaf
            return false;
        }
        return true;
    }

    get isConnected() {
        if (this.hasCreatedLink) {
            return true;
        }
        return Boolean(this.selectedCreatedConnection)
    }

    get selectedConnection() {
        if (!this.leftSelectionData || !this.rightSelectionData || !this.taxonomyMapperStore.mapper.vizConnectionData) {
            return undefined;
        }
        const cI = hasConnection(this.leftSelectionData, this.rightSelectionData, this.taxonomyMapperStore.mapper.vizConnectionData)
        if (cI === -1) {
            return undefined;
        }
        return this.taxonomyMapperStore.mapper.vizConnectionData[cI]
    }

    get selectedCreatedConnection() {
        const connection = this.selectedConnection
        if (!connection) return undefined;
        return Boolean(connection.map)
    }

    // Think about how to design the domain model for this store.
    onClickData(clickedNode: Tree) {
        // console.log('TaxonomyMapSelectionDelegate onClickData')
        if (!this.taxonomyMapperStore.mapper.vizPointData || !this.taxonomyMapperStore.mapper.vizConnectionData) {
            console.error('Clicked non existing data')
            return;
        }

        const oldLeftId = this.leftSelectionData?.id;
        const oldLeftIndex = this.leftSelectionIndex;
        const oldRightId = this.rightSelectionData?.id;
        const oldRightIndex = this.rightSelectionIndex;

        // On the next click, unselect the other side
        const unselectOpposite = this.hasCreatedLink;
        this.hasCreatedLink = false;

        let newLeftId = oldLeftId;
        let newLeftIndex = oldLeftIndex;
        let newRightId = oldRightId;
        let newRightIndex = oldRightIndex;
        if (clickedNode.data.values.isLeft) {
            if (oldLeftId === clickedNode.data.id) {
                // Unselect the left
                newLeftId = undefined;
                newLeftIndex = undefined;
                TaxonomyMapSelectionDelegate.setSelected(clickedNode, false);
            } else {
                // Make a new selection on the left
                if (this.leftSelectionNode) {
                    TaxonomyMapSelectionDelegate.setSelected(this.leftSelectionNode, false);
                }
                newLeftId = clickedNode.data.id;
                newLeftIndex = this.taxonomyMapperStore.mapper.vizPointData.findIndex(d => d.data.id === newLeftId && d.data.values.isLeft)
                TaxonomyMapSelectionDelegate.setSelected(clickedNode, true);
            }
            if (unselectOpposite) {
                // Unselect the right
                if (this.rightSelectionNode) {
                    TaxonomyMapSelectionDelegate.setSelected(this.rightSelectionNode, false);
                }
                newRightId = undefined;
                newRightIndex = undefined;
            }
        } else {
            if (oldRightId === clickedNode.data.id) {
                // Unselected the right
                newRightId = undefined;
                newRightIndex = undefined;
                TaxonomyMapSelectionDelegate.setSelected(clickedNode, false);
            } else {
                // Make a new selection on the right
                if (this.rightSelectionNode) {
                    TaxonomyMapSelectionDelegate.setSelected(this.rightSelectionNode, false);
                }
                newRightId = clickedNode.data.id;
                newRightIndex = this.taxonomyMapperStore.mapper.vizPointData.findIndex(d => d.data.id === newRightId && !d.data.values.isLeft)
                TaxonomyMapSelectionDelegate.setSelected(clickedNode, true);
            }
            if (unselectOpposite) {
                // Unselect the left
                if (this.leftSelectionNode) {
                    TaxonomyMapSelectionDelegate.setSelected(this.leftSelectionNode, false);
                }
                newLeftId = undefined;
                newLeftIndex = undefined;
            }
        }
        // console.log('Clicked', oldLeftId, newLeftId, ' --- ', oldRightId, newRightId);
        this.setSelectionIndex(newLeftIndex, newRightIndex);

        // Update the connections
        if (oldLeftIndex !== newLeftIndex) {
            if (oldLeftIndex !== undefined) {
                this.taxonomyMapperStore.mapper.vizPointData[oldLeftIndex].data.selected = false;
            }
            if (newLeftIndex !== undefined) {
                this.taxonomyMapperStore.mapper.vizPointData[newLeftIndex].data.selected = true;
            }
        }
        if (oldRightIndex !== newRightIndex) {
            if (oldRightIndex !== undefined)
                this.taxonomyMapperStore.mapper.vizPointData[oldRightIndex].data.selected = false;
            if (newRightIndex !== undefined)
                this.taxonomyMapperStore.mapper.vizPointData[newRightIndex].data.selected = true;
        }
        this.taxonomyMapperStore.mapper.userVizPointData = [...this.taxonomyMapperStore.mapper.vizPointData]

        if (oldLeftId !== undefined && oldRightId !== undefined) {
            const prevConnI = this.taxonomyMapperStore.mapper.vizConnectionData.findIndex(d => d.leftId === oldLeftId && d.rightId === oldRightId)
            if (prevConnI === -1) {
                // console.log('Old connection: cannot remove from view', oldLeftId, oldRightId)
            } else {
                const prevConn = this.taxonomyMapperStore.mapper.vizConnectionData[prevConnI]
                if (prevConn.map || prevConn.beingCreated) {
                    // Keep it in the view, but put as unselect only
                    const c = {...prevConn}
                    c.selected = false;

                    this.taxonomyMapperStore.mapper.vizConnectionData.splice(prevConnI, 1, c)
                } else {
                    // Remove it as it never really existsed
                    // console.log('Old connection: Remove it', prevConnI);
                    this.taxonomyMapperStore.mapper.vizConnectionData.splice(prevConnI, 1)
                }
            }
        }

        const newConnI = this.taxonomyMapperStore.mapper.vizConnectionData.findIndex(d => d.leftId === newLeftId && d.rightId === newRightId);
        let connection: MatchDataType;
        if (newConnI === -1) {
            if (newLeftId === undefined || newRightId === undefined) {
                // Not any kind of line needs to be drawn in this selection
            } else {
                // Create a virtual connection
                connection = {
                    leftId: newLeftId,
                    rightId: newRightId,
                    map: undefined,
                    selected: true,
                    beingCreated: false,
                };
                this.taxonomyMapperStore.mapper.vizConnectionData.push(connection)
            }
        } else {
            // Update the existing connection
            const prevConnection = this.taxonomyMapperStore.mapper.vizConnectionData[newConnI];
            connection = {...prevConnection}
            connection.selected = true;
            this.taxonomyMapperStore.mapper.vizConnectionData.splice(newConnI, 1, connection)
        }

        if (this.taxonomyMapperStore.autoUpdateConnectedRight) {
            // See if there was an existing link associated with the selection
            if (newLeftId !== undefined) {
                const associatedConnections = this.taxonomyMapperStore.mapper.vizConnectionData.filter(d => d.leftId === newLeftId)
                // console.log('onClickData Associated.length=', associatedConnections.length);
                if (associatedConnections.length > 0) {
                    const rightIds = associatedConnections.map(d => d.rightId).filter(n => n !== undefined) as number[];
                    const leftIds = associatedConnections.map(d => d.leftId).filter(n => n !== undefined) as number[];

                    const existingConnections = associatedConnections.filter(d => d.map);
                    const onlySelection = existingConnections.length === 0;
                    // console.log('onClickData existingConnections.length=', existingConnections.length);
                    if (onlySelection) {
                        // All the ids on the right are virtual
                        this.taxonomyMapperStore.treeController.alignNodes(leftIds, rightIds);
                    } else {
                        // Align the tree to the existing connections
                        // But also open the tree to all the virtual and existing connections

                        const existingRightIds = existingConnections
                            .map(d => d.rightId).filter(n => n !== undefined) as number[];
                        // First open the tree, then align the tree
                        this.taxonomyMapperStore.treeController.openRightTreeToNodes(new Set(rightIds));
                        this.taxonomyMapperStore.treeController.alignNodes(leftIds, existingRightIds);
                    }
                } else {
                    this.taxonomyMapperStore.treeController.openTreeCompletely(false);
                    this.taxonomyMapperStore.treeController.resetTreeAlignment();
                }
            }
        }
    }

    onClickToggleConnection() {
        if (!this.taxonomyMapperStore.mapper.vizConnectionData) return;
        if (!this.leftSelectionData || !this.rightSelectionData) {
            return;
        }

        const cI = hasConnection(this.leftSelectionData, this.rightSelectionData, this.taxonomyMapperStore.mapper.vizConnectionData);
        console.assert(cI !== -1)

        // Select a link
        const link = this.taxonomyMapperStore.mapper.vizConnectionData[cI]

        if (link.beingCreated) {
            console.warn('Not allowed to create a link whilst it is already in the pipeline', {
                left: link.leftId, right: link.rightId, map: Boolean(link.map),
            })
            return;
        }

        if (link.map) {
            this.taxonomyMapperStore.mapper.removeMapping(link);
        } else {
            // Create a new one
            this.connectedToLeft?.forEach(link => {
                this.taxonomyMapperStore.mapper.removeMapping(link);
            })
            this.taxonomyMapperStore.mapper.createMapping(link);
        }
    }

    get connectedToLeft(): MatchDataType[] | undefined {
        const left = this.leftSelectionData;
        if (!left) return undefined;
        return this.taxonomyMapperStore.mapper.vizConnectionData?.filter(d => d.leftId === left.id)
    }

    setSelectionIndex(leftSelectionIndex: number | undefined, rightSelectionIndex: number | undefined) {
        this.leftSelectionIndex = leftSelectionIndex;
        this.rightSelectionIndex = rightSelectionIndex;
        this.taxonomyMapperStore.filter.setAndClearRightSearch(this.leftSelectionData?.label || '')
    }

    private static setSelected(node: Tree, selected: boolean) {
        // const ancestors = node.ancestors().map(n => n.data.label)
        // console.log('setSelected '+selected, ancestors);
        node.data.selected = selected;
        node.ancestors().forEach(n => n.data.childSelected = selected)
    }

    clearSelection() {
        if (this.leftSelectionNode) {
            TaxonomyMapSelectionDelegate.setSelected(this.leftSelectionNode, false);
        }
        if (this.rightSelectionNode) {
            TaxonomyMapSelectionDelegate.setSelected(this.rightSelectionNode, false);
        }
        this.setSelectionIndex(undefined, undefined);
    }
}

function hasConnection(d1: Node, d2: Node, dataArray: MatchDataType[]) {
    if (d1.values.isLeft === d2.values.isLeft) throw Error();
    const [l, r] = d1.values.isLeft ? [d1, d2] : [d2, d1];
    return dataArray.findIndex(c => c.leftId === l.id && c.rightId === r.id);
}
