import {createContext, useContext} from "react";
import {buildAxiosInstance} from "../services/Http";
import MithraApi from "../services/MithraApi";
import AuthStore from "./AuthStore";
import LoadingBarStore from "./LoadingBarStore";
import DebugStore from "./DebugStore";
import {CompareBagStore} from "../pages/synergy_v1/CompareBagStore";
import {BagStore} from "./BagStore";
import {CategorizationStore} from "./CategorizationStore";
import {CategorizationReviewStore} from "./CategorizationReviewStore";
import TaxonomyEditorStore from "./TaxonomyEditorStore";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {ApprovalStore} from "./ApprovalStore";
import ProfileStore from "./ProfileStore";
import {OpportunityStore} from "./OpportunityStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {environment} from "../env";
import AuthApi from "../services/AuthApi";
import {BagLoadingStore} from "./BagLoadingStore";
import {SpendConcentrationStore} from "../jobs/koi/spend-concentration/SpendConcentrationStore";
import {TaxonomyMapperStore} from "../pages/taxonomy-mapper/store/TaxonomyMapperStore";
import {AiManagerStore} from "./AiManagerStore";
import {PpvControllerStore} from "../jobs/koi/purchase-price-variance/PpvControllerStore";
import {SupplierNormalizationStore} from "./SupplierNormalizationStore";
import {RootAppStore} from "./RootAppStore";
import MithraDataIngestionApi from "../services/MithraDataIngestionApi";
import DataIngestionStore from "./DataIngestionStore";
import TaxonomySuggestorStore from "./TaxonomySuggestorStore";
import {SynergyStore} from "./SynergyStore";

const p = new ProfileStore();
const rootAppStore = new RootAppStore();
const api = new MithraApi(buildAxiosInstance(environment.apiUrl));
const materializedApi = new MithraMaterializedApi(buildAxiosInstance(environment.materializedApiUrl));
const dataIngestionApi = new MithraDataIngestionApi(buildAxiosInstance(environment.apiDataIngestionUrl));
const authApi = new AuthApi(materializedApi.http);
const bagStore = new BagStore(materializedApi, p);
const authStore = new AuthStore(authApi, p);
authStore.authentication.wrapAuthorized(api.http, materializedApi.http);
const loadingBarStore = new LoadingBarStore();
const cmpStore = new CompareBagStore(materializedApi, p);
const bagLoadingStore = new BagLoadingStore(materializedApi, bagStore, loadingBarStore, authStore);
const categorizationStore = new CategorizationStore(api, authStore, bagStore, materializedApi, p);
const approvalStore = new ApprovalStore(materializedApi, authStore, bagStore, p);
const categorizationReviewStore = new CategorizationReviewStore(p, materializedApi, bagStore, approvalStore, categorizationStore, authStore);
const taxonomyManagerStore = new TaxonomyManagerStore(materializedApi, bagStore, approvalStore, authStore, p);
const taxonomyEditorStore = new TaxonomyEditorStore(taxonomyManagerStore, p);
const taxonomySuggestorStore = new TaxonomySuggestorStore(p, materializedApi, bagStore, authStore, taxonomyManagerStore, p);
const opportunityStore = new OpportunityStore(p, api, bagStore);
const spendConcentrationStore = new SpendConcentrationStore(materializedApi, bagStore, p);
const taxonomyMapperStore = new TaxonomyMapperStore(materializedApi, authStore, p, bagStore, taxonomyManagerStore);
const aiManagerStore = new AiManagerStore(materializedApi, p, bagStore, categorizationStore, taxonomyMapperStore);
const ppvControllerStore = new PpvControllerStore(materializedApi, bagStore, p);
const supplierNormalizationStore = new SupplierNormalizationStore(materializedApi, bagStore, p);
const dataIngestionStore = new DataIngestionStore(materializedApi, dataIngestionApi, bagStore, authStore, p);
const masterSupplierNormalizationStore = new SupplierNormalizationStore(materializedApi, bagStore, p);
/**
 * @deprecated TODO: Find a way to properly switch between data in the stores, (on pages and in combined dashboards)
 */
const baseSupplierNormalizationStore = new SupplierNormalizationStore(materializedApi, bagStore, p);
const synergyStore = new SynergyStore(bagStore, p, materializedApi);
export const stores = {
    rootAppStore,
    bagStore,
    synergyStore,
    cmpStore,
    authStore,
    loadingBarStore,
    bagLoadingStore,
    categorizationStore,
    categorizationReviewStore,
    api,
    materializedApi,
    dataIngestionApi: dataIngestionApi,
    taxonomyManagerStore,
    taxonomyEditorStore,
    taxonomySuggestorStore,
    approvalStore,
    p,
    opportunityStore,
    spendConcentrationStore,
    taxonomyMapperStore,
    aiManagerStore,
    ppvControllerStore,
    supplierNormalizationStore,
    masterSupplierNormalizationStore,
    baseSupplierNormalizationStore,
    dataIngestionStore: dataIngestionStore,
    debugStore: new DebugStore(api),
};

/**
 * @see https://mobx.js.org/defining-data-stores.html#example-domain-store
 * Subclassing is only supported limited by MobX, so we prefer composition to inheritance for Stores
 */
export type Stores = typeof stores;

/**
 * Custom defined function to get all stores of this app
 * @see https://mobx.js.org/defining-data-stores.html
 */
export const useStores: () => Stores = () => useContext(createContext(stores))
