import {Route} from 'react-router-dom';
import {AuthRoute, RouteSpec} from "./AuthRoute";
import React from "react";
import {NotFound} from "./NotFound";
import {LoginPage} from "../auth/LoginPage";
import {SimpleEnvWrapper} from "../jobs/wrapper/SimpleEnvWrapper";
import {SpendConcentration} from "../jobs/koi/spend-concentration/SpendConcentration";
import {KOIPurchasePriceVariance} from "../jobs/koi/purchase-price-variance/KOIPurchasePriceVariance";
import {MainComponent} from "../components/main/MainComponent";
import {WrappedRoute} from "./WrappedRoute";
import {KoiKeyDataFacts} from "../jobs/koi/key-data-facts/KoiKeyDataFacts";
import {KoiSupplierHierarchy} from "../jobs/koi/supplier-hierarchy/KoiSupplierHierarchy";
import {ManageDataPage} from "../pages/manage-data/ManageDataPage";
import {KOICashFlowOptimization} from "../jobs/koi/koi-cash-flow-optimization/KOICashFlowOptimization";
import {KOISupplierSegmentation} from "../jobs/koi/koi-supplier-segmentation/KOISupplierSegmentation";
import {SynergySelectionPage} from "../pages/synergy_v1/selection/SynergySelectionPage";
import {SynergyDashboardPage} from "../pages/synergy_v1/dashboard/SynergyDashboardPage";
import {SynergyOverviewPage} from "../pages/synergy_v1/SynergyOverviewPage";
import {routes} from './routes';
import {SynergySuppliersPage} from "../pages/synergy_v1/suppliers/SynergySuppliersPage";
import {SynergyCategoriesPage} from "../pages/synergy_v1/categories/SynergyCategoriesPage";
import {DoubleEnvWrapper} from "../jobs/wrapper/DoubleEnvWrapper";
import {OpportunitiesTrello} from "../pages/opportunities-trello/OpportunitiesTrello";
import {DashBoard} from '../pages/dashboard/Dashboard';
import {TaxonomyEditorPage} from "../pages/taxonomy-editor/TaxonomyEditorPage";
import {ApprovalListPage} from "../pages/approval/ApprovalListPage";
import {ApprovalCategorizationPage} from "../pages/approval/categorization/ApprovalCategorizationPage";
import {ApprovalTaxonomyPage} from "../pages/approval/taxonomy/ApprovalTaxonomyPage";
import {TaxonomyMapperPage} from "../pages/taxonomy-mapper/TaxonomyMapperPage";
import {TaxonomyMapperReviewPage} from "../pages/taxonomy-mapper-review/TaxonomyMapperReviewPage";
import {AiCategorizationPage} from "../pages/ai-categorization/AiCategorizationPage";
import {TaxonomyEditorViewOnlyPage} from "../pages/taxonomy-editor/TaxonomyEditorViewOnlyPage";
import {ReportingPage} from "../pages/reporting/ReportingPage";
import {CategorizationPage} from '../pages/categorization/CategorizationPage';
import {SingleCleaningDashboard} from "../pages/multi-dashboard/SingleCleaningDashboard";
import {SingleAnalyticsDashboard} from "../pages/multi-dashboard/SingleAnalyticsDashboard";
import {SynergyAnalyticsDashboard} from "../pages/multi-dashboard/SynergyAnalyticsDashboard";
import {SynergyMergeDashboard} from "../pages/multi-dashboard/SynergyMergeDashboard";
import {MultiEnvWrapper} from "../jobs/wrapper/MultiEnvWrapper";
import {ManageMasterDataPage} from "../pages/multi-dashboard/data/ManageMasterDataPage";
import {SupplierNormalizationPage} from "../pages/supplier-normalization/SupplierNormalizationPage";
import {DatasetOverviewPage} from '../pages/data-management/DatasetOverviewPage';
import {DataUploadPage} from '../pages/data-management/DataUploadPage';
import {Merge1EnvWrapper} from "../jobs/wrapper/Merge1EnvWrapper";
import {Merge1Dashboard} from "../pages/multi-dashboard/nestle-dashboard/Merge1Dashboard";
import {LookerPreviewPage} from "../pages/looker-preview/LookerPreviewPage";
import {DataIngestionWrapper} from "../jobs/wrapper/DataIngestionWrapper";
import {DataMappingPage} from "../pages/data-management/DataMappingPage";
import {environment} from "../env";
import {CleanupDashboard} from "../pages/cleanup-dashboard/CleanupDashboard";
import {MergeXDashboard} from "../pages/multi-dashboard/merge-x-dashboard/MergeXDashboard";
import {MergeXEnvWrapper} from '../jobs/wrapper/MergeXEnvWrapper';
import {TaxonomySuggestorPage} from "../pages/taxonomy-suggestor/TaxonomySuggestorPage";
import {C} from '../configurations/active-configuration';
import {Merge2Dashboard} from "../pages/multi-dashboard/lg-dashboard/Merge2Dashboard";
import {Merge2EnvWrapper} from "../jobs/wrapper/Merge2EnvWrapper";
import {MinimalDashboard} from "../pages/dashboard/MinimalDashboard";
import {CommonSuppliersPage} from "../pages/common-suppliers/CommonSuppliersPage";

// The different variants of wrappers
const noD: RouteSpec = [SimpleEnvWrapper, {fluid: true, dashEnv: 'simple', dataType: 'no_data'}] // No data wrapper
const sim: RouteSpec = [SimpleEnvWrapper, {fluid: true, dashEnv: 'simple'}]
const duo: RouteSpec = [DoubleEnvWrapper, {fluid: true, dashEnv: 'dual'}]
const ms1: RouteSpec = [MultiEnvWrapper, {fluid: true, dashEnv: 'multi', dashType: 'single', dataType: 'default'}]
const msy: RouteSpec = [MultiEnvWrapper, {fluid: true, dashEnv: 'multi', dashType: 'synergy', dataType: 'default'}]
const dwmNoData: RouteSpec = [DataIngestionWrapper, {fluid: true, dataType: 'no_data',}]
const dwmData: RouteSpec = [DataIngestionWrapper, {fluid: true,}]

const m1w: RouteSpec = [Merge1EnvWrapper, {fluid: true}]
// LG
const m2C: RouteSpec = [Merge2EnvWrapper, {fluid: true, dashboardEnvironment: 'cleanup'}]
const m2A: RouteSpec = [Merge2EnvWrapper, {fluid: true, dashboardEnvironment: 'analytics'}]
const m2M: RouteSpec = [Merge2EnvWrapper, {fluid: true, dashboardEnvironment: 'merge'}] // Note: SYNERGY = MERGE
// SHV
const mxC: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'cleanup'}]
const mxA: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'analytics'}]
const mxM: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge'}] // Note: SYNERGY = MERGE

// For SHV multi subsidiary environment
const mxM1N: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub1_new'}]
const mxM1O: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub1_old'}]
const mxM2N: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub2_new'}]
const mxM2O: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub2_old'}]
const mxM3N: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub3_new'}]
const mxM3O: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'sub3_old'}]

// TODO: These will go to cleanup
const mMNO: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge', dataType: 'old_new'}]
const mM1: RouteSpec = [MergeXEnvWrapper, {fluid: true, dashboardEnvironment: 'merge'}]

// Shortcuts to save space
const r = routes;
const R = AuthRoute;
let _k = 0;

function k() {
    return _k++;
}

// Matches the routes (top to bottom)
const routeSpecs: (JSX.Element | undefined)[] = [

    // Homepage
    environment.package === 'merge_1'
        ? <R key={k()} w={m1w} path={r.home} component={Merge1Dashboard} exact/>
        : <R key={k()} w={noD} path={r.home} component={ManageDataPage} notStrict exact/>,

].concat(environment.package === 'merge_1' ? [
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Multi Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    <R key={k()} w={m1w} path={r.merge_1_supplier_normalization_master} component={SupplierNormalizationPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_client_categorization_review} component={AiCategorizationPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_supplier_normalization} component={SupplierNormalizationPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_common_suppliers} component={CommonSuppliersPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_client_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_client_taxonomy_builder} component={TaxonomyEditorPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_common_categories_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={m1w} path={r.merge_1_common_categories} component={TaxonomyMapperPage}/>,
] : environment.package === 'cleanup' ? [
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Cleanup dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    <R key={k()} w={sim} path={r.cleanup_dashboard} component={CleanupDashboard}/>,
    <R key={k()} w={sim} path={r.supplier_normalization} component={SupplierNormalizationPage}/>,
    <R key={k()} w={sim} path={r.categorization_review} component={CategorizationPage}/>,
    <R key={k()} w={sim} path={r.taxonomy_builder} component={TaxonomyEditorPage}/>,
    <R key={k()} w={sim} path={r.taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={sim} path={r.koi_spend_concentration} component={SpendConcentration}/>,

    // For testing:
    <R key={k()} w={sim} path={r.job_old_dashboard} component={DashBoard}/>,

    <R key={k()} w={sim} path={r.data_manage} component={ManageDataPage}/>,
    <R key={k()} w={noD} path={r.data_home} component={ManageDataPage}/>,
] : environment.package === 'merge_2' ? ([
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Merge 2 Dashboard (Used for Liberty Global)
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <R key={k()} w={mM1} path={r.merge_2_master_dashboard} component={Merge2Dashboard}/>,

        // By default, go to the Cleanup dashboard
        <R key={k()} w={m2C} path={r.merge_2_dashboard} component={Merge2Dashboard}/>,

        <R key={k()} w={m2M} path={r.merge_2_merge_taxonomy_mapper_baseline} component={TaxonomyMapperPage}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_taxonomy_mapper_target} component={TaxonomyMapperPage}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_categorization} component={CategorizationPage}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_common_categories} component={TaxonomyMapperPage}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_spend} component={SynergyDashboardPage}
           componentProps={{noLink: true}}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_dashboard} component={Merge2Dashboard}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_synergy_overview} component={SynergyOverviewPage}/>,

        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>, // Also in extended
        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,  // Also in extended
        <R key={k()} w={m2C} path={r.merge_2_cleanup_supplier_normalization} component={SupplierNormalizationPage}/>,
        <R key={k()} w={m2C} path={r.merge_2_cleanup_categorization} component={CategorizationPage}/>,
        <R key={k()} w={m2C} path={r.merge_2_cleanup_dashboard} component={Merge2Dashboard}/>,

        <R key={k()} w={m2A} path={r.merge_2_analytics_supplier_segmentation} component={KOISupplierSegmentation}/>,
        <R key={k()} w={m2A} path={r.merge_2_analytics_ppv} component={KOIPurchasePriceVariance}/>,
        <R key={k()} w={m2A} path={r.merge_2_analytics_spend_concentration} component={SpendConcentration}/>,
        <R key={k()} w={m2A} path={r.merge_2_analytics_explore_categories} component={KoiKeyDataFacts}/>,
        <R key={k()} w={m2A} path={r.merge_2_analytics_dashboard} component={Merge2Dashboard}/>,

        <R key={k()} w={m2M} path={r.merge_2_merge_common_suppliers} component={CommonSuppliersPage}/>,

        <R key={k()} w={mxM1O} path={r.merge_2_merge_taxonomy_mapper_baseline_1} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM1N} path={r.merge_2_merge_taxonomy_mapper_target_1} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM2O} path={r.merge_2_merge_taxonomy_mapper_baseline_2} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM2N} path={r.merge_2_merge_taxonomy_mapper_target_2} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM3O} path={r.merge_2_merge_taxonomy_mapper_baseline_3} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM3N} path={r.merge_2_merge_taxonomy_mapper_target_3} component={TaxonomyMapperPage}/>,
        <R key={k()} w={m2M} path={r.merge_2_merge_dashboard} component={Merge2Dashboard}/>,

        <R key={k()} w={mMNO} path={r.merge_2_master_taxonomy_compare_baseline} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_builder} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_compare_un} component={TaxonomyEditorPage}/>,
    ].concat((environment.dashboardVariant === 'simple' || environment.dashboardVariant === '?') ? [
        // Smaller version of the Master Page in the dashboard
        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer_id} component={TaxonomyEditorPage}/>,
        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer} component={TaxonomyEditorPage}/>,
    ] : environment.dashboardVariant === 'expanded' ? [
        // Expanded version of the Master Page in the dashboard
        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={m2C} path={r.merge_2_cleanup_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_viewer_baseline} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_builder_target} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_compare_un} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_2_master_taxonomy_compare_baseline} component={TaxonomyEditorPage}/>,

    ] : []))
    : environment.package === 'merge_x' ? ([
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Merge X Dashboard
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_supplier_normalization} component={SupplierNormalizationPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_categorization} component={CategorizationPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_dashboard} component={MergeXDashboard}/>,

        <R key={k()} w={mxA} path={r.merge_x_analytics_supplier_segmentation} component={KOISupplierSegmentation}/>,
        <R key={k()} w={mxA} path={r.merge_x_analytics_ppv} component={KOIPurchasePriceVariance}/>,
        <R key={k()} w={mxA} path={r.merge_x_analytics_spend_concentration} component={SpendConcentration}/>,
        <R key={k()} w={mxA} path={r.merge_x_analytics_explore_categories} component={KoiKeyDataFacts}/>,
        <R key={k()} w={mxA} path={r.merge_x_analytics_dashboard} component={MergeXDashboard}/>,

        <R key={k()} w={mxM} path={r.merge_x_merge_common_suppliers} component={CommonSuppliersPage}/>,

        <R key={k()} w={mxM1O} path={r.merge_x_merge_taxonomy_mapper_baseline_1} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM1N} path={r.merge_x_merge_taxonomy_mapper_target_1} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM2O} path={r.merge_x_merge_taxonomy_mapper_baseline_2} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM2N} path={r.merge_x_merge_taxonomy_mapper_target_2} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM3O} path={r.merge_x_merge_taxonomy_mapper_baseline_3} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM3N} path={r.merge_x_merge_taxonomy_mapper_target_3} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mxM} path={r.merge_x_merge_categorization} component={CategorizationPage}/>,
        <R key={k()} w={mxM} path={r.merge_x_merge_dashboard} component={MergeXDashboard}/>,

        <R key={k()} w={mMNO} path={r.merge_x_master_taxonomy_compare_baseline} component={TaxonomyMapperPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_builder} component={TaxonomyEditorPage}/>,
        // <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_compare_un} component={TaxonomyEditorPage}/>,

        <R key={k()} w={mM1} path={r.merge_x_master_dashboard} component={MergeXDashboard}/>,

        <R key={k()} w={mxM} path={r.merge_x_taxonomy_suggestor} component={TaxonomySuggestorPage}/>,
        <R key={k()} w={mxM} path={r.merge_x_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mxM} path={r.merge_x_taxonomy_builder} component={TaxonomyEditorPage}/>,

        // Looker Preview Page (for SHV)
        <R key={k()} w={MainComponent} path={r.merge_x_master_looker_opp_old} component={LookerPreviewPage}
           componentProps={{lookerUrl: C.profile.lookerUrls?.at(5) || '',}}/>,
        <R key={k()} w={MainComponent} path={r.merge_x_master_looker_opp_new} component={LookerPreviewPage}
           componentProps={{lookerUrl: C.profile.lookerUrls?.at(4) || ''}}/>,
        <R key={k()} w={MainComponent} path={r.merge_x_master_looker_adv_all} component={LookerPreviewPage}
           componentProps={{lookerUrl: C.profile.lookerUrls?.at(0) || ''}}/>,
        <R key={k()} w={MainComponent} path={r.merge_x_master_looker_subsidiary_dashboard} component={LookerPreviewPage}
           componentProps={{
               subsidiaryLookerUrls: C.profile.lookerUrls ? [
                   C.profile.lookerUrls.at(1) || '',
                   C.profile.lookerUrls.at(3) || '',
                   C.profile.lookerUrls.at(2) || '',
               ] : undefined
           }}/>,

        // By default, go to the Cleanup dashboard
        <R key={k()} w={mxC} path={r.merge_x_dashboard} component={MergeXDashboard}/>,
    ].concat((environment.dashboardVariant === 'simple' || environment.dashboardVariant === '?') ? [
        // Smaller version of the Master Page in the dashboard
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer_id} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_builder_target} component={TaxonomyEditorPage}/>,
    ] : environment.dashboardVariant === 'expanded' ? [
        // Expanded version of the Master Page in the dashboard
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mxC} path={r.merge_x_cleanup_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_viewer_baseline} component={TaxonomyEditorViewOnlyPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_builder_target} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_compare_un} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_taxonomy_compare_baseline} component={TaxonomyEditorPage}/>,
        <R key={k()} w={mM1} path={r.merge_x_master_dashboard} component={MergeXDashboard}/>,
    ] : [])) : (environment.dashboardVariant === 'minimal' ? [
        <R key={k()} w={sim} path={r.job_dashboard} component={MinimalDashboard}/>, // Takes precedence over DashBoard
    ] : []).concat([
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Default fallback Dashboard
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        <R key={k()} w={sim} path={r.job_dashboard} component={DashBoard}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_dashboard} component={SingleCleaningDashboard}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_sup_nor} component={SupplierNormalizationPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_cat_welcome} component={CategorizationPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_cat_busy} component={CategorizationPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_cat_review} component={CategorizationPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_cat_result} component={CategorizationPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_taxonomy_builder} component={TaxonomyEditorPage}/>,
        <R key={k()} w={ms1} path={r.single_cleansing_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
        <R key={k()} w={ms1} path={r.single_data_manage} component={ManageDataPage}/>,
        <R key={k()} w={noD} path={r.single_data_home2} component={ManageDataPage}/>,
        <R key={k()} w={noD} path={r.single_data_home} component={ManageDataPage}/>,
    ])).concat([
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Shared routes
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Multi Dashboard
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // single_cleansing
    // N/A
    // single_analytics_dashboard
    <R key={k()} w={ms1} path={r.single_analytics_dashboard} component={SingleAnalyticsDashboard}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_ss} component={KOISupplierSegmentation}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_kdf} component={KoiKeyDataFacts}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_ppv} component={KOIPurchasePriceVariance}/>,
    <R key={k()} w={ms1} path={r.single_analytics_koi_sc} component={SpendConcentration}/>,
    // KOICashFlowOptimization
    // KoiSupplierHierarchy

    // Looker Preview Page (Deprecated)
    <R key={k()} w={ms1} path={r.single_looker_preview} component={LookerPreviewPage}/>,
    //
    // single dashboard rest
    <R key={k()} w={msy} path={r.single_data_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.single_data_opportunities_id} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.single_data_report} component={ReportingPage}/>,
    // synergy_v3_dashboard
    <R key={k()} w={msy} path={r.synergy_v3_suppliers} component={SynergySuppliersPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_categories} component={SynergyCategoriesPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_spend} component={SynergyDashboardPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_data} component={ManageMasterDataPage}/>,
    <R key={k()} w={msy} path={r.synergy_v3_dashboard} component={SynergyAnalyticsDashboard}/>,
    // synergy_merge_dashboard
    <R key={k()} w={msy} path={r.synergy_merge_review} component={AiCategorizationPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_review_result} component={AiCategorizationPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_builder} component={TaxonomyEditorPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    // synergy_v3 rest
    <R key={k()} w={msy} path={r.synergy_v3_opportunities_id} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.synergy_v3_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={msy} path={r.synergy_v3_report} component={ReportingPage}/>,
    <R key={k()} w={msy} path={r.synergy_merge_dashboard} component={SynergyMergeDashboard}/>,
    // Must be at the end
    <R key={k()} w={msy} path={r.synergy_v3} component={ManageDataPage}/>,
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // App logic
    <R key={k()} w={sim} path={r.job_koi_sc} component={SpendConcentration}/>,
    <R key={k()} w={sim} path={r.job_koi_cfo} component={KOICashFlowOptimization}/>,
    <R key={k()} w={sim} path={r.job_koi_ss} component={KOISupplierSegmentation}/>,
    <R key={k()} w={sim} path={r.job_koi_ppv} component={KOIPurchasePriceVariance}/>,
    <R key={k()} w={sim} path={r.job_koi_kdf} component={KoiKeyDataFacts}/>,
    <R key={k()} w={sim} path={r.job_koi_sh} component={KoiSupplierHierarchy}/>,
    <R key={k()} w={sim} path={r.job_supplier_normalization} component={SupplierNormalizationPage}/>,

    // New user flow
    <R key={k()} w={sim} path={r.job_manage} component={ManageDataPage}/>,
    <R key={k()} w={sim} path={r.job_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={sim} path={r.job_report} component={ReportingPage}/>,

    <R key={k()} w={duo} path={r.job_ai_cat_welcome} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_busy} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_review} component={CategorizationPage}/>,
    <R key={k()} w={duo} path={r.job_ai_cat_result} component={CategorizationPage}/>,


    <R key={k()} w={duo} path={r.job_synergy_v1_suppliers} component={SynergySuppliersPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_categories} component={SynergyCategoriesPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_dashboard} component={SynergyDashboardPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_opportunities} component={OpportunitiesTrello}/>,

    <R key={k()} w={sim} path={r.job_taxonomy_suggestor} component={TaxonomySuggestorPage}/>,
    <R key={k()} w={sim} path={r.job_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={sim} path={r.job_taxonomy_builder} component={TaxonomyEditorPage}/>,

    <R key={k()} w={duo} path={r.synergy_v2_dashboard} component={SynergyMergeDashboard}/>,
    <R key={k()} w={duo} path={r.synergy_v2_review} component={AiCategorizationPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_review_result} component={AiCategorizationPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_viewer} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_viewer_id} component={TaxonomyEditorViewOnlyPage}/>,
    <R key={k()} w={sim} path={r.synergy_v2_taxonomy_builder_id} component={TaxonomyEditorPage}/>,
    <R key={k()} w={duo} path={r.synergy_v2_opportunities} component={OpportunitiesTrello}/>,
    <R key={k()} w={noD} path={r.synergy_v2_data} component={ManageDataPage}/>,
    <R key={k()} w={noD} path={r.synergy_v2} component={ManageDataPage}/>,

    <R key={k()} w={noD} path={r.approval_cat_detail} component={ApprovalCategorizationPage}/>,
    <R key={k()} w={noD} path={r.approval_tax_detail} component={ApprovalTaxonomyPage}/>,
    <R key={k()} w={noD} path={r.approval} component={ApprovalListPage}/>,

    // Catch for incomplete synergy views (must be at the end)
    <R key={k()} w={noD} path={r.job_synergy_v1_1} component={SynergySelectionPage}/>,
    <R key={k()} w={duo} path={r.job_synergy_v1_2} component={SynergySelectionPage}/>,
    <R key={k()} w={noD} path={r.job_synergy_v1_0} component={SynergySelectionPage}/>,

    <R key={k()} w={sim} path={r.taxonomy_mapper_review} component={TaxonomyMapperReviewPage}/>,
    <R key={k()} w={sim} path={r.taxonomy_mapper} component={TaxonomyMapperPage}/>,

    // Looker Preview Page
    <R key={k()} w={MainComponent} path={r.looker_preview_id} component={LookerPreviewPage}/>,
    <R key={k()} w={MainComponent} path={r.looker_preview} component={LookerPreviewPage}/>,

    <R key={k()} w={dwmNoData} path={r.data_ingestion} component={DatasetOverviewPage}/>,
    <R key={k()} w={dwmData} path={r.data_upload_mapping} component={DataMappingPage}/>,
    <R key={k()} w={dwmNoData} path={r.data_upload} component={DataUploadPage}/>,


    // Authentication
    <Route key={k()} exact path={r.login} component={LoginPage}/>,

    // Last catch is a 404
    <WrappedRoute key={k()} w={MainComponent} component={NotFound}/>,
])

export const activeRoutes: JSX.Element[] = routeSpecs.filter(r => r) as JSX.Element[]
