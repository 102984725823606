import {makeAutoObservable} from "mobx";

/**
 * TODO: CAT-712
 * @deprecated
 */
export type DashboardEnvironment =
    'simple'
    | 'dual'
    | 'multi'
    | 'nestle' // TODO: CAT-712

/**
 * @deprecated TODO: CAT-712 Remove old dashboard switching mechanism
 */
export type DashboardType = 'single' | 'synergy';

/**
 * The type of dataset that is loaded
 */
export type DatasetType = 'standalone' | 'master' | 'subsidiary' | 'no_data'
    | 'sub1_new' | 'sub2_new' | 'sub3_new'
    | 'sub1_old' | 'sub2_old' | 'sub3_old'
    | 'old_new';

/**
 * The type of dashboard that can be shown
 */
export type DashboardEnvironmentV3 = 'analytics' | 'cleanup' | 'merge';

/**
 * Props that are passed to the route wrapper component
 */
export type AppState = {
    fluid: boolean
    /**
     * The versioning of the dashboard
     * @deprecated
     */
    dashEnv?: DashboardEnvironment
    /**
     * The type of dataset that is loaded
     * @deprecated Do not use 'default' or 'combined' anymore
     */
    dataType?: DatasetType | 'default' | 'combined'
    /**
     * The type of styling that is used in the page
     * @deprecated TODO: CAT-712 Remove old dashboard switching mechanism
     */
    dashType?: DashboardType
    /**
     * The environment of the dashboard that is being shown
     */
    dashboardEnvironment?: DashboardEnvironmentV3
}
export const DEFAULT_APP_STATE: AppState = {
    fluid: true,
}


export class RootAppStore {
    get app(): AppState {
        return this.routeApp || this.defaultApp;
    }

    routeApp: AppState | undefined;
    defaultApp: AppState;

    constructor() {
        makeAutoObservable(this);
        this.defaultApp = DEFAULT_APP_STATE;
    }

    setApp(app: AppState) {
        this.routeApp = app;
    }

    setDashboardEnvironment(dashboardEnvironment: DashboardEnvironmentV3) {
        this.routeApp = {
            ...this.app,
            dashboardEnvironment,
        }
    }
}
