import {makeAutoObservable, reaction} from "mobx";
import MithraApi from "../services/MithraApi";
import {CurrencyAbbreviation} from "../components/currency-component/CurrencyComponent";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import AuthStore from "./AuthStore";
import {BagStore} from "./BagStore";
import {CategorizationReviewDataDelegate} from "./categorization/CategorizationReviewDataDelegate";

type Page = 'model_selection' // or 'welcome'
    | 'model_busy'
    | 'review'
    | 'result'

export class CategorizationStore {
    readonly data = new CategorizationReviewDataDelegate(this.matApi, this.auth, this.profile)

    step = 0;
    page: Page = 'model_selection';
    desiredBagId: number | undefined;
    desiredTaxonomySize: number | undefined;
    categorizationKpi: number | undefined;

    constructor(
        private api: MithraApi,
        private auth: AuthStore,
        private bagStore: BagStore,
        private matApi: MithraMaterializedApi,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        /**
         * @deprecated this needs a more stable interface, it's too hacky
         */
        reaction(() => [this.bagId, this.taxonomySize] as const, ([bagId, taxonomySize]) => {
            console.log('CategorizationStore Triggering change of bagId', bagId);
            if (bagId && taxonomySize) {
                this.data.setRequestBagId(bagId)
                this.reInitialize(bagId, taxonomySize)
            }
        });
        reaction(() => [this.bagId, this.taxonomySize] as const, ([bagId, taxonomySize]) => {
            if (bagId && taxonomySize) {
                this.reInitialize(bagId, taxonomySize)
            }
        })
        reaction(() => [this.data.requestedBagId, this.bagStore.bu.selectedBusinessUnitId] as const, ([, businessUnitId]) => {
            this.data.filterManager.setFilteredBusinessUnitId(businessUnitId)
        })
    }

    get bagId() {
        return this.desiredBagId || this.bagStore.bag?.id || 0;
    }

    get taxonomySize() {
        return this.desiredTaxonomySize || this.bagStore.taxonomy_size || 0;
    }

    /**
     @deprecated this needs a more stable interface, it's too hacky
     */
    reInitialize(bagId: number, taxonomySize: number) {
        console.debug('CategorizationStore.reInitialize', {bagId, taxonomySize});
        this.step = 0;
        this.page = 'model_selection';
        this.data.reInitialize(bagId, taxonomySize);
        // this.backToStart()
    }

    /**
     * @deprecated this needs a more stable interface, it's too hacky
     */
    reInitializeV2(bagId: number, taxonomySize: number) {
        console.debug('CategorizationStore.reInitializeV2', {bagId, taxonomySize});
        return this.data.reInitializeV2(bagId, taxonomySize);
    }

    /**
     * @deprecated this needs a more stable interface, it's too hacky
     */
    setDesiredBag(bagId: number, taxonomySize: number) {
        this.desiredBagId = bagId;
        this.desiredTaxonomySize = taxonomySize;
    }

    /**
     * TODO: CAT-712
     * @deprecated from vion-release
     */
    backToStart() {
        this.step = 0
        this.page = 'model_selection'
    }

    /**
     * TODO: CAT-712
     * @deprecated from vion-release
     */
    navigateToStart() {
        this.step = 0
        this.page = 'model_selection'
        this.data.filterManager.selectedCategory = []
    }

    /**
     * TODO: CAT-712
     * @deprecated from vion-release
     */
    showReview() {
        this.step = 1
        this.page = 'review'
    }

    /**
     * TODO: CAT-712
     * @deprecated from vion-release
     */
    showResult() {
        this.step = 2
        this.page = 'result'
    }

    navigateToPage(page: Page) {
        switch (page) {
            case "model_selection":
                this.step = 0;
                this.data.filterManager.selectedCategory = [];
                break;
            case "model_busy":
                this.step = 1;
                this.data.filterManager.selectedCategory = [];
                break;
            case "review":
                this.step = 2;
                break;
            case "result":
                this.step = 3;
                break;
        }
        this.page = page;
    }
}

export type SummaryKeyValues = {
    abbreviation: CurrencyAbbreviation,
    after: {
        classified_spend: number, // total - unclassified
        reclassified_spend: number,
        unclassified_spend: number,
    }
}
