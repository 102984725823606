import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../../../components/icons/ClassificationHierarchyIcon";
import {SupplierSegmentationIcon} from "../../../../components/icons/SupplierSegmentationIcon";
import {MoveIcon} from "../../../../components/icons/MoveIcon";
import {ArrowCollapsedIcon} from "../../../../components/icons/ArrowCollapsedIcon";
import {environment} from "../../../../env";
import {Bag} from "../../../../services/classes/Bag";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const MergeXAnalyticsDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        spendConcentrationStore,
        ppvControllerStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
        supplierNormalizationStore
    } = useStores();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const scTop = spendConcentrationStore.topValueP;

    // Temporary fix for CAT-824
    // TODO: CAT-888
    let isLoadingHack824 = false;
    let numberOfSuppliers = bagStore.kpi.common?.n_suppliers;
    if (p.p.hackNSuppliersFromCommonSuppliers) {
        isLoadingHack824 = supplierNormalizationStore._postStatsRequestManager.busy;
        numberOfSuppliers = supplierNormalizationStore.postKeyValues?.total_suppliers_count;
    }

    const ppvTotal = ppvControllerStore.spendTotalPPV;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;


    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>MergeXAnalyticsDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard analytics-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                // Temporary fix for CAT-824
                // TODO: CAT-888
                subtitle={numberOfSuppliers && !isLoadingHack824
                    ? String(numberOfSuppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                {/* subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}> */}
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.merge_x_analytics_supplier_segmentation, {id: bagId})}
                    isTrial={true}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common && !isLoadingHack824
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.merge_x_analytics_explore_categories, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common && !isLoadingHack824
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(ppvTotal)}
                    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.merge_x_analytics_ppv, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.merge_x_analytics_spend_concentration, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
