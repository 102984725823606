import React from "react";
import './SynergyDashboardPage.scss';
import {ButtonBase, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useStores} from "../../../stores";
import {hardcoded_dpw} from "../hardcoded/hardcoded_dpw";
import {
    CmpBreakdownSingleVisualization
} from "../../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {
    cmpVizCondensedOptionsLeft,
    cmpVizCondensedOptionsRight,
    cmpVizTabletCondensedOptionsLeft,
    cmpVizTabletCondensedOptionsRight
} from "../synergyTypes";
import {AnnotatedValueComponent} from "../../../components/annotated-value-component/AnnotatedValueComponent";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {generatePath, useHistory} from "react-router";
import {CmpDataBagRouteMatch, routes} from "../../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {KeyboardArrowRight} from "@mui/icons-material";

type Props = {
    noLink?: boolean
}
export const SynergyDashboardPage: React.FC<Props> = ({noLink}) => {
    const {cmpStore} = useStores();
    const theme = useTheme();
    const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    const supplierBreakdownData = cmpStore.supplierCompareBreakdown;

    const categoryCompareData = cmpStore.cmpCat.categoryTotalCompareData;
    const categoryCompareBreakdownData = cmpStore.cmpCat.categoryTotalCompareBreakdown;


    const optionsLeft = mediaTablet ? cmpVizTabletCondensedOptionsLeft : cmpVizCondensedOptionsLeft;
    const optionsRight = mediaTablet ? cmpVizTabletCondensedOptionsRight : cmpVizCondensedOptionsRight;

    return <main className="synergy-dashboard-page">
        <Grid container justifyContent="center" className="page-header">
            <Grid item>
                <Typography variant="h2" component="h1" className="title">
                    Synergies Dashboard
                </Typography>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" className="dashboard-header">
            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {cmpStore.getMasterBagName()}
                </Typography>
            </Grid>

            <Grid item lg={3} md={2}/>

            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {cmpStore.getCmpBagName()}
                </Typography>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={supplierBreakdownData.masterData}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink}>
                        <Typography variant="h4" className="title">
                            Common suppliers
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent v={hardcoded_dpw.OVERLAP.overlap_n_suppliers}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Suppliers spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={hardcoded_dpw.OVERLAP.overlap_total_combined_spend}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={supplierBreakdownData.cmpData}
                    options={optionsRight}
                />
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={categoryCompareBreakdownData.masterData}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink}>
                        <Typography variant="h4" className="title">
                            Similar Categories
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent v={categoryCompareData.category_n_overlap}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Combined spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={categoryCompareData.master_spend_overlap + categoryCompareData.compare_spend_overlap}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={categoryCompareBreakdownData.cmpData}
                    options={optionsRight}
                />
            </Grid>
        </Grid>

    </main>
};

const MyButtonWrapper: React.FC<Props> = ({noLink, children}) => {
    const history = useHistory();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    if (noLink) return <>{children}</>;
    return <div className="breakdown-button-wrapper">
        <ButtonBase
            onClick={() => history.push(generatePath(routes.job_synergy_v1_categories, routeMatch.params))}
            className="breakdown-button"
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    {children}
                </Grid>
                <Grid item className="button-arrow">
                    <KeyboardArrowRight/>
                </Grid>
            </Grid>
        </ButtonBase>
    </div>
};
