import {makeAutoObservable} from "mobx";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {SynergyStore} from "../SynergyStore";
import {CommonSuppliersReviewDelegate} from "./CommonSuppliersReviewDelegate";

/**
 * The synergy store for common suppliers
 */
export class CommonSuppliersDelegate {

    readonly numberTotalSuppliersLeft: number = this.profileStore.p.hardcodedNumberTotalSuppliersLeft || -1;
    readonly numberTotalSuppliersRight: number = this.profileStore.p.hardcodedNumberTotalSuppliersRight || -1;
    readonly numberTotalCommonSuppliers: number = this.profileStore.p.hardcodedNumberTotalCommonSuppliers || -1;
    readonly numberCommonSuppliersLeft: number = this.profileStore.p.hardcodedNumberCommonSuppliersLeft || -1;
    readonly numberCommonSuppliersRight: number = this.profileStore.p.hardcodedNumberCommonSuppliersRight || -1;
    readonly totalSpendCommonSuppliers: number = this.profileStore.p.hardcodedTotalSpendCommonSuppliers || -1;
    readonly totalSuppliersSpendLeft: number = this.profileStore.p.hardcodedTotalSuppliersSpendLeft || -1;
    readonly totalSuppliersSpendRight: number = this.profileStore.p.hardcodedTotalSuppliersSpendRight || -1;
    readonly commonSuppliersSpendLeft: number = this.profileStore.p.hardcodedCommonSuppliersSpendLeft || -1;
    readonly commonSuppliersSpendRight: number = this.profileStore.p.hardcodedCommonSuppliersSpendRight || -1;

    readonly review = new CommonSuppliersReviewDelegate(this.api, this, this.synergyStore);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private synergyStore: SynergyStore,
        private bagStore: BagStore,
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
        // reaction(() => ([this.bagStore.bag?.id, this.ps._parentSupplierManager.result] as const), ([databag, result]) => {
        //     console.log('SupplierNormalizationStore.reaction', databag, result?.data.length)
        //     if (databag && result) {
        //         // Only when the _parentSupplierManager is done re-trigger the startRowRequest
        //         this.review.filterDelegate.startRowsRequest();
        //     }
        // })
    }

    init() {
        const synergy = this.synergyStore.requestedSynergyId;
        console.log('CommonSuppliersDelegate.init', synergy)

        this.review._commonSupplierGroupRequest.reset(); // Ensures that the subRow request is stopped
        if (!synergy) return;
        this.review.filterDelegate.startRowsRequest();
    }
}
