import './CleanupDashBoard.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {DashboardGridAlert} from "../multi-dashboard/DashboardGridAlert";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../routing/routes";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {DashboardRow} from "../../components/dashboard/DashboardRow";
import {SupplierNormalizationV2Icon} from "../../components/icons/SupplierNormalizationV2Icon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {environment} from "../../env";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 */
export const CleanupDashboard: React.FC = observer(() => {
    const {
        bagStore,
        categorizationStore,
        supplierNormalizationStore,
        taxonomyManagerStore,
        spendConcentrationStore
    } = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const pendingSupplierNormalization = supplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const scTop = spendConcentrationStore.topValueP;
    return <>
        {environment.isTest && <h1>CleanupDashboard</h1>}
        <DashboardGridAlert/>
        <Grid container
              className="dashboard single-cleaning-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="supplier-normalization"
                    avatar={<SupplierNormalizationV2Icon/>}
                    title="Supplier Normalization"
                    value={drawOpportunityValue(pendingSupplierNormalization)}
                    subtitle="suppliers not reviewed yet"
                    link={generatePath(routes.supplier_normalization, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.taxonomy_builder, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.categorization_review, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.koi_spend_concentration, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
