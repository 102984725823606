import './ReportingPage.scss'
import React from "react";
import {Alert, Button, CircularProgress, Grid} from "@mui/material";
import {ChevronLeft} from "@mui/icons-material";
import {useStores} from "../../stores";
import {observer} from "mobx-react-lite";
import {CategorizationReport} from './report-categorization/CategorizationReport';
import {useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../routing/routes';
import {generateHomePath} from "../../routing/routing.utils";

type Props = {}
export const ReportingPage: React.FC<Props> = observer(() => {
    const {
        rootAppStore,
        bagStore,
        categorizationStore,
        categorizationReviewStore,
    } = useStores();
    const bagId = bagStore.bagId;

    const history = useHistory();
    const routeMatch = useRouteMatch<JobRouteMatch>();

    return <div>
        {/*A header with back button*/}
        <div className="custom-page reporting-page">
            <Grid container alignItems="center" className="header">
                <Grid item xs={2} className="text-center">
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            if (categorizationStore.data.filterManager.selectedLevel === 0) {
                                history.push(generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app));
                            } else {
                                categorizationStore.data.filterManager.selectOneCategoryUp();
                            }
                        }}>
                        {categorizationStore.data.filterManager.selectedLevel === 0 ? 'To dashboard' : 'Back'}
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    {categorizationReviewStore.categories.taxonomy_categories === null &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="warning">Missing taxonomy</Alert>
                            </Grid>
                        </Grid>
                    }
                    {categorizationReviewStore.categories.taxonomy_categories === undefined && !categorizationReviewStore.categories.error &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="info">
                                    Loading review, please wait
                                    <span className="ml-2 mr-1">
                                            <CircularProgress size={12}/>
                                        </span>
                                </Alert>
                            </Grid>
                        </Grid>
                    }
                    {categorizationReviewStore.categories.error &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="error">{categorizationReviewStore.categories.error}</Alert>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Grid>
            <div className="reporting-page-content">
                <CategorizationReport/>
            </div>
        </div>

    </div>
});
