import {ColSpec} from "../../../../components/table/MithraTableHeadColumns";
import ProfileStore from "../../../../stores/ProfileStore";

export function getCatReviewColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-selection', txt: '', align: 'center', width: 85},
        {cls: 'col-dropdown', txt: '', align: 'center'},
        {cls: 'col-s_name', txt: p.supplierDataName},
        {cls: 'col-s_spend', txt: 'Total spend'},
        {cls: 'col-l1s', txt: 'L1(s) of vendor'},
        {cls: 'col-bus', txt: p.buDataName},
        {cls: 'col-s_cats', txt: ''},
        {cls: 'col-s_YN', txt: 'Approve', align: 'right' as const},
    ]
    return arr.filter(c => c) as ColSpec[];
}

export function getCatReviewSubColumns(p: ProfileStore, bagId: number | undefined, singular: boolean): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-selection', txt: '', align: 'center' as const} as ColSpec,
        (!singular) ? undefined : {cls: 'col-s_name', txt: p.supplierDataName, columnFieldId: 's_name'},
        {cls: 'col-p_name', txt: p.p.p_name_col_name, width: 125, columnFieldId: 'p_name'},
        {cls: 'col-p_spend', txt: 'Amount', width: 90, columnFieldId: 'p_spend'},
        p.p.p_description_col_name !== false ? {
            cls: 'col-p_description',
            txt: p.p.p_description_col_name,
            columnFieldId: 'p_description'
        } : undefined,
    ].concat(p.partContextColNames.map(({colName, colClassName, dataField}) =>
        ({cls: colClassName, txt: colName, columnFieldId: dataField})
    )).concat(
        (singular ? undefined : true) && p.p.categorizationReviewSubRowRelationData && ([] as Array<ColSpec>).concat(
            ...p.p.categorizationReviewSubRowRelationData!.map(related_data_item => 
                related_data_item.columns.map(column => 
                    (column.colSpec)
                )
            )
        )
    ).concat([
        {cls: 'col-p_alg_l', txt: 'Previous category'},
        p.showCategorizationScoreForBag(bagId) ? {
            cls: 'col-p_alg_conf',
            txt: 'Score',
            columnFieldId: 'p_suggestion_score'
        } : undefined,
        {cls: 'col-p_reviewed_l', txt: 'Suggested category'},
        {cls: 'col-p_YN', txt: '', align: 'right' as const} as ColSpec,
    ]);
    return arr.filter(c => c) as ColSpec[];
}
