import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import {
    ApiSuggestionTreeResponse,
    ApiUpdateSuggestionTreeResponse,
    catsToDict,
    ReviewStatusApiDataType
} from "../services/ApiHelpers";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";
import {toCurrency} from "../components/currency-component/CurrencyComponent";
import {MatSearch, MatSupplierFilter_V2} from "../services/classes/MatReviewClasses";
import {MatSupplierCategoryConcentrationStatistics} from "../services/classes/MaterializedClasses";
import {PageResponseManager} from "./managers/PageResponseManager";
import {SearchManager} from "./managers/SearchManager";


// Volatile state in FE
export type ReviewStatusChangesType = {
    hasChangesInChildren: boolean
}

// // Persistent state in BE
// ReviewStatusApiDataType

export type SuggestionTreeStoreType<T> = {
    label: string;
    children: SuggestionTreeStoreType<T>[];
    values: T;
    review?: {
        apiData: ReviewStatusApiDataType;
        stateData: ReviewStatusChangesType;
    };
}

export type ResultTableRows = {
    p__description: string
    d__source: string
    s__name: string
    p__spend: string | number
    p__purchase_date: string
}


export type SelectionTableRows = {
    p__spend: string | number
    p_spend_a: string | number
    p_spend_b: string | number
    p_spend_c: string | number
    s_suppliers: string | number
    p__transactions: string | number
}

export type TableColumns = {
    columnTitle: string
    getValue: (d: any, c: any) => string
    xOffset: number
}

const hardcodedPaginatedTableRows = 30

export default class TaxonomySuggestorStore {

    suggestedTaxonomyId: number = this.profileStore.p.hardcodedTaxonomySuggestionId || -1;
    taxonomyApiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data> | undefined = undefined
    taxonomySuggestionState: SuggestionTreeStoreType<m_taxonomy.Data> | undefined = undefined
    // selectedNode: SuggestionTreeStoreType<m_taxonomy.Data> | undefined = undefined
    selectedCategory: string[] = [];
    selectedNodeLabel: string | undefined = ''
    selectedNodeData: any | undefined = undefined
    tableColumns: TableColumns[] = [
        {
            columnTitle: 'Total Spend',
            getValue: (d, c) => {
                const currencyValue = toCurrency(this.profileStore.currencySymbol, d.data.values.p__spend__sum);
                ;
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 420,
        },
        {
            columnTitle: 'Holding',
            getValue: (d, c) => {
                const currencyValue = toCurrency(this.profileStore.currencySymbol, d.data.values.p_spend_a);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 520,
        },
        {
            columnTitle: 'Energy',
            getValue: (d, c) => {
                const currencyValue = toCurrency(this.profileStore.currencySymbol, d.data.values.p_spend_b);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 620,
        },
        {
            columnTitle: 'Nutreco',
            getValue: (d, c) => {
                const currencyValue = toCurrency(this.profileStore.currencySymbol, d.data.values.p_spend_c);
                const value = currencyValue.replace('NaN', '0');
                return value;
            },
            xOffset: 720,
        },
        {
            columnTitle: 'Suppliers',
            getValue: (d, c) => {
                const ns = String(d.data.values.s__id__nunique)
                const value = ns.replace('undefined', '0');
                return value
            },
            xOffset: 820,
        },
        {
            columnTitle: 'Transactions',
            getValue: (d, c) => {
                const ns = String(d.data.values.p__id__count)
                const value = ns.replace('undefined', '0');
                return value
            },
            xOffset: 920,
        },
    ]

    readonly supplierSearch = new SearchManager(() => {
        this.requestTableData()
    })

    /**
     * TODO: For now we re-use an spend concentration endpoint... this should be changed to a new endpoint
     */
    readonly _spendConcentrationSupplierRequestManager = new PageResponseManager<MatSupplierFilter_V2, MatSupplierCategoryConcentrationStatistics>(
        hardcodedPaginatedTableRows,
        (page, filter) => this.api.listPaginatedSpendConcentrationSupplier(filter, page, hardcodedPaginatedTableRows),
    );

    // noinspection JSUnusedLocalSymbols
    constructor(
        private profile: ProfileStore,
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async getTaxonomySuggestion(suggestedTaxonomyId: number) {
        return await this.api.getTaxonomySuggestions(suggestedTaxonomyId).then((data) => {
            console.log('Got taxonomy suggestions')
            console.log(data)
            this.setTaxonomySuggestionState(data.suggestion_state)
            this.setTaxonomyApiResponse(data.suggestion_state)
            return

        }).catch((error) => {
            console.error('Error while getting taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    async updateTaxonomySuggestionValues() {
        return await this.api.updateTaxonomySuggestionValues(this.suggestedTaxonomyId).then((data) => {
            console.log('Updated taxonomy suggestions')
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    setTaxonomySuggestionState(apiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>) {
        this.taxonomySuggestionState = this.extractTaxonomySuggestionState(apiResponse);
    }

    extractTaxonomySuggestionState(apiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>): SuggestionTreeStoreType<m_taxonomy.Data> {
        const {label, children, values, review_status} = apiResponse;
        const {...reviewApiData} = review_status ?? {};

        const storeNode: SuggestionTreeStoreType<m_taxonomy.Data> = {
            label,
            children: [],
            values,
            review: {
                apiData: reviewApiData,
                stateData: {hasChangesInChildren: false}
            }
        };

        if (children) {
            storeNode.children = children.map(child => this.extractTaxonomySuggestionState(child));
        }

        return storeNode;
    }

    async putTaxonomySuggestion(state: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>) {

        console.log('Updating taxonomy suggestions')
        console.log(state)

        return await this.api.putTaxonomySuggestions(this.suggestedTaxonomyId, state).then((data) => {
            console.log('Updated taxonomy suggestions')
            console.log(data)
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }


    setTaxonomyApiResponse(taxonomyApiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>) {
        this.taxonomyApiResponse = taxonomyApiResponse;
    }

    saveTaxonomySuggestion() {
        this.api.saveTaxonomySuggestions(this.suggestedTaxonomyId)
    }

    // setSelectedNode(node: SuggestionTreeStoreType<m_taxonomy.Data> | undefined) {
    //     this.selectedNode = node
    // }

    setSelectedNodeLabel(label: string | undefined) {
        this.selectedNodeLabel = label
    }

    setSelectedNodeValues(data: m_taxonomy.Data | undefined) {
        this.selectedNodeData = data
    }

    // selectedPreviousNode(node: SuggestionTreeStoreType<m_taxonomy.Data> | undefined) {
    //     if (!this.selectedNode) {
    //         if (this.selectedNode == ){
    //             return true
    //         }
    //     }
    //     return false
    // }

    get suggestionKpis(): { numberOfAdditions: number, numberOfDeletions: number, numberOfRenames: number } {
        let numberOfAdditions = 0;
        let numberOfDeletions = 0;
        let numberOfRenames = 0;
        const iterateSuggestions = (suggestion) => {
            if (suggestion?.review_status?.added) {
                numberOfAdditions += 1;
            } else if (suggestion?.review_status?.deleted) {
                numberOfDeletions += 1;
            } else if (suggestion?.review_status?.renamed) {
                numberOfRenames += 1;
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        iterateSuggestions(this.taxonomyApiResponse);
        return {numberOfAdditions, numberOfDeletions, numberOfRenames};
    }

    get selectedDepth() {
        return this.selectedCategory.length;
    }

    get isCategorySelected() {
        return this.selectedCategory && this.selectedCategory.length > 0;
    }

    setSelectedCategory(categories?: string[]) {
        this.selectedCategory = categories ? categories : [];
        this.requestTableData();
    }

    requestTableData() {
        if (this.dataTableFilter) {
            this._spendConcentrationSupplierRequestManager._request(this.dataTableFilter);
        } else {
            this._spendConcentrationSupplierRequestManager.reset();
        }
    }

    /**
     * Taken from: SpendConcentrationStore.supplierRequestFilter
     */
    get dataTableFilter(): MatSupplierFilter_V2 {
        if (!this.profileStore.p.hardcodedCombinedBagId) throw new Error('Missing hardcodedCombinedBagId');
        const level = this.selectedDepth;
        const selectedCats = catsToDict(this.selectedCategory, level);

        let search: MatSearch | undefined = undefined;
        if (this.supplierSearch.activeSearchString) {
            search = {
                supplier: this.supplierSearch.activeSearchString,
            }
        }
        return {
            databag: this.profileStore.p.hardcodedCombinedBagId,
            business_unit: undefined,
            search,
            filterLevel: level + 1,
            fixLevels: level,
            ...selectedCats,
        };
    }

    get isTableDataLoading() {
        return this._spendConcentrationSupplierRequestManager.isLoading;
    }

    /**
     * TODO: MatSupplierCategoryConcentrationStatistics is not a good fit for this endpoint
     */
    get paginatedTableData() {
        return this._spendConcentrationSupplierRequestManager;
    }
}
