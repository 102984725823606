import './dashboard/SynergyDashboardPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {generateHomePath} from "../../routing/routing.utils";
import {ButtonBase, Grid, Typography} from "@mui/material";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {CmpDataBagRouteMatch, JobRouteMatch, routes} from "../../routing/routes";
import {AnnotatedValueComponent} from "../../components/annotated-value-component/AnnotatedValueComponent";
import {CurrencyComponent, toCurrency} from "../../components/currency-component/CurrencyComponent";
import {generatePath, useHistory} from "react-router";
import {KeyboardArrowRight} from "@mui/icons-material";

export const SynergyOverviewPage: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, supplierNormalizationStore, synergyStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const noLink = false

    const dataSuppliersRight: Data = {
        top: {
            id: "1",
            total: synergyStore.commonSuppliersDelegate.numberTotalSuppliersRight,
            value: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight,
            valueTitle: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight.toString(),
            valueTitleHover: synergyStore.commonSuppliersDelegate.numberCommonSuppliersRight.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonSuppliersDelegate.totalSuppliersSpendRight,
            value: synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendRight, 3,),
        },
    };


    const dataSuppliersLeft: Data = {
        top: {
            id: "1",
            total: synergyStore.commonSuppliersDelegate.numberTotalSuppliersLeft,
            value: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft,
            valueTitle: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft.toString(),
            valueTitleHover: synergyStore.commonSuppliersDelegate.numberCommonSuppliersLeft.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonSuppliersDelegate.totalSuppliersSpendLeft,
            value: synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonSuppliersDelegate.commonSuppliersSpendLeft, 3,),
        },
    };

    const dataCategoriesLeft: Data = {
        top: {
            id: "1",
            total: synergyStore.commonCategoriesDelegate.numberTotalCategoriesLeft,
            value: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft,
            valueTitle: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft.toString(),
            valueTitleHover: synergyStore.commonCategoriesDelegate.numberCommonCategoriesLeft.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonCategoriesDelegate.totalCategoriesSpendLeft,
            value: synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendLeft, 3,),
        },
    };

    const dataCategoriesRight: Data = {
        top: {
            id: "1",
            total: synergyStore.commonCategoriesDelegate.numberTotalCategoriesRight,
            value: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight,
            valueTitle: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight.toString(),
            valueTitleHover: synergyStore.commonCategoriesDelegate.numberCommonCategoriesRight.toString(),
        },
        bottom: {
            id: "2",
            total: synergyStore.commonCategoriesDelegate.totalCategoriesSpendRight,
            value: synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight,
            valueTitle: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight, 3),
            valueTitleHover: toCurrency(p.currencySymbol, synergyStore.commonCategoriesDelegate.commonCategoriesSpendRight, 3,),
        },
    };


    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    useEffect(() => {
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId set');
        supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, bag.taxonomy_size)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    return <KoiFacade
        title="Synergy Overview"
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="synergy-dashboard-page">


        <Grid container justifyContent="space-evenly" className="dashboard-header" style={{marginTop: '4em'}}>
            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {synergyStore.datasetNameLeft}
                </Typography>
            </Grid>

            <Grid item lg={3} md={2}/>

            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {synergyStore.datasetNameRight}
                </Typography>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={dataSuppliersLeft}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink} isCommSup={true}>
                        <Typography variant="h4" className="title">
                            Common suppliers
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent
                                v={synergyStore.commonSuppliersDelegate.numberTotalCommonSuppliers}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Suppliers spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={synergyStore.commonSuppliersDelegate.totalSpendCommonSuppliers || -1}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={dataSuppliersRight}
                    options={optionsRight}
                />
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={dataCategoriesLeft}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink} isCommSup={false}>
                        <Typography variant="h4" className="title">
                            Similar Categories
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent
                                v={synergyStore.commonCategoriesDelegate.numberTotalCommonCategories}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Combined spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={synergyStore.commonCategoriesDelegate.totalSpendCommonCategories}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={dataCategoriesRight}
                    options={optionsRight}
                />
            </Grid>
        </Grid>
    </KoiFacade>
});

type Props = {
    noLink?: boolean,
    isCommSup?: boolean
}
const MyButtonWrapper: React.FC<Props> = ({noLink, isCommSup, children,}) => {
    const history = useHistory();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    if (noLink) return <>{children}</>;
    return <div className="breakdown-button-wrapper">
        <ButtonBase
            onClick={() => {
                if (environment.package === 'merge_x') {
                    if (isCommSup) {
                        history.push(generatePath(routes.merge_x_merge_common_suppliers, routeMatch.params))
                    } else {
                        history.push(generatePath(routes.merge_x_merge_common_categories, routeMatch.params))
                    }
                }

                if (environment.package === 'merge_2') {

                    // FIXME: this is a hack
                    if (isCommSup) {
                        history.push(generatePath(routes.merge_2_merge_common_suppliers, routeMatch.params))
                    } else {
                        history.push(generatePath(routes.merge_2_merge_common_categories, routeMatch.params))
                    }
                }
            }}
            className="breakdown-button"
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    {children}
                </Grid>
                <Grid item className="button-arrow">
                    <KeyboardArrowRight/>
                </Grid>
            </Grid>
        </ButtonBase>
    </div>
};

