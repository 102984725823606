/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";
export const C: AppConfiguration = {
    package: 'classic',
    dashboardVariant: 'minimal',
    menu: 'single',
    profile: {
        p_name_col_name: 'Material',
        p_description_col_name: 'Text',
        searchConfiguration: 'default_p_desc',
        s_col_name: 'Vendor',
        bu_col_name: 'Region',
        taxonomy_builder_only_n_cats: true,
        hackHideUncategorizedInReview: true,

        skipAiSupplierNormalizationModel: true,
        useSupplierNormalizationCard: true,

        hideSmallStandaloneSpend: {
            minStandaloneSpend: 1000,
            minStandaloneSuppliers: 3,
        },

        locale: 'en-us',
        currency: 'EUR',
        hide_main_menu: true,
        trialCards: ['koi-spend-segmentation'],

        hackRetrieveCommonSupplierLastKpis: true,
    },
}
