import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Collapse, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {TransitionProps} from "@mui/material/transitions/transition";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {CategorizationReviewSubTable} from "./CategorizationReviewSubTable";
import {fromArrayCategories} from "../../../../services/ApiHelpers";
import {CAT_MAIN_REVIEW_TABLE_COL_SPAN} from "./CategorizationReviewMainTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {getSpend, SomeMatSupplierReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";

// TODO: Removed during migration to V5
// const useRowStyles = makeStyles({
//     root: {
//         '& > *': {
//             borderBottom: 'unset',
//         },
//     },
// });

type Props = {
    row: SomeMatSupplierReviewRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};

export const CategorizationReviewMainRow: React.FC<Props> = observer(({row, controller}) => {
    const {bagStore, categorizationReviewStore} = useStores();
    const [open, setOpen] = React.useState(false);

    let animationTimings: TransitionProps['timeout'] | number | 'auto' = 'auto' as const;

    const hasParts = row.parts ? row.parts.length > 0 : false;
    const nParts = row.parts?.length;
    // const firstPart: MatPartReviewRowState | undefined = row.parts && row.parts.length > 0 ? row.parts[0] : undefined;

    let recatControlsComponent;
    if (row.parts) {
        if (row.parts.length > 100) {
            // Performance issue
            animationTimings = 0
        } else if (row.parts.length > 30) {
            // Does not fit on the screen anymore
            animationTimings = {
                enter: 350,
                exit: 100,
            }
        }

        if (row.combined_state) {
            // The combined state is calculated
            if (row.combined_state.allSameCategory === false) {
                // It's inconclusive
                recatControlsComponent = <Typography variant="caption">Mixed categories</Typography>;
            } else if (categorizationReviewStore.categories.taxonomy_categories && row.parts.length >= 1) {
                const effectiveLabels = fromArrayCategories(row.combined_state.allSameCategory, bagStore.taxonomy_size);
                recatControlsComponent = <PartCategorizationComponent cats={effectiveLabels}/>
            }
        }
    }
    return <>
        <TableRow className="supplier-row">
            <TableCell className="col-dropdown" onClick={() => setOpen(!open)}>
                {hasParts &&
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>
                }
                {nParts}
            </TableCell>
            <TableCell className="col-s_name">
                {row.s_name}
            </TableCell>
            <TableCell className="col-s_spend">
                <CurrencyAbbreviationContext.Consumer>{abbreviation => {
                    return <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>;
                }}</CurrencyAbbreviationContext.Consumer>
            </TableCell>
            <TableCell className="col-l1s">
                {row.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </TableCell>
            <TableCell className="col-s_cats">
                {recatControlsComponent}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className="cell-sub-table" colSpan={CAT_MAIN_REVIEW_TABLE_COL_SPAN}>
                {nParts !== undefined && nParts > 0 &&
                    <Collapse in={open} timeout={animationTimings} unmountOnExit>
                        <CategorizationReviewSubTable data={row} controller={controller}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
