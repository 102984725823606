import React, {useEffect, useState} from "react";
import {
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {useStores} from "../../../../stores";
import {
    CurrencyComponent,
    findAbbreviationOfGroup,
    MILLION_ABBREVIATION,
    toCurrency
} from "../../../../components/currency-component/CurrencyComponent";
import {PartData} from "../../../../services/classes/MaterializedClasses";
import {PageResponseManager} from "../../../../stores/managers/PageResponseManager";
import {MithraTablePaginationActions} from "../../../../components/table-pagination/MithraTablePaginationActions";
import {observer} from "mobx-react-lite";
import moment from "moment/moment";
import {getSupplierBreakdownColumns} from "./SupplierBreakdownColumns";
import {MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {environment} from "../../../../env";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";

const PAGE_SIZE = 100;
type PageRespM = PageResponseManager<{ bag: number, supplier: number }, PartData>;

export type SupplierSpecData = {
    supplierId: number
    label: string
    totalSpend: number
}
type Props = {
    data?: SupplierSpecData
}
export const SupplierBreakdown: React.FC<Props> = observer(({data}) => {
    const {bagStore, materializedApi, p} = useStores();
    const supplierName = data?.label;
    const supplier = data?.supplierId;
    const bag = bagStore.bagId;
    const COLUMNS = getSupplierBreakdownColumns(p);

    const [paginator, setPaginator] = useState<PageRespM | undefined>(undefined);
    useEffect(() => {
        setPaginator(new PageResponseManager<{ bag: number, supplier: number }, PartData>(
            PAGE_SIZE,
            (page, {bag, supplier}) => materializedApi.listPartData(bag, supplier, page, PAGE_SIZE)
        ))
    }, [materializedApi])

    useEffect(() => {
        if (!paginator) return;
        if (!supplier) {
            paginator.reset();
        } else {
            paginator.init({bag, supplier})
        }
    }, [paginator, bag, supplier])

    const isLoading = !paginator || paginator.isLoading;
    const tableClass = 'mithra-table x-design parts-table' + (isLoading ? ' loading' : '');
    const abbreviation = p.p.hardcodedKPIAbbreviation
        ? p.p.hardcodedKPIAbbreviation
        : paginator?.data ? findAbbreviationOfGroup(paginator.data.map(d => d.p_spend)) : MILLION_ABBREVIATION;
    return <section className="supplier-breakdown-visualization">
        <Grid container alignItems="baseline" justifyContent="flex-start">
            <Grid item>
                <Typography className="supplier-header" variant="h4">
                    {supplierName}
                </Typography>
            </Grid>
            <Grid item style={{marginLeft: '1em'}}>
                <Typography variant="subtitle1">
                    {data && `Total ${toCurrency(p.currencySymbol, data.totalSpend)} spend`}
                </Typography>
            </Grid>
        </Grid>

        <TableContainer className={tableClass}>
            <Table cellPadding={0}>
                <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
                <TableBody>
                    {paginator?.data ? paginator.data.map(part => {
                            let i = 0;
                            return <TableRow key={part.p_id}>
                                <MithraTableCell c={COLUMNS[i++]}>
                                    {environment.isTest && <>p_id={part.p_id}<br/></>}
                                    {part.p_name}
                                </MithraTableCell>
                                {p.p.p_description_col_name !== false &&
                                    <MithraTableCell c={COLUMNS[i++]}>
                                        <GoogleLink type="part_with_supplier" objs={[part]}>
                                            {part.p_description}
                                        </GoogleLink>
                                    </MithraTableCell>
                                }
                                {p.partContextColNames.map(({dataField, colType}) =>
                                    <MithraTableCell key={dataField} c={COLUMNS[i++]}>
                                        {colType === 'date'
                                            ? moment(part[dataField]).format('L')
                                            : String(part[dataField])}
                                    </MithraTableCell>
                                )}
                                <MithraTableCell c={COLUMNS[i++]}>
                                    <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                                        <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
                                    }</CurrencyAbbreviationContext.Consumer>
                                </MithraTableCell>
                            </TableRow>;
                        }
                    ) : <TableRow className="no-data-yet"/>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={COLUMNS.length}
                            count={paginator?.count || 0}
                            rowsPerPage={paginator?.pageSize || 0}
                            page={(paginator?.page || 1) - 1}
                            onPageChange={(e, page) => {
                                paginator?.changePage(page + 1)
                            }}
                            ActionsComponent={MithraTablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </section>
})
