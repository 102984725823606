import '../../../../components/data-table/DataTable.scss';
import './CategorizationReviewFeedback.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {CategorizationReviewFeedbackMainRow} from "./CategorizationReviewFeedbackMainRow";
import {MithraTablePaginationActions} from "../../../../components/table-pagination/MithraTablePaginationActions";
import {CurrencyAbbreviationContext} from '../../../../components/currency-component/CurrencyAbbreviationContext';
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../../components/currency-component/CurrencyComponent";
import {getSpend} from "../../../../services/classes/MatReviewClasses";
import {ApprovalStatusEnum} from "../../../../services/classes/AiClasses";

type Props = {}
export const CategorizationReviewFeedbackMainTable: React.FC<Props> = observer(() => {
    const {approvalStore, p} = useStores();
    const catApproval = approvalStore.cat;

    const supplierPages = catApproval.data.supplierPages;
    // const supplierPages = approvalStore.cat.;//
    let abbreviation: CurrencyAbbreviation;
    if (p.p.hardcodedKPIAbbreviation) {
        abbreviation = p.p.hardcodedKPIAbbreviation;
    } else {
        abbreviation = NO_ABBREVIATION;
        if (supplierPages.data) {
            const spends = supplierPages.data.map(d => getSpend(d))
            if (spends.length > 0)
                abbreviation = findAbbreviationOfGroup(spends)
        }
    }
    const isLoading = catApproval.data.isLoadingParts;
    const tableClass = 'mithra-table x-design categorization-approval-table' + (isLoading ? ' loading' : '');

    if (supplierPages?.data?.length === 0 && (
        approvalStore.approval?.current_status.status === ApprovalStatusEnum.APPROVED ||
        approvalStore.approval?.current_status.status === ApprovalStatusEnum.REJECTED
    )) return <></>;

    return <TableContainer className={tableClass}>
        {/*{controller && <PartReCategorizationHandler controller={controller}/>}*/}
        <Table cellPadding={0}>
            <TableHead>
                <TableRow>
                    {p.reviewTableColumnsMain.map((c, i) =>
                        <TableCell key={i} className={c.cls} align={c.align}>{c.txt}</TableCell>
                    )}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={p.reviewTableColumnsMain.length}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>

            </TableHead>
            <TableBody className="categorization-approval-table-body">
                {supplierPages.data ? <>
                        {supplierPages.data.map(row =>
                            <React.Fragment key={row.id}>
                                <CurrencyAbbreviationContext.Provider value={abbreviation}>
                                    <CategorizationReviewFeedbackMainRow row={row}/>
                                </CurrencyAbbreviationContext.Provider>
                            </React.Fragment>
                        )}
                        {supplierPages.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={p.reviewTableColumnsMain.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={p.reviewTableColumnsMain.length}
                        count={supplierPages.count}
                        rowsPerPage={supplierPages.pageSize}
                        page={supplierPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => supplierPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
