import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {Collapse, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {TransitionProps} from "@mui/material/transitions/transition";
import {
    getSpend,
    MatPartReviewRowState,
    SomeMatSupplierReviewRowState
} from "../../../../services/classes/MatReviewClasses";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {fromArrayCategories} from "../../../../services/ApiHelpers";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {CategorizationReviewFeedbackSubTable} from "./CategorizationReviewFeedbackSubTable";
import {CategorizationReviewFeedbackDelegate} from "../../../../stores/approval/CategorizationReviewFeedbackDelegate";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {CombinedApprovalButtons} from "../../../../components/approval-acc-rej/CombinedApprovalButtons";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";

type Props = {
    row: SomeMatSupplierReviewRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};

export const CategorizationReviewFeedbackMainRow: React.FC<Props> = observer(({row}) => {
    const {authStore, bagStore, approvalStore, p} = useStores();
    const [open, setOpen] = React.useState(false);

    const catApproval = approvalStore.cat;

    let animationTimings: TransitionProps['timeout'] | number | 'auto' = 'auto' as const;

    let combinedAccRejState = CategorizationReviewFeedbackDelegate.calcCombinedFeedbackState(row.parts);
    let onAccept: undefined | (() => void) = undefined;
    let onReject: undefined | (() => void) = undefined;

    const hasParts = row.parts ? row.parts.length > 0 : false;
    const nParts = row.parts?.length;
    const firstPart: MatPartReviewRowState | undefined = row.parts && row.parts.length > 0 ? row.parts[0] : undefined;

    // const prevCats = getPreviousReviewCategory(part, bagStore.taxonomy_size);
    // const currentCats = getActiveReviewCategory(part, bagStore.taxonomy_size);

    let recatControlsComponent;
    if (row.parts) {
        const parts = row.parts;
        if (!authStore.viewOnly && combinedAccRejState.editAllowed) {
            onAccept = () => catApproval.reviewFeedback.clickAcceptRejectParts(parts, ReviewChoice.ACCEPT);
            onReject = () => catApproval.reviewFeedback.clickAcceptRejectParts(parts, ReviewChoice.REJECT);
        }
        if (row.parts.length > 100) {
            // Performance issue
            animationTimings = 0
        } else if (row.parts.length > 30) {
            // Does not fit on the screen anymore
            animationTimings = {
                enter: 350,
                exit: 100,
            }
        }

        if (row.combined_state) {
            // The combined state is calculated
            if (row.combined_state.allSameCategory === false) {
                // It's inconclusive
                recatControlsComponent = <Typography variant="caption">Mixed categories</Typography>;
            } else if (catApproval.categories.taxonomy_categories && row.parts.length >= 1) {
                const effectiveLabels = fromArrayCategories(row.combined_state.allSameCategory, bagStore.taxonomy_size);
                recatControlsComponent = <PartCategorizationComponent cats={effectiveLabels}/>
            }
        }
    }
    return <>
        <TableRow className="supplier-row">
            <TableCell className="col-dropdown" onClick={() => setOpen(!open)}>
                {hasParts &&
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>
                }
                {nParts}
            </TableCell>
            <TableCell className="col-s_name">
                <GoogleLink type="supplier" objs={[row, firstPart]}>
                    {row.s_name}
                </GoogleLink>
            </TableCell>
            <TableCell className="col-s_spend">
                <CurrencyAbbreviationContext.Consumer>{abbreviation => {
                    return <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>;
                }}</CurrencyAbbreviationContext.Consumer>
            </TableCell>
            <TableCell className="col-l1s">
                {row.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </TableCell>
            {!p.p.hideBuInCategorizationTable &&
                <TableCell className="col-bus">
                    {row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}
                </TableCell>
            }
            <TableCell className="col-s_cats">
                {recatControlsComponent}
            </TableCell>
            <TableCell className="col-s_YN" align="right">
                <CombinedApprovalButtons onAccept={onAccept} onReject={onReject} state={combinedAccRejState}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className="cell-sub-table" colSpan={p.reviewTableColumnsMain.length}>
                {nParts !== undefined && nParts > 0 &&
                    <Collapse in={open} timeout={animationTimings} unmountOnExit>
                        <CategorizationReviewFeedbackSubTable data={row}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
