import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Bag} from "../../../services/classes/Bag";
import {Alert, Button, Grid, LinearProgress, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";

export const AiModelBusy: React.FC = observer(() => {
    const {bagStore, categorizationStore, aiManagerStore} = useStores();
    const bagId = bagStore.bagId;

    return <div className="ai-model-busy">
        <Grid container alignItems="center" direction="column">
            <Grid item className="header">
                <Typography variant="h4">
                    Categorizing ...
                </Typography>
            </Grid>
            <Grid item>
                <div className="progress-container">
                    <LinearProgress
                        variant="determinate"
                        value={aiManagerStore.applyTaxonomyMapping.progress}/>
                </div>
            </Grid>
            <Grid item>
                <Typography variant="caption">
                    Estimated completion time: {aiManagerStore.applyTaxonomyMapping.estCompletionTime?.fromNow()}
                </Typography>
            </Grid>
            <Grid item className="instructions-text">
                <Typography variant="body2" className="text-center">
                    Please be patient and stay on this page until the process to completes.
                </Typography>
                {aiManagerStore.applyTaxonomyMapping.runSync.error &&
                    <Alert severity="error">{aiManagerStore.applyTaxonomyMapping.runSync.error}</Alert>
                }
            </Grid>
            <Grid item className="action-btn-container">
                <div className="button-wrapper">
                    <Button
                        id="continue-categorization-btn"
                        color="primary"
                        variant="outlined"
                        endIcon={<ChevronRight/>}
                        disabled={aiManagerStore.applyTaxonomyMapping.runSync.busy
                            || categorizationStore.data.hasNoCategorizationResult
                            || categorizationStore.data.isLoading}
                        onClick={() => {
                            categorizationStore.navigateToPage('review')
                        }}
                    >
                        View results
                    </Button>
                </div>

            </Grid>
        </Grid>
    </div>
});
