import './TaxonomySuggestor.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {
    CollapsibleSuggestionIndentTree
} from "../../components/visualization/collapsible-suggestion-indent-tree/CollapsibleSuggestionIndentTree";
import {ApiUpdateSuggestionTreeResponse} from "../../services/ApiHelpers";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {Button, ButtonGroup, Grid} from "@mui/material";
import {ChevronLeft, SaveAsOutlined} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {
    ExtendedTree,
    Options,
    SuggestionTreeDataType
} from "../../components/visualization/collapsible-suggestion-indent-tree/TaxonomySuggestionTreeBuilder";
import {TaxonomySuggestionSelection} from "./TaxonomySuggestionSelection";
import {TaxonomySuggestionTable} from "./TaxonomySuggestionTable";
import {TaxonomySuggestionSearchComponent} from "./TaxonomySuggestionSearchComponent";


export const TaxonomySuggestorPage: React.FC = observer(() => {
    const {p, taxonomySuggestorStore, bagStore} = useStores();
    const [filter, setFilter] = React.useState<any | undefined>(0);

    // const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const history = useHistory();


    const hiddenRoot = true;
    const hiddenUncat = false;
    const options: Options = {
        height: 5000,
        width: 1500,
        margin: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 50,
        },
        leftToRight: true,
        columns: taxonomySuggestorStore.tableColumns,
        profile: p,

        onDataChanged: (d: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>) => {
            taxonomySuggestorStore.putTaxonomySuggestion(d)
        },
        overrideOnClickSelect: (d: ExtendedTree | undefined) => {
            taxonomySuggestorStore.setSelectedCategory(d?.ancestors().reverse().slice(1).map(n => n.data.label || ''));
            taxonomySuggestorStore.setSelectedNodeLabel(d?.data.label || undefined)
            taxonomySuggestorStore.setSelectedNodeValues(d?.data.values || undefined)
        }
    };

    return <>
        <div className='taxonomy-suggestor-page'>
            <div className='taxonomy-suggestor-toolbar'>
                <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<ChevronLeft/>}
                    onClick={() => {
                        history.push(generatePath(routes.merge_x_taxonomy_builder, {
                            id: bagId,
                        }))
                    }}>
                    Back
                </Button>

                {/*DEBUG ONLY*/}
                {/*<ButtonGroup>*/}
                {/*    <Button*/}
                {/*        color="error"*/}
                {/*        variant="outlined"*/}
                {/*        onClick={() => {*/}
                {/*            console.log('Updating values...')*/}
                {/*            taxonomySuggestorStore.updateTaxonomySuggestionValues()*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Update values*/}
                {/*    </Button>*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        variant="outlined"*/}
                {/*        startIcon={<SaveAsOutlined/>}*/}
                {/*        onClick={() => {*/}
                {/*            taxonomySuggestorStore.saveTaxonomySuggestion()*/}
                {/*            history.push(generatePath(routes.job_taxonomy_builder, {id: bagId, taxonomyId: 1}))*/}
                {/*        }}>*/}
                {/*        Save*/}
                {/*    </Button>*/}
                {/*</ButtonGroup>*/}

                {!p.p.readOnlySuggestion
                    && !p.p.taxonomyBuilderViewOnly // Probably we also want to disable this, but this is not delivered to customer yet
                    && <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<SaveAsOutlined/>}
                        onClick={() => {
                            taxonomySuggestorStore.saveTaxonomySuggestion()
                            history.push(generatePath(routes.merge_x_taxonomy_builder, {id: bagId,}))
                        }}>
                        Save
                    </Button>}

            </div>

            <div className='taxonomy-suggestor-filters'>
                <ButtonGroup className="ml-4">
                    <Button variant={filter === 0 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(0)
                            }
                            }>
                        No filter
                    </Button>
                    <Button variant={filter === 1 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(1)
                            }
                            }>
                        {taxonomySuggestorStore.suggestionKpis.numberOfAdditions} Suggestion to add
                    </Button>
                    <Button variant={filter === 2 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(2)
                            }
                            }>
                        {taxonomySuggestorStore.suggestionKpis.numberOfDeletions} Suggestion to delete
                    </Button>
                    <Button variant={filter === 3 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(3)
                            }
                            }>
                        {taxonomySuggestorStore.suggestionKpis.numberOfRenames} Suggestion to rename
                    </Button>
                </ButtonGroup>
            </div>

            <Grid container className='taxonomy-suggestor-main-container'>
                <Grid item
                      xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 5 : 12}>
                    {/*<Grid item xs={6}>*/}
                    <section className='taxonomy-container'>
                        {taxonomySuggestorStore.taxonomySuggestionState &&
                            <CollapsibleSuggestionIndentTree
                                data={taxonomySuggestorStore.taxonomySuggestionState}
                                options={options}
                                hiddenRoot={hiddenRoot}
                                hiddenUncat={hiddenUncat}
                                filter={filter}
                                hiddenColumns={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? true : undefined}
                            />}
                    </section>
                </Grid>
                {taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData && <Grid item
                                                                                                              xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 7 : 12}
                >
                    <section className='taxonomy-suggestion-selection-container'>
                        <div className='selection-overview'>

                            <TaxonomySuggestionSelection
                                selectedNodeLabel={taxonomySuggestorStore.selectedNodeLabel}
                                selectedNodeData={taxonomySuggestorStore.selectedNodeData}/>
                        </div>
                        <div className='selection-data-section'>

                            <Grid container className="selection-data-toolbar">
                                <Grid item><TaxonomySuggestionSearchComponent/></Grid>
                                {/*<div style={{flexGrow: 1}}/>*/}
                            </Grid>

                            {taxonomySuggestorStore.isCategorySelected && <TaxonomySuggestionTable/>}
                        </div>
                    </section>
                </Grid>}
            </Grid>
        </div>
    </>
});

type Filter = undefined | ((d: d3.HierarchyNode<SuggestionTreeDataType>) => boolean);
export const TSTB_FILTERS: Filter[] = [
    undefined,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.added,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.deleted,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.renamed,
]
