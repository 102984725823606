import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {TableCell, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {environment} from "../../../../env";
import {getActiveReviewCategory} from "../../../../services/classes/MatReviewHelpers";
import {MatPartReviewRowState} from "../../../../services/classes/MatReviewClasses";
import moment from "moment/moment";


type Props = {
    part: MatPartReviewRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const CategorizationReviewSubRow: React.FC<Props> = observer(({part}) => {
    const {p, bagStore} = useStores();

    const currentCats = getActiveReviewCategory(part, bagStore.taxonomy_size);

    return <TableRow>
        <TableCell className="col-p_name" component="th" scope="row">
            {environment.isTest && <>p_id={part.p_id}<br/></>}
            {part.p_name || '-'}
        </TableCell>
        <TableCell className="col-p_spend">
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </TableCell>

        {p.p.p_description_col_name !== false &&
            <TableCell className="col-p_description">
                {part.p_description}
            </TableCell>
        }

        {p.partContextColNames.map(({dataField, colType}) =>
            <TableCell key={dataField} className={"col-" + dataField}>
                {colType === 'date'
                    ? moment(part[dataField]).format('L')
                    : String(part[dataField])}
            </TableCell>
        )}

        <TableCell className="col-p_alg_l">
            <PartCategorizationComponent cats={currentCats}/>
        </TableCell>
    </TableRow>
})
