import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Button, Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {TransitionProps} from "@mui/material/transitions/transition";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {PartReCategorizationComponent} from "../../../../components/categorization/PartReCategorizationComponent";
import {CategorizationReviewStore} from "../../../../stores/CategorizationReviewStore";
import {CategorizationReviewSubTable} from "./CategorizationReviewSubTable";
import {fromArrayCategories} from "../../../../services/ApiHelpers";
import {CombinedReviewButtons} from "../../../../components/approval-acc-rej/CombinedReviewButtons";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {
    getSpend,
    MatPartReviewRowState,
    SomeMatSupplierReviewRowState
} from "../../../../services/classes/MatReviewClasses";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";

// TODO: Removed during migration to V5
// const useRowStyles = makeStyles({
//     root: {
//         '& > *': {
//             borderBottom: 'unset',
//         },
//     },
// });

type Props = {
    row: SomeMatSupplierReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};

export const CategorizationReviewMainRow: React.FC<Props> = observer(({row, controller}) => {
    const {authStore, bagStore, categorizationReviewStore, p} = useStores();
    const [open, setOpen] = React.useState(false);

    let animationTimings: TransitionProps['timeout'] | number | 'auto' = 'auto' as const;

    let combinedAccRejState = CategorizationReviewStore.calcCombinedReviewState(row.parts);
    let onAccept: undefined | (() => void) = undefined;

    const hasParts = row.parts ? row.parts.length > 0 : false;
    const nParts = row.parts?.length;
    const firstPart: MatPartReviewRowState | undefined = row.parts && row.parts.length > 0 ? row.parts[0] : undefined;

    let recatControlsComponent;
    if (row.parts) {
        const parts = row.parts;
        if (!authStore.viewOnly && combinedAccRejState.editAllowed) {
            onAccept = () => categorizationReviewStore.clickAcceptRejectParts(parts, ReviewChoice.ACCEPT);
        }
        if (row.parts.length > 100) {
            // Performance issue
            animationTimings = 0
        } else if (row.parts.length > 30) {
            // Does not fit on the screen anymore
            animationTimings = {
                enter: 350,
                exit: 100,
            }
        }

        if (row.combined_state) {
            // The combined state is calculated
            if (row.combined_state.allSameCategory === false) {
                // It's inconclusive
                recatControlsComponent = <Button
                    endIcon={<KeyboardArrowDown/>}
                    onClick={event => {
                        const anchor = event.currentTarget;
                        controller.setOnSelect((newCats) => categorizationReviewStore.clickReCatParts(parts, newCats))
                        controller.open(anchor)
                    }}
                >
                    Mixed categories
                </Button>
            } else if (combinedAccRejState.editAllowed
                && categorizationReviewStore.categories.taxonomy_categories
                && row.parts.length >= 1) {
                const effectiveLabels = fromArrayCategories(row.combined_state.allSameCategory, bagStore.taxonomy_size);
                recatControlsComponent = <PartReCategorizationComponent
                    cats={effectiveLabels}
                    handleOpenSearch={event => {
                        const anchor = event.currentTarget;
                        controller.setOnSelect((newCats) => categorizationReviewStore.clickReCatParts(parts, newCats))
                        controller.open(anchor)
                    }}
                />
            }
        }
    }

    return <>
        <TableRow className="supplier-row">
            <TableCell className="col-dropdown" onClick={() => setOpen(!open)}>
                {hasParts &&
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>
                }
                {nParts}
            </TableCell>
            <TableCell className="col-s_name">
                <GoogleLink type="supplier" objs={[row, firstPart]}>
                    {row.s_name}
                </GoogleLink>
            </TableCell>
            <TableCell className="col-s_spend">
                <CurrencyAbbreviationContext.Consumer>{abbreviation => {
                    return <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>;
                }}</CurrencyAbbreviationContext.Consumer>
            </TableCell>
            <TableCell className="col-l1s">
                {row.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </TableCell>
            {!p.p.hideBuInCategorizationTable &&
                <TableCell className="col-bus">
                    {row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}
                </TableCell>
            }
            <TableCell className="col-s_cats">
                {recatControlsComponent}
            </TableCell>
            <TableCell className="col-s_YN" align="right">
                {row.combined_state?.allSameCategory && row.combined_state?.allSameCategory.every(cat => cat === '') ? null : (
                    <CombinedReviewButtons onAccept={onAccept} state={combinedAccRejState}/>
                )}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className="cell-sub-table" colSpan={p.reviewTableColumnsMain.length}>
                {nParts !== undefined && nParts > 0 &&
                    <Collapse in={open} timeout={animationTimings} unmountOnExit>
                        <CategorizationReviewSubTable data={row} controller={controller}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
