import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Grid} from "@mui/material";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../components/dashboard/Dashboard.utils";
import {useStores} from "../../../stores";
import {environment} from "../../../env";
import {DashboardRow} from "../../../components/dashboard/DashboardRow";
import {DashBoardCard} from "../../dashboard/DashBoardCard";
import {SupplierSegmentationIcon} from "../../../components/icons/SupplierSegmentationIcon";
import {generatePath} from "react-router";
import {routes} from "../../../routing/routes";
import {ClassificationHierarchyIcon} from "../../../components/icons/ClassificationHierarchyIcon";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {MoveIcon} from "../../../components/icons/MoveIcon";
import {ArrowCollapsedIcon} from "../../../components/icons/ArrowCollapsedIcon";


export const Merge2AnalyticsDashboard: React.FC = observer(() => {
    const {
        p,
        bagStore,
        cmpStore,
        categorizationStore,
        baseSupplierNormalizationStore,
        masterSupplierNormalizationStore,
        spendConcentrationStore,
        ppvControllerStore,
        taxonomyManagerStore,
        taxonomyMapperStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const pendingSupplierNormalizationMaster = masterSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const pendingSupplierNormalizationBase = baseSupplierNormalizationStore.postKeyValues?.total_standalone_suppliers_spend;
    const masterCommonKpi = cmpStore.masterKpi;
    const baseCommonKpi = bagStore.kpi.common;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const scTop = spendConcentrationStore.topValueP;
    const ppvTotal = ppvControllerStore.spendTotalPPV;
    const nCategories = bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : DASHBOARD_CONSTANTS.SUBTITLE_LOADING;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;


    const baseBagId = bagStore.bag?.id;
    const masterBagId = cmpStore.masterBag?.id;
    useEffect(() => {
        if (baseBagId && masterBagId) {
            masterSupplierNormalizationStore._postStatsRequestManager.request({databag: masterBagId})
            baseSupplierNormalizationStore._postStatsRequestManager.request({databag: baseBagId})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseBagId, masterBagId])

    return <>
        {environment.isTest && <h1>Merge2AnalyticsDashboard</h1>}
        <Grid container
              className="dashboard merge-2-dashboard analytics-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_suppliers)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.merge_2_analytics_supplier_segmentation, {id: bagId})}
                    isTrial={true}
                />
            </DashboardRow>
            <DashboardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common
                    ? String(bagStore.kpi.common.n_categories)
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.merge_2_analytics_explore_categories, {id: bagId})}
                />
            </DashboardRow>
            <DashboardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(ppvTotal)}
                    valueColor={ppvTotal ? (ppvTotal >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.merge_2_analytics_ppv, {id: bagId})}
                />
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={scTop ? `${Math.round(scTop)}%` : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    valueColor={scTop ? (scTop < DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD_PARETO)
                        ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.merge_2_analytics_spend_concentration, {id: bagId})}
                />
            </DashboardRow>
        </Grid>
    </>
});
